import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css"; // Import your CSS file

const UserAccountSuspended = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = () => {
      navigate("/", { replace: true }); // Redirect to home page on back button
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  return (
    <>
      <div className="Main_email_div">
        <div className="verification-email-sent">
          <h2 style={{ color: "red" }}>Account Suspended</h2>
          <p>
            Your account has been suspended due to multiple incorrect attempts
            to answer the security question. This measure is necessary to
            protect both the account and personal information.
          </p>
          <p>
            <strong>Need Help?</strong>
            <br />
            Should this suspension seem like an error, or if assistance is
            needed in restoring access to the account, reaching out is
            encouraged. Security and access to services remain a top priority.
          </p>
          <p>
            <strong>Contact Us:</strong>
            <br />
            Email:{" "}
            <a
              href={`${process.env.REACT_APP_support_email}`}
              className="label-font"
              style={{ fontSize: "16px", color: "teal" }}
            >
              {`${process.env.REACT_APP_support_display_address}`}
            </a>
          </p>
          <p>
            The inconvenience caused by this situation is recognized, and
            support through the process of reinstating the account is available.
            Appreciation for understanding and cooperation is extended.
          </p>
          <p>Thank you for your understanding and cooperation.</p>
        </div>
      </div>
    </>
  );
};

export default UserAccountSuspended;
