import React, { useEffect } from "react";
import { Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import { Styles } from "../styles/styles";
import Home from "../pages/Home";
import FindSentiment from "../pages/PerceptionApp";
import Login from "../pages/LoginRoute";
import VerificationEmailSent from "../pages/ExtraPages/VerificationEmailSent.js";
import ResetPasswordEmailSent from "../pages/ExtraPages/ResetPasswordEmail";
import UserAccountSuspended from "../pages/ExtraPages/AccountSuspended";
import Header from "../components/Header";
import HeaderLogoOnly from "../components/HeaderLogoOnly";
import OTPPage from "../pages/OTP/OTPverification_page";
import AccountEditDetails from "../pages/editaccount/AccountEditDetail.jsx";
import PasswordResetPage from "../pages/ResetPassword/PasswordResetPage.js";
import ForgotPassword from "../pages/ResetPassword/ForgotPasswordPage.js";
import DeactivateAccount from "../pages/ExtraPages/Deactivate.js";
import UserAccountDeactivated from "../pages/ExtraPages/AccountDeactivated.js";
import CookieDeclaration from "../pages/PolicyPages/CookiePolicy/index";
import PrivacypolicyDeclaration from "../pages/PolicyPages/PrivacyPolicy/index";
import SecuritypolicyDeclaration from "../pages/PolicyPages/DataProtection/index";
import DpapolicyDeclaration from "../pages/PolicyPages/Dpa/index";
import TaCpolicyDeclaration from "../pages/PolicyPages/TermsConditions/index";
import { SidebarProvider } from "../context/SidebarContext";
import Reports from "../pages/Reports";
import ReportsPage from "../pages/ReportTab";
import ReportViewPage from "../pages/ReportTab/ViewReport";
import Features from "../pages/Features";
import AboutAI from "../pages/AboutAI/AboutAI";
import OurPromise from "../pages/OurPromise/OurPromise";
import Plans from "../pages/Plans/Plans";
import HelpForm from "../pages/HelpForm/HelpForm";
import ScrollToTop from "../common/ScrollToTop";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Verify3DSPage from "../components/FindPerceptionBlock/Verify3DSPage";
import VerifiedPage from "../components/FindPerceptionBlock/StatusPage";
import StatusPage from "../components/FindPerceptionBlock/StatusPage";

const Router = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const renderHeader = () => {
    if (
      currentPath === "/login" ||
      currentPath === "/otp" ||
      currentPath === "/OTP" ||
      currentPath === "/forgotpassword" ||
      currentPath === "/accountdeactivated" ||
      currentPath === "/suspended"
    ) {
      return <HeaderLogoOnly />;
    } else {
      return (
        <span id="header">
          <Header />
        </span>
      );
    }
  };

  useEffect(() => {
    const handleSignOut = () => {
      window.location.reload(); // Reload the page on sign-out event
    };

    // Listen for the localSrage signOutEvent
    window.addEventListener("storage", (event) => {
      if (event.key === "signOutEvent") {
        handleSignOut();
      }
    });

    // Listen for the custom signout-reload event
    window.addEventListener("signout-reload", handleSignOut);

    // Cleanup listeners on unmount
    return () => {
      window.removeEventListener("storage", handleSignOut);
      window.removeEventListener("signout-reload", handleSignOut);
    };
  }, []);

  
  useEffect(() => {
    const handleFocus = () => {
      setTimeout(() => {
        CheckInternetStatus();
      }, 1000);
    };
    const intervalId = setInterval(CheckInternetStatus, 5000);
    window.addEventListener("focus", handleFocus);
    return () => {
      clearInterval(intervalId);
      window.removeEventListener("focus", handleFocus);
    };
  }, []);
  

  const CheckInternetStatus = () => {
    const toastId = "internet-status-toast";
    if (!navigator.onLine && !toast.isActive(toastId)) {
      toast.error(
        "No internet connection. Please check your connection and try again.",
        { toastId,
          hideProgressBar: true
         }
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        minHeight: "100vh",
      }}
    >
      <ScrollToTop />
      <ToastContainer />
      <SidebarProvider>
        <Suspense fallback={null}>
          {renderHeader()}
          <Styles />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/pie" element={<FindSentiment />} />
            <Route path="/login" element={<Login />} />
            <Route path="/features" element={<Features />} />
            <Route path="/aboutai" element={<AboutAI />} />
            <Route path="/ourpromise" element={<OurPromise />} />
            <Route path="/plans" element={<Plans />} />
            <Route path="/help" element={<HelpForm />} />
            <Route
              path="/verificationEmail"
              element={<VerificationEmailSent />}
            />
            <Route path="/OTP" element={<OTPPage />} />
            <Route
              path="/resetpassword/:id/:token"
              element={<PasswordResetPage />}
            />
            <Route
              path="/resetpasswordemailsent"
              element={<ResetPasswordEmailSent />}
            />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route
              path="/accountdeactivated"
              element={<UserAccountDeactivated />}
            />
            <Route path="/cookie-policy" element={<CookieDeclaration />} />
            <Route
              path="/privacy-policy"
              element={<PrivacypolicyDeclaration />}
            />
            <Route
              path="/security-policy"
              element={<SecuritypolicyDeclaration />}
            />
            <Route path="/dpa-policy" element={<DpapolicyDeclaration />} />
            <Route path="/tc-policy" element={<TaCpolicyDeclaration />} />
            <Route path="/editaccount" element={<AccountEditDetails />} />
            <Route path="/deactivate" element={<DeactivateAccount />} />
            <Route path="/suspended" element={<UserAccountSuspended />} />
            <Route path="/report" element={<ReportsPage />} />
            <Route path="report/viewreport" element={<ReportViewPage />} />
            <Route path="/reports/:id" element={<Reports />} />{" "}
            <Route path="pie/verify3ds" element={<Verify3DSPage />} />
            <Route path="pie/status/:action" element={<StatusPage />} />


          </Routes>
          <Footer />
        </Suspense>
      </SidebarProvider>
    </div>
  );
};

export default Router;
