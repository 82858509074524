import { Row } from "antd";
import { useState } from "react";
import { Spin } from "antd";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import { useNavigate, useLocation } from "react-router-dom";
import { useSidebar } from "../../context/SidebarContext";

import "./styles.css";

import {
  FooterSection,
  NavLink,
  Extra,
  LogoContainer,
  FooterContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = () => {
  const { isSidebarOpen, toggleSidebar } = useSidebar();
  const location = useLocation();

  const isHomeRoute = location.pathname === "/";
  const isSmallDevice = window.innerWidth < 1160;
  const hideClass = isSidebarOpen && isSmallDevice ? "hide-element" : "";
  const navigate = useNavigate();
  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleLogoClick = async () => {
    if (isSidebarOpen) {
      toggleSidebar();
    }

    setIsLoading(true);
    let redirectToPie = true;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}decode/decodetoken`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user ID");
      }

      const data = await response.json();
      if (!data || data.userId == null) {
        redirectToPie = false;
      }
    } catch (error) {
      console.error("Failed to load user ID", error);
    }

    // Perform the logic to determine the route
    // This should be replaced with the actual logic to determine if the user should be redirected
    setTimeout(() => {
      setIsLoading(false); // Stop loading before navigating

      if (redirectToPie) {
        navigate("/pie"); // Use navigate to go to "/pie"
      } else {
        navigate("/"); // Use navigate to stay on "/"
      }
    }, 1000); // Simulate a network request
  };

  // Your FullPageLoader component
  const FullPageLoader = () => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255, 1)",
        zIndex: 1000,
      }}
    >
      <div
        className="centered-loader_report_tab"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spin size="large" />
      </div>
    </div>
  );

  const scrollUp = () => {
    if (!isHomeRoute) {
      handleLogoClick();
    }

    try {
      const element = document.getElementById("header") as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    } catch (error) {
      navigate("/");
    }
  };

  return (
    <>
      {isLoading && <FullPageLoader />}
      <div
        className={`Footer_main_div footer ${isSidebarOpen ? "shifted" : ""}`}
      >
        <FooterSection>
          <Container>
            <div className="policiesdiv">
              <ul id="menu-menu-for-footer" className="bottom-nav">
                <li
                  id="menu-item-2137"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2137"
                >
                  <a target="_blank" href="tc-policy">
                    Terms & Conditions
                  </a>
                </li>
                <li
                  id="menu-item-2112"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2112"
                >
                  <a target="_blank" href="security-policy">
                    Data Protection & Security Policy
                  </a>
                </li>
                <li
                  id="menu-item-2152"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2152"
                >
                  <a target="_blank" href="dpa-policy">
                    DPA
                  </a>
                </li>
                <li
                  id="menu-item-1450"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-1450"
                >
                  <a rel="privacy-policy" target="_blank" href="privacy-policy">
                    Privacy Policy
                  </a>
                </li>
                <li
                  id="menu-item-2344"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2344"
                >
                  <a
                    href="cookie-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Cookie Policy
                  </a>
                </li>
              </ul>
            </div>
          </Container>
        </FooterSection>
        <Extra>
          <Container border={true}>
            <Row
              justify="space-between"
              align="middle"
              style={{ paddingTop: "1rem" }}
            >
              <LogoContainer>
                <div style={{ marginTop: "7px" }}>
                  <NavLink to="/" onClick={() => scrollUp()}>
                    <SvgIcon
                      src="logo.svg"
                      aria-label="homepage"
                      width="90px"
                      height="64px"
                    />
                  </NavLink>
                </div>

                <p
                  style={{
                    color: "#333333",
                    marginLeft: "-15px",
                    marginTop: "7px",
                    fontSize: "15px",
                  }}
                  className={`footer-tagline ${hideClass}`}
                >
                  AI Driven Customer Perception Intelligence with Actionable
                  Insights
                </p>
              </LogoContainer>

              <FooterContainer>
                <div style={{ marginTop: "2px" }}>
                  <SocialLink
                    href="https://www.linkedin.com/company/resilient-technologies-ltd/"
                    src="linkedin.svg"
                  />
                </div>

                <a
                  href="https://resilienttech.co.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    height="36"
                    data-testid="resilient_logo"
                    style={{ border: 0, height: 36 }}
                    src="/img/svg/RTLogomain.svg"
                    alt="Resilient Tech Logo"
                  />
                </a>
              </FooterContainer>
            </Row>
          </Container>
        </Extra>
      </div>
    </>
  );
};

export default Footer;
