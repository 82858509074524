import "./Search.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Import JavaScript
import "bootstrap/dist/css/bootstrap.min.css";

function LoadingIndicator({ processing, text }) {
  return (
    <div className="datafetching">
      <div className="progress_bar_div">
        <div className="col">
          <p style={{ color: "#364545", fontSize: "20px" }}>{text}</p>
        </div>
        <div className="progress_icon">
          {processing === true ? (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <input
              type="checkbox"
              className="form-check-input"
              id={`dataFetchedCheckbox_${text}`}
              // checked={true}
              disabled
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default LoadingIndicator;
