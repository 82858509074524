import "./ListItem.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const ListItem = ({ items, users, freeReviews }) => {
  return (
    <div className="item-list">
      {items.map((item, index) => (
        <div key={index} className="item px-4 pd-vertical">
          <span className="fw-bold">{item}</span>
          {item === "Free Reviews" && (
            <div className="tooltip-container">
              <span className="fw-bold">
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  size="s"
                  style={{
                    color: "#9e9e9e",
                    paddingTop: "5px",
                    paddingLeft: "5px",
                    cursor: "pointer",
                  }}
                />
              </span>
              <div className="tooltip-content-plans">
                <a id="plans-tooltip">
                  Free {freeReviews} reviews for first {users} registered users
                  of the month
                </a>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ListItem;
