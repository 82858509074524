import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Button, Form, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

const ForgotPassword = () => {
  // Use useLocation to access the location object
  const location = useLocation();
  const navigate = useNavigate();

  // Access the state passed through navigate function
  const { state } = location;
  const isChangePassword = state?.isChangePassword;

  // Conditional rendering based on isChangePassword
  const headingText = isChangePassword ? "Reset Password" : "Forgot Password";
  const [email, setEmail] = useState(""); // State to store email
  const [wrongcredentials, setwrongcredentials] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responsemessage, setresponsemessage] = useState("");
  const [remainingTries, setremainingTries] = useState(null);

  // State to store the security question text
  const [securityQuestion, setSecurityQuestion] = useState("");
  // State to control the visibility of the security question form item
  const [showSecurityQuestion, setShowSecurityQuestion] = useState(false);

  // Update email state on input change
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  // Function to handle "Go Back" action
  const handleGoBack = () => {
    window.history.back();
  };

  const handleSignOut = async (shouldReload = false) => {
    try {
      await fetch(`${process.env.REACT_APP_NODE_SERVER_URL}user/signout`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      localStorage.removeItem("userData");

      // Broadcast the sign-out event to other tabs
      localStorage.setItem("signOutEvent", Date.now().toString());

      if (shouldReload) {
        // Broadcast a custom event to trigger reload in all tabs
        window.dispatchEvent(new Event("signout-reload"));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onFinish = async (values) => {
    try {
      setwrongcredentials(false);
      setLoading(true);

      if (!email) {
        setwrongcredentials(true);
        setresponsemessage(
          "Please provide your email address to receive a password reset link."
        );
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}reset/resetpassword`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        if (Object.keys(values).length === 1) {
          if (result.status === "passed") {
            setLoading(false);
            setSecurityQuestion(result.question);
            setShowSecurityQuestion(true);
          } else if (result.status === "suspended") {
            setLoading(false);
            await handleSignOut(false); // No reload
            navigate("/suspended");
          } else {
            setwrongcredentials(true);
            setresponsemessage(result.message);
            setLoading(false);
          }
        } else {
          if (result.status === "passed") {
            setLoading(false);
            navigate("/resetpasswordemailsent");
          } else if (result.status === "suspended") {
            setLoading(false);  
            await handleSignOut(false); // No reload
            navigate("/suspended");
          } else {
            setwrongcredentials(true);
            setremainingTries(result.remaining_tries);
            setresponsemessage(result.message);
            setLoading(false);
          }
        }
      }
    } catch (error) {
      console.error("Error during data submission:", error);
      setresponsemessage("Error during data submission. Please try again.");
      setLoading(false);
    }
  };

  return (
    <>
      <div className="arrow-container_reset">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={handleGoBack}
          className="back-icon-reset"
        />
      </div>
      <div className="Main_div_forgot">
        <div className="forgot_heading" style={{ fontSize: "30px" }}>
          {headingText}
        </div>
        <hr style={{ marginTop: "-0.0rem" }}></hr>
        <p
          className="signin_heading"
          style={{ fontSize: "14px", paddingBottom: "10px" }}
        >
          Enter your email address below. If it is linked to a registered
          account, you will receive an email to reset your password.
        </p>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="email"
            label={<span className="label-font">Email</span>}
            rules={[
              {
                type: "email",
                message: "The input is not valid email.",
              },
              {
                required: true,
                message: "Please provide your email.",
              },
            ]}
            className="form-item label-font"
          >
            <Input
              className="input-field"
              placeholder="Enter your email"
              onChange={handleEmailChange} // Handle email input change
            />
          </Form.Item>

          {showSecurityQuestion && (
            <>
              <Form.Item
                name="securityAnswer"
                label={
                  <span className="label-font">
                    🔑 Security question: {securityQuestion}
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message:
                      "Please provide the answer to your security question.",
                  },
                ]}
                className="form-item label-font"
              >
                <Input
                  className="input-field"
                  placeholder="Enter your security question answer"
                />
              </Form.Item>
              {remainingTries != null && (
                <Form.Item className="wrong_credentials_div">
                  <p
                    className="label-font"
                    style={{
                      color: "orange",
                      paddingBottom: "8px",
                    }}
                  >
                    💡 Remaining Attempts: {remainingTries}
                  </p>
                </Form.Item>
              )}
            </>
          )}

          {wrongcredentials && (
            <Form.Item className="wrong_credentials_div">
              <p className="label-font" style={{ color: "red" }}>
                {responsemessage}
              </p>
            </Form.Item>
          )}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="submit-button"
              disabled={loading || remainingTries === 0}
            >
              {loading ? (
                <span>
                  <span className="loading-dot"></span>
                  <span className="loading-dot"></span>
                  <span className="loading-dot"></span>
                </span>
              ) : (
                <span
                  className="label-font"
                  style={{
                    color: remainingTries === 0 ? "#364545" : "white",
                    fontSize: "17px",
                  }}
                >
                  Submit
                </span>
              )}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ForgotPassword;
