import { utils, read } from "xlsx";

export const arraycontainskeys = async (
  selectedcolumntoprocess,
  Required_columns_length
) => {
  if (
    Object.keys(selectedcolumntoprocess).length < Required_columns_length ||
    !selectedcolumntoprocess.hasOwnProperty("Reviews")
  ) {
    return false;
  } else {
    return true;
  }
};

export const xlsxToCsv = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, {
        type: "array",
        cellDates: true,
        dateNF: "mm/dd/yyyy;@",
      });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert the worksheet to an array of arrays, keeping the date format
      const jsonData = utils.sheet_to_json(worksheet, {
        header: 1,
        blankrows: false,
        raw: false,
      });

      // Filter out empty rows
      const filteredData = jsonData.filter((row) =>
        row.some((cell) => cell !== null && cell !== "")
      );

      // Resolve the promise with the filtered data
      resolve(filteredData);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export const countSentiments = (array) => {
  let positiveReviewsCount = 0;
  let mixedReviewsCount = 0;

  if (array.length === 0) {
    return 0;
  }

  array.forEach((item) => {
    if (item.overall_sentiment === "positive") {
      positiveReviewsCount++;
    } else if (
      item.overall_sentiment === "mixed" &&
      item.confidence_scores["positive"] >= 0.5
    ) {
      mixedReviewsCount++;
    }
  });

  return ((positiveReviewsCount + mixedReviewsCount / 4) / array.length) * 80;
};

export const calculateSentimentScore = (array, array_phrases) => {
  let sentimentReviewsScore = countSentiments(array);
  let positivePhrases = countAllSentiments(array_phrases);

  if (sentimentReviewsScore === 0) {
    sentimentReviewsScore = 1;
  }

  const positiveCount = positivePhrases["positive"] || 0;
  const negativeCount = positivePhrases["negative"] || 0;

  let sentimentScore;
  if (positiveCount + negativeCount === 0) {
    sentimentScore = sentimentReviewsScore;
  } else {
    sentimentScore =
      sentimentReviewsScore +
      (positiveCount / (positiveCount + negativeCount)) * 20;
  }

  return Math.ceil(sentimentScore);
};

export const countAllSentiments = (array) => {
  if (array === null) {
    return 0;
  }

  let sentimentCounts = {
    positive: 0,
    negative: 0,
    neutral: 0,
    mixed: 0,
  };

  array.forEach((item) => {
    switch (item.sentiment) {
      case "positive":
        if (item.scores.positive > 0.8) {
          sentimentCounts.positive++;
        }
        break;
      case "negative":
        if (item.scores.negative > 0.8) {
          sentimentCounts.negative++;
        }
        break;
      case "neutral":
        sentimentCounts.neutral++;
        break;
      case "mixed":
        sentimentCounts.mixed++;
        break;
      default:
        // Handle unexpected sentiment values, if necessary
        break;
    }
  });

  return sentimentCounts;
};

export const count_positive_phrases = (array) => {
  if (array === null) {
    return 0;
  }

  let sentimentCounts = {
    positive: 0,
    negative: 0,
    neutral: 0,
    mixed: 0,
  };

  array.forEach((item) => {
    switch (item.overall_sentiment) {
      case "positive":
        sentimentCounts.positive++;
        break;
      case "negative":
        sentimentCounts.negative++;
        break;
      case "neutral":
        sentimentCounts.neutral++;
        break;
      case "mixed":
        sentimentCounts.mixed++;
        break;
      default:
        // Handle unexpected sentiment values, if necessary
        break;
    }
  });

  // Divide each score by the length of the array
  let totalCount = array.length;
  Object.keys(sentimentCounts).forEach((key) => {
    sentimentCounts[key] = (sentimentCounts[key] / totalCount) * 100;
  });

  return sentimentCounts;
};

export const findMinMaxDates = (arr) => {
  if (arr.length === 0) {
    return { minDate: null, maxDate: null };
  }

  let minDate = new Date(arr[0].date);
  let maxDate = new Date(arr[0].date);

  arr.forEach((item) => {
    if (item.date) {
      const currentDate = new Date(item.date);
      if (currentDate < minDate) {
        minDate = currentDate;
      }
      if (currentDate > maxDate) {
        maxDate = currentDate;
      }
    }
  });

  return {
    minDate: isNaN(minDate.getTime()) ? null : minDate,
    maxDate: isNaN(maxDate.getTime()) ? null : maxDate,
  };
};
