import React, { useState, useRef, useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import "./style.css";

const OTPPage = () => {
  const navigate = useNavigate();

  const id_to_be_passed = localStorage.getItem("pie_user_Id");
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  const remember = queryParams.get("remember");

  const [otp, setOtp] = useState(["", "", "", ""]);

  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, setUserEmail] = useState(""); // State to hold user email
  const otpRefs = useRef([null, null, null, null]);
  const [countdown, setCountdown] = useState(60);

  const [resending, setResending] = useState(false);
  const [resendMessage, setResendMessage] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const submitButtonRef = useRef(null);

  const [displayallowcookies, setdisplayallowcookies] = useState(false);

  useEffect(() => {
    // Only start the countdown if it's greater than 0
    if (countdown > 0) {
      // Decrease countdown every second
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]); // Dependency on countdown to update every second

  const handleDigitInput = (index, value) => {
    // Ensure only numeric values are allowed
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value[0]; // Take only the first character
      setOtp(newOtp);

      if (value && index < 3) {
        otpRefs.current[index + 1].focus();
      } else if (value && index === 3) {
        submitButtonRef.current.focus(); // Focus the submit button when the last digit is entered
      }
    }
  };

  const isOTPComplete = otp.every((digit) => digit && digit.length === 1);

  const handleOTPVerification = async () => {
    setIsLoading(true);
    setSubmissionAttempted(true); // Set the submission attempt state to true
    seterrorMessage("");
    setResendMessage("");
    if (isOTPComplete) {
      const otpNumber = otp.join("");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}user/verifyOTP`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include", // This is important for cookies to be sent and received
            body: JSON.stringify({
              userId: id_to_be_passed,
              remember: remember,
              otp: otpNumber,
            }),
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        if (data.status == "Passed") {
          localStorage.setItem(
            "userData",
            JSON.stringify({
              username: data.userData.username,
              firstName: data.userData.firstName,
              lastName: data.userData.lastName,
              expiresAt: Date.now() + data.userData.expiresIn * 1000,
            })
          );
          navigate("/pie");
        } else {
          seterrorMessage(data.message);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching user email:", error);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleResendOTP = async (event) => {
    event.preventDefault(); // Prevent default if using an anchor tag
    setResending(true);

    // Implement your logic to send a POST request for resending the OTP
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}resend/resendOTP`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: id_to_be_passed,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setResending(false);
      setCountdown(60);
      setResendMessage(
        "OTP has been resent. Please check your email for the new OTP."
      );
      // Handle the response, maybe start countdown again or show a message
    } catch (error) {
      setResending(false);
      setResendMessage("Failed to resend OTP. Please try again.");
      // Handle error cases
    }
  };

  const handleBackspace = (index, e) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      if (newOtp[index]) {
        // If there's a value at the current index, remove it
        newOtp[index] = "";
      } else if (index > 0) {
        // If the current index is empty, remove the value from the previous index
        newOtp[index - 1] = "";
        otpRefs.current[index - 1].focus();
      }
      setOtp(newOtp);
    }
  };

  useEffect(() => {
    // Fetch user email based on userId
    const fetchUserEmail = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}user/getUserbyId`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: id_to_be_passed,
            }),
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        const userEmailFromServer = data.email; // Replace 'email' with the actual key from the server response
        setUserEmail(userEmailFromServer); // Set the user email from server response
      } catch (error) {
        console.error("Error fetching user email:", error);
        // Removed setting of dummy email here to avoid overwriting on each error
      }
    };

    fetchUserEmail(); // Call the function to fetch user email when the component mounts
    otpRefs.current[0].focus();
  }, []); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    const test = "test";
    Cookies.set(test, test);
    const result = Cookies.get(test) === test;
    if (result) {
      setdisplayallowcookies(false);
    } else {
      setdisplayallowcookies(true);
    }
    Cookies.remove(test);
  }, []);

  const formatEmail = (email) => {
    // Split the email into local and domain parts
    const [local, domain] = email.split("@");

    if (local.length <= 3) {
      // If the local part is 3 characters or shorter, display the email as is
      return email;
    } else {
      const firstThreeChars = local.substring(0, 3);
      const lastChar = local.slice(-1);
      const obfuscationLength = Math.min(local.length - 4, 4);
      const obfuscationChar = local.length > 10 ? "." : "*";
      const obfuscation = obfuscationChar.repeat(obfuscationLength);
      const formattedLocal = `${firstThreeChars}${obfuscation}${lastChar}`;
      return `${formattedLocal}@${domain}`;
    }
  };

  return (
    <div className="OTP_ParentDiv">
      <div className="card">
        <div className="card-header">
          <div
            className="header-subtext label-font"
            style={{ fontSize: "20px" }}
          >
            Enter the verification code sent to
          </div>
          <div className="verification-number">{formatEmail(userEmail)}</div>{" "}
          {/* Display user email */}
        </div>
        <div className="otp-container">
          {otp.map((digit, index) => (
            <input
              className="otp-input"
              type="text"
              inputMode="numeric"
              autoComplete="one-time-code"
              maxLength={1} // Limit input to one character
              key={index}
              ref={(el) => (otpRefs.current[index] = el)}
              value={digit}
              onChange={(e) => handleDigitInput(index, e.target.value)}
              onKeyDown={(e) => handleBackspace(index, e)}
            />
          ))}
        </div>
        {submissionAttempted && !isOTPComplete && (
          <div className="label-font" style={{ color: "red" }}>
            Please enter all four digits of your OTP.
          </div>
        )}

        {errorMessage && (
          <div
            className="label-font"
            style={{ color: "red", textAlign: "center" }}
          >
            {errorMessage}
          </div>
        )}

        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50px",
            }}
          >
            <span>
              <span
                className="loading-dot"
                style={{ backgroundColor: "#364545" }}
              ></span>
              <span
                className="loading-dot"
                style={{ backgroundColor: "#364545" }}
              ></span>
              <span
                className="loading-dot"
                style={{ backgroundColor: "#364545" }}
              ></span>
            </span>
          </div>
        ) : (
          <button
            type="button"
            className="submit-btn"
            onClick={handleOTPVerification}
            disabled={isLoading} // Disable the button while loading
            ref={submitButtonRef}
          >
            <span
              className="label-font"
              style={{
                color: "white",
                fontSize: "17px",
              }}
            >
              Submit
            </span>
          </button>
        )}

        {resendMessage && (
          <div className="resend-message label-font text-center">
            {resendMessage}
          </div>
        )}
        <div className="otp-resend-block label-font">
          Didn’t get the OTP?{" "}
          {resending ? (
            <span className="label-font">
              Resending OTP
              <span>
                <span
                  className="loading-dot"
                  style={{ backgroundColor: "#364545" }}
                ></span>
                <span
                  className="loading-dot"
                  style={{ backgroundColor: "#364545" }}
                ></span>
                <span
                  className="loading-dot"
                  style={{ backgroundColor: "#364545" }}
                ></span>
              </span>
            </span>
          ) : countdown > 0 ? (
            <span className="label-font">
              Please wait for {countdown} seconds
            </span>
          ) : (
            <a
              href="#"
              className="otp-resend label-font"
              onClick={handleResendOTP}
              style={{ cursor: "pointer", textDecoration: "underline" }}
              role="button"
            >
              Click to Resend
            </a>
          )}
        </div>
        {displayallowcookies && (
          <div>
            <p
              className="label-font"
              style={{ marginTop: "1rem", color: "red" }}
            >
              Please allow storage of necessary cookies to proceed with the
              verification process.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default OTPPage;
