import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./DetailedFreqPhrases.css";

const DetailedFreqPhrases = ({
  data,
  tabIndex,
  setTabIndex,
  selectedReportId,
}) => {
  const [localData, setLocalData] = useState([]);
  const isSmallScreen = window.innerWidth < 335;

  useEffect(() => {
    setLocalData(data || []);
  }, [data, selectedReportId]);

  if (!localData) {
    return <div>Loading...</div>;
  }

  const positivePhrases = localData.filter(
    (item) => item.sentiment === "positive" && item.scores.positive > 0.8
  );

  const negativePhrases = localData.filter(
    (item) => item.sentiment === "negative" && item.scores.negative > 0.8
  );

  if (positivePhrases.length === 0 && negativePhrases.length === 0) {
    return (
      <div>The uploaded file contains no positive or negative phrases.</div>
    );
  }

  return (
    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
      <div className="d-flex margin-2">
        <TabList>
          <Tab>
            <h6 className="title-phrases">
              {isSmallScreen ? "Positive Phrases" : "All Positive Phrases"}
            </h6>
          </Tab>
          <Tab>
            <h6 className="title-phrases">
              {isSmallScreen ? "Negative Phrases" : "All Negative Phrases"}
            </h6>
          </Tab>
        </TabList>
      </div>

      <TabPanel>
        {positivePhrases.length > 0 ? (
          positivePhrases.map((item, index) => (
            <div key={index} className="CompPhrase">
              <p className="title-phrases"> {item.text}</p>
            </div>
          ))
        ) : (
          <div>No positive phrases available.</div>
        )}
      </TabPanel>

      <TabPanel>
        {negativePhrases.length > 0 ? (
          negativePhrases.map((item, index) => (
            <div key={index} className="CompPhrase">
              <p className="title-phrases">{item.text}</p>
            </div>
          ))
        ) : (
          <div>No negative phrases available.</div>
        )}
      </TabPanel>
    </Tabs>
  );
};

export default DetailedFreqPhrases;
