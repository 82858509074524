import "./style.css";

const ReviewStatus = ({ userreviewsdata }) => {
  return (
    <div className="review-container">
      <div className="review-row">
        <div>
          <hr></hr>
        </div>
        <div className="review-item">
          <span className="label label-font">Total free reviews allowed:</span>
          <span className="value label-font" style={{ fontSize: "16px" }}>
            {userreviewsdata.total_reviews}
          </span>
        </div>
        <div className="review-item">
          <span className="label label-font">
            Total free reviews remaining:
          </span>
          <span className="value label-font" style={{ fontSize: "16px" }}>
            {userreviewsdata.total_reviews - userreviewsdata.reviews_processed}
          </span>
        </div>
        <div className="review-item">
          <span className="label label-font">
            Total free reviews processed:
          </span>
          <span className="value label-font" style={{ fontSize: "16px" }}>
            {userreviewsdata.reviews_processed}
          </span>
        </div>
        <div className="review-item">
          <span className="label label-font">Free reviews end date:</span>
          <span className="value label-font" style={{ fontSize: "16px" }}>
            {userreviewsdata.end_date
              ? new Date(userreviewsdata.end_date).toLocaleDateString()
              : ""}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReviewStatus;
