import React, { useEffect, useRef } from "react";
import "./style.css";

const CookieDeclaration = () => {
  const legalContainerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.id = "CookieDeclaration";
    script.src =
      "https://consent.cookiebot.com/b394e2bc-013f-4abb-bdd2-231927958724/cd.js";
    script.type = "text/javascript";
    script.async = true;

    legalContainerRef.current.appendChild(script);

    return () => {
      if (legalContainerRef.current) {
        legalContainerRef.current.removeChild(script);
      }
    };
  }, []);

  return (
    <>
      <div className="policies_maindiv">
        <div ref={legalContainerRef} className="cookie-policy-maindiv">
          <p style={{ fontSize: "25px", color: "#364545", fontWeight: "bold" }}>
            Cookie Policy
          </p>
        </div>
        <div className="cookie-policy-maindiv">
          <div class="cookie-table-container">
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
                marginTop: "-5rem",
              }}
            >
              Types of cookies
            </p>
            <p>
              1. This website uses the following cookies some of which will
              share data with US based partners:
            </p>
            <table class="cookie-table">
              <thead>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Type of cookie</th>
                  <th style={{ fontWeight: "bold" }}>Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Necessary cookies</td>
                  <td>
                    Necessary cookies help make a website usable by enabling
                    basic functions like page navigation and access to secure
                    areas of the website. The website cannot function properly
                    without these cookies. They include, for example, cookies
                    that enable you to log into secure areas of the Website,
                    submit contact us form, secure website by distinguishing
                    between humans and bots.
                  </td>
                </tr>
                <tr>
                  <td>Preferences cookies</td>
                  <td>
                    These are used to recognise you when you return to our
                    website. This enables us to personalise our content for
                    you,greet you by name and remember your preferences (for
                    example, your choice of language or region). By allowing the
                    use of preference cookies, you agree to our placement of
                    functionality cookies.
                  </td>
                </tr>
                <tr>
                  <td>Statistics cookies</td>
                  <td>
                    These cookies allow us to recognise and count the number of
                    visitors and to see how visitors move around our website
                    when they are using it. This helps us to improve the way our
                    website works, for example, by ensuring that users are
                    finding what they are looking for easily.
                  </td>
                </tr>
                <tr>
                  <td>Marketing cookies</td>
                  <td>
                    Marketing cookies are used to track visitors across
                    websites. The intention is to display ads that are relevant
                    and engaging for the individual user and thereby more
                    valuable for publishers and third party advertisers.
                  </td>
                </tr>
                <tr>
                  <td>Unclassified cookies</td>
                  <td>
                    Unclassified cookies are cookies that we are in the process
                    of classifying, together with the providers of individual
                    cookies.
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ paddingTop: "20px" }}>
              2. You can find a list of the cookies that we use in the Cookie
              Declaration mentioned above.
            </p>
            <p>
              3. We have carefully chosen these cookies and have taken steps to
              ensure that your privacy is protected and respected at all times.
            </p>
            <p style={{ fontSize: "16px", fontWeight: "bolder" }}>
              How to control your cookies
            </p>
            <p>
              1. You can change your cookie preferences at any time by clicking
              on the ‘Cookie Settings’ icon banner on bottom left hand side of
              the website. You can then adjust the available sliders to ‘On’ or
              ‘Off’, then clicking ‘Save and Close’. You may need to refresh
              your page for your settings to take effect.
            </p>
            <div class="cookie-info">
              <p>
                2. You can choose to enable or disable cookies in your internet
                by default, most internet browsers accept cookies, but allow
                some control of most cookies through the browser settings. To
                find out more about cookies, including how to see what cookies
                have been set, visit{" "}
                <a href="http://www.aboutcookies.org">
                  <span className="cookie_web_link">www.aboutcookies.org</span>
                </a>{" "}
                or{" "}
                <a href="http://www.allaboutcookies.org">
                  <span className="cookie_web_link">
                    www.allaboutcookies.org
                  </span>
                </a>
                . Find out how to manage cookies on popular browsers:
              </p>
              <ul>
                <li>
                  <a
                    href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en"
                    style={{
                      color: "#2ea3f2",
                    }}
                  >
                    Google Chrome
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                    style={{
                      color: "#2ea3f2",
                    }}
                  >
                    Microsoft Edge
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                    style={{
                      color: "#2ea3f2",
                    }}
                  >
                    Mozilla Firefox
                  </a>
                </li>

                <li>
                  <a
                    href="https://help.opera.com/en/latest/web-preferences/"
                    style={{
                      color: "#2ea3f2",
                    }}
                  >
                    Opera
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.apple.com/en-gb/safari"
                    style={{
                      color: "#2ea3f2",
                    }}
                  >
                    Apple Safari
                  </a>
                </li>
              </ul>
              <p>
                To find information relating to other browsers, visit the
                browser developer's website.
              </p>
            </div>
            <p>
              3. You can switch off cookies at any time, however, you may lose
              information that enables you to access the Website more quickly
              and efficiently.
            </p>
            <p>
              4. It is recommended that you ensure that your internet browser is
              up-to-date and that you consult the help and guidance provided by
              the developer of your internet browser if you are unsure about
              adjusting your privacy settings.
            </p>
            <p style={{ fontSize: "16px", fontWeight: "bolder" }}>
              Changes to this policy
            </p>
            <p>
              Resilient Technologies Ltd reserves the right to change this
              cookie policy as we may deem necessary from time to time or as may
              be required by any changes will be immediately posted on the
              Website and you are deemed to have accepted the terms of the
              cookie policy on your first use of the Website following the
              alterations. This policy was last revised on Mar 01, 2024.
            </p>
            <p style={{ fontSize: "16px", fontWeight: "bolder" }}>
              Contact details
            </p>
            <p>
              1. The Website is owned by Resilient Technologies Ltd incorporated
              in England and Wales with registered number{" "}
              <span className="label-font" style={{ fontSize: "15px" }}>
                14253939
              </span>{" "}
              whose registered office is at{" "}
              <span className="label-font" style={{ fontSize: "15px" }}>
                71-75
              </span>{" "}
              Shelton Street, Covent Garden, London, WC2H 9JQ, England.
            </p>
            <p>2. You may contact us using the contact form on this Website.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookieDeclaration;
