import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LockOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";

import "./style.css";

interface YourComponentProps {
  setloginpageshow: (value: boolean) => void;
}

const SignInPopupModal = ({ setloginpageshow }: YourComponentProps) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(""); // State to store email
  const [wrongcredentials, setwrongcredentials] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responsemessage, setresponsemessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [resendemail, setresendemail] = useState(false);
  const [emailsentsuccessfull, setemailsentsuccessfull] = useState(false);

  // Update email state on input change
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleResendClick = async () => {
    if (!email) {
      // Handle case where email is not entered
      setresponsemessage("Please enter an email first to proceed.");
      return;
    }

    setIsProcessing(true);

    const response = await fetch(
      `${process.env.REACT_APP_NODE_SERVER_URL}resend/resendverification`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }), // Pass only email
      }
      // ... rest of the fetch logic
    );

    const data = await response.json();

    if (response.ok) {
      if (data["status"] == "passed") {
        setresendemail(false);
        setemailsentsuccessfull(true);
      }
    } else {
      setresponsemessage(data["message"]);
    }

    setIsProcessing(false);
  };

  const onFinish = async (values: any) => {
    try {
      setemailsentsuccessfull(false);
      setwrongcredentials(false);
      setresendemail(false);
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}user/signinUser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      if (result.status == "passed") {
        // const userIdString = JSON.stringify(result.userId);
        localStorage.setItem("pie_user_Id", result.userId);
        // sessionStorage.setItem("isAuthenticated", "true");
        // navigate("/pie");
        navigate(`/otp?remember=${values.remember}`); // Redirect to /pie route
        setLoading(false);
      } else if (result.status == "notverified") {
        setLoading(false);
        setresendemail(true);
        // Handle invalid credentials (show error message, etc.)
      } else {
        setwrongcredentials(true);
        setresponsemessage(result["message"]);
        setLoading(false);
        // Handle invalid credentials (show error message, etc.)
      }
    } catch (error) {
      console.error("Error during data submission:", error);
      setresponsemessage("Error during data submission. Please try agian.");
      setLoading(false);
    }
  };

  return (
    <div className="Main_div">
      <a className="signin_heading">Login</a>

      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          name="email"
          label={
            <span className="label-font_signin">
              Email<span className="required-asterisk">*</span>
            </span>
          }
          rules={[
            {
              type: "email",
              message: "The input is not valid email.",
            },
            {
              required: true,
              message: "Please provide your email.",
            },
          ]}
          className="form-item"
          required={false}
        >
          <Input
            className="input-field"
            placeholder="Enter your email"
            maxLength={50}
            onChange={handleEmailChange} // Handle email input change
          />
        </Form.Item>
        <Form.Item
          name="password"
          className="input-field"
          label={
            <span className="label-font_signin">
              Password<span className="required-asterisk">*</span>
            </span>
          }
          rules={[{ required: true, message: "Please provide your password." }]}
          required={false}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            maxLength={30}
            placeholder="Enter your password"
          />
        </Form.Item>
        <Form.Item className="forgot_div">
          <a
            className="forgot_div_text label-font_signin"
            onClick={() => {
              navigate("/forgotpassword");
            }}
          >
            Forgot Password?
          </a>
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          className="remember_div"
        >
          <Checkbox>
            {<span className="label-font_signin">Remember me</span>}
          </Checkbox>
        </Form.Item>

        {emailsentsuccessfull && (
          <div className="label-font_signin">
            <span style={{ color: "green" }}>
              The verification email has been sent successfully.
            </span>
          </div>
        )}

        {resendemail && (
          <Form.Item className="verification-message">
            {isProcessing ? (
              <div className="label-font_signin">
                <span className="animated-dots">
                  Sending verification email
                </span>
              </div>
            ) : (
              <div>
                <p className="label-font_signin" style={{ color: "red" }}>
                  Please verify your account as it has not been verified yet.
                </p>

                <Button
                  onClick={handleResendClick}
                  className="resend_verification"
                  disabled={isProcessing}
                >
                  <span
                    className="label-font_signin"
                    style={{ color: "white" }}
                  >
                    Resend verification email
                  </span>
                </Button>
              </div>
            )}
          </Form.Item>
        )}

        {wrongcredentials && (
          <Form.Item className="wrong_credentials_div">
            <p className="label-font_signin" style={{ color: "red" }}>
              {responsemessage}
            </p>
          </Form.Item>
        )}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="submit-button"
            disabled={loading}
          >
            {loading ? (
              <span>
                <span className="loading-dot"></span>
                <span className="loading-dot"></span>
                <span className="loading-dot"></span>
              </span>
            ) : (
              <span
                className="label-font_signin"
                style={{
                  color: "white",
                  fontSize: "17px",
                }}
              >
                Login
              </span>
            )}
          </Button>

          <a
            className="register_name"
            onClick={(e) => {
              e.preventDefault();
              setloginpageshow(false);
            }}
          >
            <p
              className="label-font_signin"
              style={{
                textAlign: "center",
                paddingTop: "5px",
                fontSize: "16px",
              }}
            >
              Create your PIE account
            </p>
          </a>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignInPopupModal;
