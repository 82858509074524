import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";

const HistogramChart = ({ reportsdata }) => {
  const chartRef = useRef(null);
  // State to store calculated height
  const [chartHeight, setChartHeight] = useState("400px");
  const isMobile = window.innerWidth < 610;

  useEffect(() => {
    // Dynamically adjust chart height based on screen size
    const updateChartSize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 768) {
        // For tablets, laptops, and desktops
        setChartHeight("400px"); // Larger height for larger screens
      } else {
        setChartHeight("300px"); // Smaller height for smaller screens
      }
    };

    // Call updateChartSize on component mount and window resize
    window.addEventListener("resize", updateChartSize);
    updateChartSize(); // Initial size adjustment

    return () => {
      window.removeEventListener("resize", updateChartSize);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const ctx = document.getElementById("sentimentScoreChart").getContext("2d");

    const reportScores = reportsdata.map((report) => report.report_score);
    const reportLabels = reportsdata.map((report) => {
      const createdDate = new Date(report.created_at);
      const formattedDate = createdDate.toLocaleDateString();
      let label = `${report.reportname.substring(0, 3)}... - ${formattedDate}`;
      return label;
    });

    chartRef.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels: reportLabels,
        datasets: [
          {
            data: reportScores,
            backgroundColor: reportScores.map((score) => {
              if (score < 40) return "rgba(255, 0, 0, 0.4)";
              else if (score < 70) return "rgba(255, 165, 0, 0.4)";
              else return "rgba(0, 128, 0, 0.4)";
            }),
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: function (tooltipItems) {
                return reportsdata[tooltipItems[0].dataIndex].reportname;
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              display: !isMobile, // Hide ticks (labels) on mobile screens
            },
          },
          y: {
            beginAtZero: true,
            max: 100,
            title: {
              display: true,
              text: "Sentiment Score",
            },
          },
        },
      },
    });

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [reportsdata]);

  // Use the state-managed chartHeight for dynamic resizing
  return (
    <canvas
      id="sentimentScoreChart"
      style={{ height: chartHeight, width: "100%" }}
    ></canvas>
  );
};

export default HistogramChart;
