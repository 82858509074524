export default function FeatureItem({ title, icon, text }: any) {
  return (
    <div
      className="border rounded m-3 shadow feature-card"
      onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
      onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
    >
      <div className="card-body d-flex flex-column align-items-center pt-4">
        <img src={icon} alt="feature_icon" width={"33%"} />
        <h6 className="card-title text-center fs-5 mt-4">{title}</h6>
        <div className="w-25 my-2 bar-height"></div>
        <p className="card-subtitle text-center mb-2 fs-6 px-3 pb-3 text-dark">
          {text}
        </p>
      </div>
    </div>
  );
}
