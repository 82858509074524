import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import "./MonthlyTrendChart.css";

const apiUrl = process.env.REACT_APP_API_URL;
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
  Legend
);

const CustomLegend = () => {
  const legendItems = [
    { label: "Positive", color: "#4BCA81" },
    { label: "Neutral", color: "#FFA600" },
    { label: "Negative", color: "#FF0015" },
  ];

  return (
    <div
      className="custom-legend"
      style={{ display: "flex", flexDirection: "column" }}
    >
      {legendItems.map((item, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "4px",
            marginRight: "80px",
          }}
        >
          <div
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: item.color,
              marginRight: "8px",
            }}
          ></div>
          <div>{item.label}</div>
        </div>
      ))}
    </div>
  );
};

const MonthlyTrendChart = ({ selectedReportId }) => {
  // const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedYear, setSelectedYear] = useState(0);

  const [trendType, setTrendType] = useState("monthly"); // New state for selecting trend type
  const [sentimentData, setSentimentData] = useState([]);
  const [sentimentDataWeekly, setSentimentDataWeekly] = useState([]);
  const [sentimentDataYearly, setSentimentDataYearly] = useState([]);

  const [isEmptyData, setIsEmptyData] = useState(false); // State to track if data is empty
  // const selectedReportId = localStorage.getItem("selectedReportId") || sessionStorage.getItem("selectedReportId");

  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });
  const [fetchError, setFetchError] = useState(false); // State to track fetch errors
  /* Fetches sentiment data and updates the chart with new data when selected year changes */
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${apiUrl}/sentiment_trend_weekly/${selectedReportId}`
      );
      const rawData = await response.json();

      setSentimentDataWeekly(rawData);
    };

    fetchData();
  }, [selectedReportId]);
  useEffect(() => {
    // ... other useEffects ...

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/yearly_sentiment_trend/${selectedReportId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const rawData = await response.json();
        setSentimentDataYearly(rawData);
      } catch (error) {
        console.error("Fetch error:", error);
        setFetchError(true);
      }
    };

    fetchData();
  }, [selectedReportId]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/sentiment_trend/${selectedReportId}`
        );
        console.log('RESPONSE: ', response)
        if (!response.ok) {
          // Check if the response status is not OK
          throw new Error("Network response was not ok");
        }
        const rawData = await response.json();
        if (rawData.length === 0) {
          // console.log('RAW DATA IS EMPTY!')
          // Check if the returned data is an empty array
          setIsEmptyData(true);
          return; // Exit early if no data is returned
        } else {
          setIsEmptyData(false);
        }

        setSentimentData(rawData);
        setFetchError(false); // Reset the error state if data fetch is successful
      } catch (error) {
        console.error("Fetch error:", error);
        setFetchError(true); // Set the error state if there is an error
      }
    };

    fetchData();
  }, [selectedReportId]);
  useEffect(() => {
    if (trendType === "monthly") {
      if (selectedYear === 0) {
        const processedData = processDataForAllYears(sentimentData);
        setData(processedData);
      } else {
        const processedData = processData(sentimentData, selectedYear);
        setData(processedData);
      }
    } else if (trendType === "weekly") {
      if (selectedYear === 0) {
        const processedData = processDataAllWeek(
          sentimentDataWeekly,
          selectedYear
        );
        setData(processedData);
      } else {
        const processedData = processDataWeekly(
          sentimentDataWeekly,
          selectedYear
        );
        setData(processedData);
      }
    } else if (trendType === "yearly") {
      const processedData = processYearlyData(sentimentDataYearly);
      setData(processedData);
    }
  }, [sentimentData, selectedYear, trendType, selectedReportId]);

  const processDataWeekly = (rawData, year) => {
    // Assuming a maximum of 52 weeks in a year for the labels
    const labels = Array.from({ length: 52 }, (_, i) => `${i + 1}`);
    const datasetMap = {};

    rawData.forEach((entry) => {
      if (entry.year === year) {
        entry.sentiments.forEach((sentiment) => {
          if (!datasetMap[sentiment.sentiment]) {
            datasetMap[sentiment.sentiment] = {
              label: sentiment.sentiment,
              data: new Array(52).fill(0), // Initialize array for 52 weeks
              fill: false,
              borderColor: getColor(sentiment.sentiment),
              backgroundColor: getColor(sentiment.sentiment),
              tension: 0.4,
              pointRadius: 5,
              pointHoverRadius: 7,
              pointHitRadius: 10,
              pointBackgroundColor: "#fff",
              pointBorderColor: getColor(sentiment.sentiment),
              pointHoverBackgroundColor: getColor("#fff"),
              pointHoverBorderColor: "#fff",
            };
          }
          // Assuming the 'week' property in the entry is the week number (1-52)
          datasetMap[sentiment.sentiment].data[entry.week - 1] =
            sentiment.count;
        });
      }
    });

    return {
      labels: labels,
      datasets: Object.values(datasetMap),
    };
  };

  const processData = (rawData, year) => {
    const labels = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "june",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const datasetMap = {};

    rawData.forEach((entry) => {
      if (entry.year === year) {
        entry.sentiments.forEach((sentiment) => {
          if (!datasetMap[sentiment.sentiment]) {
            datasetMap[sentiment.sentiment] = {
              label: sentiment.sentiment,
              data: new Array(12).fill(0), // Initialize array for 12 months
              fill: false,
              borderColor: getColor(sentiment.sentiment),
              backgroundColor: getColor(sentiment.sentiment),
              tension: 0.4,
              pointRadius: 5,
              pointHoverRadius: 7,
              pointHitRadius: 10,
              pointBackgroundColor: "#ffffff",
              pointBorderColor: getColor(sentiment.sentiment),
              pointHoverBackgroundColor: getColor("#fff"),
              pointHoverBackgroundColor: "#ffffff", // Set hover background color to white

              pointHoverBorderColor: "#ffffff",
            };
          }
          datasetMap[sentiment.sentiment].data[entry.month - 1] =
            sentiment.count;
        });
      }
    });

    return {
      labels: labels,
      datasets: Object.values(datasetMap),
    };
  };

  const processDataForAllYears = (rawData) => {
    const datasetMap = {};
    const uniqueYearMonths = new Set();

    // Identify the range of years in rawData and initialize year-month combinations
    let minYear = Infinity;
    let maxYear = -Infinity;
    rawData.forEach((entry) => {
      minYear = Math.min(minYear, entry.year);
      maxYear = Math.max(maxYear, entry.year);
    });

    for (let year = minYear; year <= maxYear; year++) {
      for (let month = 1; month <= 12; month++) {
        uniqueYearMonths.add(`${year}-${month.toString().padStart(2, "0")}`);
      }
    }

    // Initialize datasetMap for each sentiment
    rawData
      .flatMap((entry) => entry.sentiments)
      .forEach((sentiment) => {
        if (!datasetMap[sentiment.sentiment]) {
          datasetMap[sentiment.sentiment] = {
            label: sentiment.sentiment,
            data: new Array(uniqueYearMonths.size).fill(0),
            fill: false,
            borderColor: getColor(sentiment.sentiment),
            backgroundColor: getColor(sentiment.sentiment),
            tension: 0.4,
            pointRadius: 5,
            pointHoverRadius: 7,
            pointHitRadius: 10,
            pointBackgroundColor: "#ffffff",
            // pointHoverBackgroundColor: getColor("#fff"),

            pointBorderColor: getColor(sentiment.sentiment),
            pointHoverBorderColor: "#ffffff",
          };
        }
      });

    // Populate datasetMap with counts
    rawData.forEach((entry) => {
      entry.sentiments.forEach((sentiment) => {
        const yearMonth = `${entry.year}-${entry.month
          .toString()
          .padStart(2, "0")}`;
        const dataIndex = [...uniqueYearMonths].indexOf(yearMonth);
        datasetMap[sentiment.sentiment].data[dataIndex] = sentiment.count;
      });
    });

    // Create labels
    const labelsForMonths = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const labels = [...uniqueYearMonths].sort().map((yearMonth) => {
      const [year, month] = yearMonth.split("-");
      return `${labelsForMonths[parseInt(month) - 1]} ${year}`;
    });

    return {
      labels: labels,
      datasets: Object.values(datasetMap),
    };
  };

  const processDataAllWeek = (rawData) => {
    const datasetMap = {};
    const uniqueYearWeeks = new Set();

    // Identify the range of years in rawData
    let minYear = Infinity;
    let maxYear = -Infinity;
    rawData.forEach((entry) => {
      minYear = Math.min(minYear, entry.year);
      maxYear = Math.max(maxYear, entry.year);
      uniqueYearWeeks.add(
        `${entry.year}-${entry.week.toString().padStart(2, "0")}`
      );
    });

    // Fill in missing year-week combinations
    for (let year = minYear; year <= maxYear; year++) {
      for (let week = 1; week <= 52; week++) {
        uniqueYearWeeks.add(`${year}-${week.toString().padStart(2, "0")}`);
      }
    }

    // Sort year-week combinations
    const sortedYearWeeks = [...uniqueYearWeeks].sort((a, b) => {
      const [yearA, weekA] = a.split("-").map(Number);
      const [yearB, weekB] = b.split("-").map(Number);
      return yearA !== yearB ? yearA - yearB : weekA - weekB;
    });

    rawData.forEach((entry) => {
      entry.sentiments.forEach((sentiment) => {
        if (!datasetMap[sentiment.sentiment]) {
          datasetMap[sentiment.sentiment] = {
            label: sentiment.sentiment,
            data: new Array(sortedYearWeeks.length).fill(0), // Initialize with zeros

            fill: false,
            borderColor: getColor(sentiment.sentiment),
            backgroundColor: getColor(sentiment.sentiment),
            tension: 0.4,
            pointRadius: 5,
            pointHoverRadius: 7,
            pointHitRadius: 10,
            pointBackgroundColor: "#ffffff",
            // pointHoverBackgroundColor: getColor("#fff"),

            pointBorderColor: getColor(sentiment.sentiment),
            pointHoverBorderColor: "#ffffff",
          };
        }
        const yearWeek = `${entry.year}-${entry.week
          .toString()
          .padStart(2, "0")}`;
        const dataIndex = sortedYearWeeks.indexOf(yearWeek);
        datasetMap[sentiment.sentiment].data[dataIndex] += sentiment.count;
      });
    });

    // Create labels based on all unique sorted year-week combinations
    const labels = sortedYearWeeks.map((yearWeek) => {
      const [year, week] = yearWeek.split("-");
      return week === "01" ? `Week 1, ${year}` : `Week ${parseInt(week)}`;
    });

    return {
      labels: labels,
      datasets: Object.values(datasetMap),
    };
  };

  const processYearlyData = (rawData) => {
    // Extracting the years and sorting them
    const labels = Object.keys(rawData).sort((a, b) => a - b);

    // Initialize datasets for each sentiment
    const datasetMap = {
      positive: {
        label: "Positive",
        data: [],
        borderColor: getColor("positive"),
        backgroundColor: getColor("positive"),
        tension: 0.4,
        pointRadius: 5,
        pointHoverRadius: 7,
        pointHitRadius: 10,
        pointBackgroundColor: "#fff",
        // pointBorderColor: getColor(sentiment),
        pointHoverBackgroundColor: getColor("#fff"),
        pointHoverBorderColor: "#fff",
      },
      neutral: {
        label: "Neutral",
        data: [],
        borderColor: getColor("neutral"),
        backgroundColor: getColor("neutral"),
        tension: 0.4,
        pointRadius: 5,
        pointHoverRadius: 7,
        pointHitRadius: 10,
        pointBackgroundColor: "#fff",
        // pointBorderColor: getColor(sentiment),
        pointHoverBackgroundColor: getColor("#fff"),
        pointHoverBorderColor: "#fff",
      },
      negative: {
        label: "Negative",
        data: [],
        borderColor: getColor("negative"),
        backgroundColor: getColor("negative"),
        tension: 0.4,
        pointRadius: 5,
        pointHoverRadius: 7,
        pointHitRadius: 10,
        pointBackgroundColor: "#fff",
        // pointBorderColor: getColor(sentiment),
        // pointHoverBackgroundColor: getColor("#fff"),
        pointHoverBorderColor: "#fff",
      },
    };

    // Populate the datasets with the data for each year
    labels.forEach((year) => {
      const yearData = rawData[year];

      // Calculate the total count for the year
      const totalCount = Object.values(yearData).reduce(
        (sum, count) => sum + count,
        0
      );

      // Calculate the percentage for each sentiment and add to the dataset
      ["positive", "neutral", "negative"].forEach((sentiment) => {
        // const percentage = totalCount > 0 ? (yearData[sentiment] / totalCount) * 100 : 0;
        const count = yearData[sentiment];
        datasetMap[sentiment].data.push(count.toFixed(2)); // Rounds to two decimal places
      });
    });
    // Convert the datasetMap into an array of datasets
    return {
      labels,
      datasets: Object.values(datasetMap),
    };
  };

  const getColor = (sentiment) => {
    switch (sentiment) {
      case "positive":
        return "#4BCA81";
      case "negative":
        return "#FF0015";
      case "neutral":
        return "#FFA600"; // Changed the color for neutral
      default:
        return "#6c757d";
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Number of Reviews", // Y-axis label
          color: "#666", // Optional: color of the label
          font: {
            size: 16, // Optional: size of the label text
            weight: "bold", // Optional: font weight
          },
        },
      },
      x: {
        ticks: { autoSkip: false },
        maxBarThickness: 100, // Adjust as needed
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
        align: "end",
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          padding: 20,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        backgroundColor: "white",
        titleColor: "black",
        titleFont: { weight: "bold" },
        bodyColor: "black",
        borderColor: "lightgray",
        borderWidth: 1,
        callbacks: {
          label: function (context) {
            let total = 0;
            // Sum up the counts for all sentiments in this dataIndex (year/month/week)
            context.chart.data.datasets.forEach((dataset) => {
              total += parseFloat(dataset.data[context.dataIndex]);
            });

            const value = parseFloat(context.dataset.data[context.dataIndex]);
            const percentage =
              total > 0 ? ((value / total) * 100).toFixed(2) : 0;

            // Capitalize the first letter of the sentiment label
            const label =
              context.dataset.label.charAt(0).toUpperCase() +
              context.dataset.label.slice(1);

            return `  ${label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
    hover: { mode: "nearest", intersect: true },
  };

  const getChartContainerStyle2 = () => {
    // Apply style if selectedYear is 0 and trendType is not 'yearly'
    if (selectedYear === 0 && trendType !== "yearly") {
      return { minWidth: "3500px", height: "500px" };
    } else {
      return { height: "500px" };
    }
  };

  const getChartContainerStyle = () => {
    // Apply style if selectedYear is 0 and trendType is not 'yearly'
    if (selectedYear === 0 && trendType !== "yearly") {
      return { overflowX: "auto", maxWidth: "100%" };
    } else {
      return {};
    }
  };

  const getUniqueYears = (data) => {
    const years = new Set(data.map((item) => item.year));
    return ["All", ...Array.from(years)]; // Replace 0 with 'All'
  };

  return (
    <>
      <h1
        style={{
          color: "#4e4e4e",
          fontSize: "24px",
          marginBottom: "20px",
          paddingLeft: "20px",
        }}
      >
        Sentiment Trend Over Time
      </h1>

      {isEmptyData || fetchError ? (
        <div>
          <div>
            "Data is not available for the selected parameters. To view the
            sentiment trend over time, please select a different date column."
          </div>
          <div style={{ height: "400px", maxWidth: "100%" }}>
            <Line data={data} options={options} />
          </div>{" "}
        </div>
      ) : (
        <div>
          <div
            className="top-controls"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div className="selectors" style={{ paddingLeft: "20px" }}>
              <div className="mb-3">
                <select
                  className="form-select custom-select"
                  value={trendType}
                  onChange={(e) => setTrendType(e.target.value)}
                >
                  <option value="weekly">Weekly Trend</option>
                  <option value="monthly">Monthly Trend</option>

                  <option value="yearly">Yearly Trend</option>
                </select>
              </div>

              {trendType === "monthly" && (
                <div className="mb-3">
                  <select
                    className="form-select custom-select"
                    value={selectedYear}
                    onChange={(e) =>
                      setSelectedYear(
                        e.target.value === "All" ? 0 : parseInt(e.target.value)
                      )
                    }
                  >
                    {getUniqueYears(sentimentData).map((year) => (
                      <option key={year} value={year}>
                        {year === "All" ? "All" : year}
                      </option> // Display 'All' for the 'All' option
                    ))}
                  </select>
                </div>
              )}
              {trendType === "weekly" && (
                <div className="mb-3">
                  <select
                    className="form-select custom-select"
                    value={selectedYear}
                    onChange={(e) =>
                      setSelectedYear(
                        e.target.value === "All" ? 0 : parseInt(e.target.value)
                      )
                    }
                  >
                    {getUniqueYears(sentimentData).map((year) => (
                      <option key={year} value={year}>
                        {year === "All" ? "All" : year}
                      </option> // Display 'All' for the 'All' option
                    ))}
                  </select>
                </div>
              )}
            </div>
            <CustomLegend />
          </div>
          <div style={getChartContainerStyle()}>
            {" "}
            {/* Adding horizontal scroll */}
            <div style={getChartContainerStyle2()}>
              {" "}
              {/* Adjust minWidth based on the expected width of the chart */}
              <Line data={data} options={options} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MonthlyTrendChart;
