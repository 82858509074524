// ItemList.js
import React from "react";
import "./ListItem.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FaRegTimesCircle } from "react-icons/fa";

const PlanCard = ({ items, icon, label }: any) => {
  return (
    <div className="item-list">
      {items.map(
        (
          item:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined,
          index: React.Key | null | undefined
        ) => (
          <div key={index} className="item px-4">
            <span className="item-name">
              {item}
              {item === "Free Reviews" && (
                <div className="new-tooltip-container">
                  <span className="fw-bold">
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      size="sm"
                      style={{
                        color: "#9e9e9e",
                        paddingTop: "5px",
                        paddingLeft: "5px",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                  <div className="tooltip-below">
                    <a id="plans-tooltip">
                      Free 300 reviews for first 100 registered users of the
                      month
                    </a>
                  </div>
                </div>
              )}
            </span>

            <span className="item-icon">
              {item === "Free Reviews" && label === "PIE" ? (
                <FaRegTimesCircle style={{ color: "grey" }} />
              ) : (
                icon
              )}
            </span>
          </div>
        )
      )}
    </div>
  );
};

export default PlanCard;
