import React, { useState, useEffect } from "react";
import "./FrequentPhrases.css";
const apiUrl = process.env.REACT_APP_API_URL;

const FrequentPhrases = ({ onMoreClick, onMoreClick2, selectedReportId }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/sentences/${selectedReportId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedReportId]);

  const truncateText = (text) => {
    const words = text.split(" ");
    if (words.length > 5) {
      return words.slice(0, 5).join(" ") + "...";
    }
    return text;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  const positivePhrases = data.filter(
    (item) => item.sentiment === "positive" && item.scores.positive > 0.8
  );

  const negativePhrases = data.filter(
    (item) => item.sentiment === "negative" && item.scores.negative > 0.8
  );

  return (
    <div className="phrases-container justify-content-center">
      <div className="phrases-section">
        <h3>Positive Phrases</h3>
        {positivePhrases.length > 0 ? (
          <>
            {positivePhrases.slice(0, 3).map((item, index) => (
              <div key={index} className="phrase">
                {truncateText(item.text)}
              </div>
            ))}
            <div className="button-more">
              <button onClick={onMoreClick} className="more-button">
                View All
              </button>
            </div>
          </>
        ) : (
          <div>No positive phrases available.</div>
        )}
      </div>

      <div className="phrases-section">
        <h3>Negative Phrases</h3>
        {negativePhrases.length > 0 ? (
          <>
            {negativePhrases.slice(0, 3).map((item, index) => (
              <div key={index} className="phrase">
                {truncateText(item.text)}
              </div>
            ))}
            <div className="button-more">
              <button onClick={onMoreClick2} className="more-button">
                View All
              </button>
            </div>
          </>
        ) : (
          <div>No negative phrases available.</div>
        )}
      </div>
    </div>
  );
};

export default FrequentPhrases;
