import React, { useEffect } from "react";

const Verify3DSPage = () => {
  const redirectUrl = localStorage.getItem('redirect_URL')
  useEffect(() => {
    window.location.href = redirectUrl;
  }, [redirectUrl]);

  return <div>...</div>;
};

export default Verify3DSPage;
