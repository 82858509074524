import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const StatusPage = () => {
    const { action } = useParams();
    const redirectUrl = localStorage.getItem('redirect_URL');
    useEffect(() => {
        // Extract 'liability_shift' from the URL
        const searchParams = new URLSearchParams(window.location.search);
        const liabilityShift = searchParams.get("liability_shift");
    
        // console.log("Action:", action);
        console.log("Liability Shift:", liabilityShift);
    
        if (redirectUrl) {
          if (liabilityShift === "NO") {
            if (window.opener) {
              window.opener.postMessage("actionCancelled", window.location.origin);
            }
          } else {
            if (window.opener) {
              window.opener.postMessage("actionComplete", window.location.origin);
            }
          }
        } else {
          console.error("No redirect URL found.");
        }
      }, [redirectUrl]);

    return <div>Redirecting...</div>;
};

export default StatusPage;
