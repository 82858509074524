import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./style.css";

import { Form, Input, Spin } from "antd";
import { Typography } from "antd";

const { Link } = Typography;

const Editlogindetails = ({ userId }) => {
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  const fetchUserData = async () => {
    setLoading(true); // Show loading indicator while fetching
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}user/getUserbyId`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }
      const data = await response.json();
      setUserData(data);
      form.setFieldsValue({
        email: data.email,
        firstName: data.firstname,
        secondName: data.lastname,
        backupEmail: data.backup_email,
        contactNumber: data.contact_no,
        companyName: data.company,
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserData();
    }
  }, [userId]); // Dependency array ensures this effect runs once on mount or when userId changes

  if (loading || !userData) {
    // Show centered loading indicator
    return (
      <div className="centered-loader">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="Main_div_register">
      <p className="register_heading editaccount-heading" style={{ fontSize: "30px" }}>
        Edit login Details
      </p>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          email: userData?.email,
          firstName: userData?.firstname,
          secondName: userData?.lastname,
          backupEmail: userData?.backup_email,
          contactNumber: userData?.contact_no,
          companyName: userData?.company,
        }}
      >
        <Form.Item
          name="email"
          label={
            <span className="label-font" style={{ marginTop: "-0.55rem" }}>
              Email<span className="required-asterisk">*</span>
            </span>
          }
          rules={[
            {
              type: "email",
              message: "The input is not valid email.",
            },
            {
              required: true,
              message: "Please provide your email.",
            },
          ]}
          className="form-item label-font"
          required={false}
        >
          <Input disabled style={{ color: "#999999", marginTop: "-0.5rem" }} />
        </Form.Item>
        <Form.Item
          name="password"
          label={
            <span className="label-font">
              Password<span className="required-asterisk">*</span>
            </span>
          }
          rules={[
            {
              required: true,
            },
          ]}
          className="form-item label-font"
          required={false}
        >
          <Input.Password
            disabled
            value="*****"
            placeholder="******************"
            iconRender={() => null} // This hides the view password icon
          />
        </Form.Item>
        <Form.Item>
          <Link
            onClick={() =>
              navigate("/forgotpassword", { state: { isChangePassword: true } })
            }
            className="change-password-link register-button-container"
          >
            <p className="button-update-password" style={{ color: "white" }}>
              Set New Password
            </p>
          </Link>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Editlogindetails;
