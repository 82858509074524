import { useRef, useState, useEffect } from "react";
import { Spin } from "antd";
import { Row, Col, Drawer } from "antd";
import { Button } from "../../common/Button";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faSignOutAlt,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import { useSidebar } from "../../context/SidebarContext";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  NavAndLoginContainer,
  LoginButtonContainer,
  UserMenuContainer,
  Label,
  Outline,
  Span,
} from "./styles";

import "./style.css";

type UserData = {
  username: string;
  firstName: string;
  lastName: string;
  expiresAt: number; // Include expiresAt in the type definition
};
interface UserMenuProps {
  userData: UserData | null;
  showUserInfo: boolean;
  toggleUserInfo: () => void;
  handleClick: () => void;
  handleSignOut: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  userInfoRef: React.RefObject<HTMLDivElement>;
}

const fetchUserDataFromBackend = async (): Promise<UserData | null> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_NODE_SERVER_URL}decode/UserdataToken`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch user data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to load user data", error);
    return null;
  }
};

const getUserDataFromLocalStorage = (): UserData | null => {
  const userDataStr = localStorage.getItem("userData");
  if (!userDataStr) return null;
  const userData: UserData = JSON.parse(userDataStr);
  const now = new Date().getTime();

  if (now > userData.expiresAt) {
    // Data has expired, clear it from local storage
    localStorage.removeItem("userData");
    return null;
  }

  return userData;
};

const Header = ({ setSelectedOption }: any) => {
  const { isSidebarOpen, toggleSidebar } = useSidebar();
  const location = useLocation();
  const isPieRoute = location.pathname === "/pie";
  const iseditaccountRoute = location.pathname === "/editaccount";
  const [visible, setVisibility] = useState(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [signOutLoading, setSignOutLoading] = useState(false);
  const [cookieExists, setCookieExists] = useState(false);

  const userInfoRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const isMobile = window.innerWidth < 990;
  const isSmallDevice = window.innerWidth < 1130;
  const shouldShowUserMenu =
    userData && (isMobile || (isSmallDevice && isSidebarOpen));
  const burgerTop = shouldShowUserMenu ? "46px" : "37px";

  // Calculate the shouldBeHidden condition
  const shouldBeHidden = isSmallDevice && isSidebarOpen;

  const toggleUserInfo = () => {
    setShowUserInfo(!showUserInfo);
  };

  useEffect(() => {
    const syncUserData = async () => {
      const localData = getUserDataFromLocalStorage();
      if (localData) {
        setUserData({
          username: localData.username,
          firstName: localData.firstName,
          lastName: localData.lastName,
          expiresAt: localData.expiresAt,
        });

        const backendData = await fetchUserDataFromBackend();
        if (
          backendData &&
          (backendData.firstName !== localData.firstName ||
            backendData.lastName !== localData.lastName)
        ) {
          // Update localData with new values from backendData
          const updatedLocalData = {
            ...localData,
            firstName: backendData.firstName,
            lastName: backendData.lastName,
          };
          localStorage.setItem("userData", JSON.stringify(updatedLocalData));
          setUserData(updatedLocalData);
        }
      } else {
        console.log("No data");
      }
    };

    syncUserData();

    // Listen for changes in localStorage and custom events
    const handleStorageChange = (event: StorageEvent | CustomEvent) => {
      if (event instanceof StorageEvent) {
        if (event.key === "userData") {
          syncUserData();
        }
      } else if (event.type === "localstorage-update") {
        syncUserData();
      }
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener(
      "localstorage-update",
      handleStorageChange as EventListener
    );

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener(
        "localstorage-update",
        handleStorageChange as EventListener
      );
    };
  }, []);

  useEffect(() => {
    const checkCookie = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}decode/check-cookie`,
          {
            method: "GET",
            credentials: "include",
          }
        );

        const data = await response.json();
        if (data.cookieExists) {
          setCookieExists(true);
        } else {
          localStorage.removeItem("userData");
          setCookieExists(false);
        }
      } catch (error) {
        console.error("Error checking cookie:", error);
      }
    };

    checkCookie();
  }, []);

  const handleSignOut = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    setSignOutLoading(true);

    try {
      // Use fetch to send a POST request to the backend
      await fetch(`${process.env.REACT_APP_NODE_SERVER_URL}user/signout`, {
        method: "POST",
        credentials: "include", // Ensure cookies are included with the request
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Remove user data from local storage
      localStorage.removeItem("userData");
      setCookieExists(false);
      

      // Broadcast the sign-out event to other tabs
      localStorage.setItem("signOutEvent", Date.now().toString());

      // Broadcast a custom event to trigger reload in all tabs
      window.dispatchEvent(new Event("signout-reload"));

      // Optional: Close the sidebar if it's open
      if (isSidebarOpen) {
        toggleSidebar();
      }

      setSignOutLoading(false);

      // Redirect the current tab to the home page or login page
      window.location.href = "/";
    } catch (error) {
      console.error("Error:", error);
      setSignOutLoading(false);
    }
  };

  useEffect(() => {
    // Event handler declared with proper typing for the event parameter
    const handleClickOutside = (event: MouseEvent) => {
      if (
        userInfoRef.current &&
        !userInfoRef.current.contains(event.target as Node)
      ) {
        setShowUserInfo(false); // Close the dialogue if click is outside
      }
    };

    // Adding event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Removing event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userInfoRef]); // userInfoRef is added as a dependency here

  const handleClick = () => {
    if (isSidebarOpen) {
      toggleSidebar();
    }
    window.location.href = "/pie";
  };

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const handleeditaccount = () => {
    if (isSidebarOpen) {
      toggleSidebar();
    }
    navigate("/editaccount");
  };

  const handledeactivateaccount = () => {
    if (isSidebarOpen) {
      toggleSidebar();
    }
    navigate("/deactivate");
  };

  const handleNavClick = (route: string) => {
    if (isSidebarOpen) {
      toggleSidebar();
    }
    navigate(route);
    setVisibility(false);
  };

  const UserMenu: React.FC<UserMenuProps> = ({
    userData,
    showUserInfo,
    toggleUserInfo,
    handleClick,
    handleSignOut,
    userInfoRef,
  }) => {
    // Component logic remains the same, but now it's type-safe
    return (
      <div>
        {" "}
        {/* This div will be the anchor for the dropdown */}
        {userData ? (
          <div
          style={{
            margin: "auto",
            position: "relative",
          }}>
            <CustomNavLinkSmall
              shouldBeHidden={shouldBeHidden}
              onClick={toggleUserInfo}
            >
              <div
                className="username"
                style={{
                  fontSize: "20px",
                  padding: "1px 2px 2px 2px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "35px",
                  height: "35px",
                  borderRadius: "50%",
                  backgroundColor: "#364545",
                  color: "#ffffff",

                  marginTop: "7px",
                }}
              >
                {userData.firstName[0].toUpperCase()}
                {userData.lastName[0].toUpperCase()}
              </div>
            </CustomNavLinkSmall>
            {showUserInfo && (
              <div
                ref={userInfoRef}
                style={{
                  position: "absolute",
                  top: "100%",
                  right: "30%",
                  backgroundColor: "white",
                  color: "#fff",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)",
                  padding: "15px",
                  borderRadius: "8px",
                  zIndex: 100, // Ensure it's on top of other elements
                  minWidth: "250px",
                  maxWidth: "250px",
                }}
                onClick={toggleUserInfo}
              >
                {/* User Info Content */}
                <div
                  style={{
                    fontSize: "16px",
                    color: "#364545",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "230px",
                  }}
                >
                  {userData.firstName} {userData.lastName}
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#364545",
                    marginBottom: "5px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "230px",
                  }}
                  title={userData.username} // Tooltip on hover
                >
                  {userData.username}
                  <hr
                    style={{
                      backgroundColor: "#364545",
                      height: "1px",
                      margin: "10px 0",
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <a
                    className="options_dropdown_edit"
                    onClick={handleeditaccount}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "gray",
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />

                      <div style={{ paddingLeft: "8px" }}>Edit account</div>
                    </div>
                  </a>

                  <a
                    className="options_dropdown_edit"
                    style={{ marginLeft: "-0.25rem" }}
                    onClick={handledeactivateaccount}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "gray",
                      }}
                    >
                      <FontAwesomeIcon icon={faUserSlash} size="1x" />

                      <div style={{ paddingLeft: "8px" }}>
                        Deactivate account
                      </div>
                    </div>
                  </a>

                  <a
                    className="options_dropdown_signout"
                    onClick={handleSignOut}
                    style={{ position: "relative" }}
                  >
                    {signOutLoading && (
                      <div
                        className="moving-progress-line"
                        style={{
                          position: "absolute",
                          bottom: "-5px",
                          left: "0",
                          right: "0",
                          minWidth: "220px",
                          height: "4px",
                        }}
                      />
                    )}

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "gray",
                      }}
                    >
                      <FontAwesomeIcon icon={faSignOutAlt} />
                      <div style={{ paddingLeft: "8px" }}>Sign out</div>
                    </div>
                  </a>
                </div>
              </div>
            )}
          </div>
        ) : (
          isMobile ?
            <CustomNavLinkSmall
              onClick={() => handleClick()}
              shouldBeHidden={shouldBeHidden}
            >
              <Span style={{color: "teal"}}>{"Login"}</Span>
            </CustomNavLinkSmall>
          :
          <LoginButtonContainer>
            <CustomNavLinkSmall
              style={{ width: "120px" }}
              onClick={() => handleClick()}
              shouldBeHidden={shouldBeHidden}
            >
              <Span className="buttonlogin">
                <Button>Login</Button>
              </Span>
            </CustomNavLinkSmall>
          </LoginButtonContainer>
        )}
      </div>
    );
  };

  const DropdownMenuHelp = () => (
    <ul className="dropdown-content_help">
      <li>
        <Link to="/help" state={{ selectedOption: "Report a bug" }}>
          Report a bug
        </Link>
      </li>
      <li>
        <Link to="/help" state={{ selectedOption: "Suggest a feature" }}>
          Suggest a feature
        </Link>
      </li>
      <li>
        <Link to="/help" state={{ selectedOption: "Contact support" }}>
          Contact support
        </Link>
      </li>
    </ul>
  );

  const MenuItemwithoutLogin = () => {
    return (
      <>
        <NavAndLoginContainer>
          <div style={{ marginTop: "8px" }}>
            {!userData ? (
              <>
                <CustomNavLinkSmall
                  shouldBeHidden={shouldBeHidden}
                  onClick={() => handleNavClick("/aboutai")}
                >
                  <Span>{"AI & PIE"}</Span>
                </CustomNavLinkSmall>

                <CustomNavLinkSmall
                  shouldBeHidden={shouldBeHidden}
                  onClick={() => handleNavClick("/plans")}
                >
                  <Span>{"Plans"}</Span>
                </CustomNavLinkSmall>

                <CustomNavLinkSmall
                  shouldBeHidden={shouldBeHidden}
                  onClick={() => handleNavClick("/ourpromise")}
                >
                  <Span>{"Our Promise"}</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall
                  shouldBeHidden={shouldBeHidden}
                  className="contactus_div"
                  onClick={() => handleNavClick("/help")}
                >
                  <Span>{"Contact Us"}</Span>
                  <DropdownMenuHelp />
                </CustomNavLinkSmall>
              </>
               ):(
              <>
                <CustomNavLinkSmall
                  shouldBeHidden={shouldBeHidden}
                  onClick={() => handleNavClick("/report")}
                >
                  <Span>{"Reports"}</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall
                  shouldBeHidden={shouldBeHidden}
                  onClick={() => handleNavClick("/features")}
                >
                  <Span>{"Features"}</Span>
                </CustomNavLinkSmall>
                {isMobile ? (
                  <CustomNavLinkSmall
                    shouldBeHidden={shouldBeHidden}
                    className="contactus_div"
                    onClick={() => handleNavClick("/help")}
                  >
                    <Span>{"Help"}</Span>
                  </CustomNavLinkSmall>
                ) : (
                  <CustomNavLinkSmall
                    shouldBeHidden={shouldBeHidden}
                    className="dropdown_help"
                  >
                    <Span>{"Help"}</Span>
                    <DropdownMenuHelp />
                  </CustomNavLinkSmall>
                )}
              </>
            )}
          </div>
        </NavAndLoginContainer>
      </>
    );
  };

  // Your FullPageLoader component
  const FullPageLoader = () => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255, 1)",
        zIndex: 1000,
      }}
    >
      <div
        className="centered-loader_report_tab"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spin size="large" />
      </div>
    </div>
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleLogoClick = async () => {
    if (isSidebarOpen) {
      toggleSidebar();
    }

    setIsLoading(true);
    let redirectToPie = true;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}decode/decodetoken`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user ID");
      }

      const data = await response.json();
      if (!data || data.userId == null) {
        redirectToPie = false;
      }
    } catch (error) {
      console.error("Failed to load user ID", error);
    }

    setTimeout(() => {
      setIsLoading(false); // Stop loading before navigating

      if (redirectToPie) {
        navigate("/pie"); // Use navigate to go to "/pie"
      } else {
        navigate("/"); // Use navigate to stay on "/"
      }
    }, 1000); // Simulate a network request
  };
  const MenuItem = () => {
    return (
      <>
        <NavAndLoginContainer>
          <div style={{ marginTop: "8px" }}>
            {!userData ? (
              <>
                <CustomNavLinkSmall
                  shouldBeHidden={shouldBeHidden}
                  onClick={() => handleNavClick("/aboutai")}
                >
                  <Span>{"AI & PIE"}</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall
                  shouldBeHidden={shouldBeHidden}
                  onClick={() => handleNavClick("/plans")}
                >
                  <Span>{"Plans"}</Span>
                </CustomNavLinkSmall>

                <CustomNavLinkSmall
                  shouldBeHidden={shouldBeHidden}
                  onClick={() => handleNavClick("/ourpromise")}
                >
                  <Span>{"Our Promise"}</Span>
                </CustomNavLinkSmall>
              </>
            ):
              <>
                <CustomNavLinkSmall
                  shouldBeHidden={shouldBeHidden}
                  onClick={() => handleNavClick("/report")}
                >
                  <Span>{"Reports"}</Span>
                </CustomNavLinkSmall>
              </>
            }
            <CustomNavLinkSmall
              shouldBeHidden={shouldBeHidden}
              onClick={() => handleNavClick("/features")}
            >
              <Span>{"Features"}</Span>
            </CustomNavLinkSmall>
            {userData && (
              <>
                <CustomNavLinkSmall
                  shouldBeHidden={shouldBeHidden}
                  className="dropdown_help"
                >
                  <Span>{"Help"}</Span>
                  <DropdownMenuHelp />
                </CustomNavLinkSmall>
              </>
            )}
            {!userData && (
              <>
                <CustomNavLinkSmall
                  shouldBeHidden={shouldBeHidden}
                  className="contactus_div"
                  onClick={() => handleNavClick("/help")}
                >
                  <Span>{"Contact Us"}</Span>
                  <DropdownMenuHelp />
                </CustomNavLinkSmall>
              </>
            )}
          </div>
          <UserMenu
            userData={userData}
            showUserInfo={showUserInfo}
            toggleUserInfo={toggleUserInfo}
            handleClick={handleClick}
            handleSignOut={handleSignOut}
            userInfoRef={userInfoRef}
          />
        </NavAndLoginContainer>
      </>
    );
  };

  return (
    <>
      {isLoading && <FullPageLoader />}
      <HeaderSection
        isPieRoute={isPieRoute}
        isEditRoute={iseditaccountRoute}
        className={`header ${isSidebarOpen ? "shifted" : ""}`}
      >
        <Container>
          <Row justify="space-between">
            <LogoContainer
              isPieRoute={isPieRoute}
              isEditRoute={iseditaccountRoute}
              to="/"
              onClick={handleLogoClick}
              aria-label="homepage"
            >
              <div className="logo-container">
                <SvgIcon src="pie5.svg" width="80px" height="50px" />
              </div>
            </LogoContainer>

            <NotHidden shouldBeHidden={shouldBeHidden}>
              <MenuItem />
            </NotHidden>

            {userData && (isMobile || (isSidebarOpen && isSmallDevice)) && (
              <UserMenuContainer>
                <UserMenu
                  userData={userData}
                  showUserInfo={showUserInfo}
                  toggleUserInfo={toggleUserInfo}
                  handleClick={handleClick}
                  handleSignOut={handleSignOut}
                  userInfoRef={userInfoRef}
                />
              </UserMenuContainer>
            )}

            <Burger
              onClick={showDrawer}
              top={burgerTop}
              showForSmallDeviceAndSidebarOpen={shouldBeHidden}
            >
              <Outline />
            </Burger>
          </Row>

          <Drawer
            closable={false}
            open={visible}
            onClose={toggleButton}
            className="Drawer_burger_click"
          >
            {(isMobile || (isSmallDevice && isSidebarOpen)) && (
              <>
                {userData ? (
                  <div>
                    <Col style={{ marginBottom: "2.5rem" }}>
                      <Label onClick={toggleButton}>
                        <Col span={12}>
                          <Menu>Menu</Menu>
                        </Col>
                        <Col span={30}>
                          <Outline />
                        </Col>
                      </Label>
                    </Col>
                    <MenuItemwithoutLogin />
                  </div>
                ) : (
                  <div>
                    <Col style={{ marginBottom: "2.5rem" }}>
                      <Label onClick={toggleButton}>
                        <Col span={12}>
                          <Menu>Menu</Menu>
                        </Col>
                        <Col span={30}>
                          <Outline />
                        </Col>
                      </Label>
                    </Col>
                    <MenuItem />
                  </div>
                )}
              </>
            )}
          </Drawer>
        </Container>
      </HeaderSection>
    </>
  );
};

export default Header;
