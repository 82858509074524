import { lazy } from "react";

import IntroContent from "../../content/IntroContent.json";
import MiddleBlockContent from "../../content/MiddleBlockContent.json";
import AboutContent from "../../content/AboutContent.json";
import MissionContent from "../../content/MissionContent.json";
import ProductContent from "../../content/ProductContent.json";
import ContactContent from "../../content/ContactContent.json";
import RefrencesContent from "../../content/RefrencesContent.json";

const RegisterUser = lazy(() => import("../../components/RegisterBlock"));
const Contact = lazy(() => import("../../components/ContactForm"));
const MiddleBlock = lazy(() => import("../../components/MiddleBlock"));
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const ContentBlock = lazy(() => import("../../components/ContentBlock"));
const RefrenceContentBlock = lazy(
  () => import("../../components/RefrencesBlock")
);

const Home = () => {
  const isMobile = window.innerWidth < 575; // Example breakpoint for mobile view
  return (
    <Container>
      <ScrollToTop />
      <span data-testid="content-block" className="content-block-margin">
        <ContentBlock
          type="right"
          title={IntroContent.title}
          content={IntroContent.text}
          button={IntroContent.button}
          icon="landingpg-image.png"
          id="intro"
        />
      </span>

      <MiddleBlock
        title={MiddleBlockContent.title}
        content={MiddleBlockContent.text}
        button={MiddleBlockContent.button}
      />
      <ContentBlock
        type="left"
        title={AboutContent.title}
        content={AboutContent.text}
        section={AboutContent.section}
        icon="section2full.png"
        id="about"
      />
      <ContentBlock
        type="right"
        title={MissionContent.title}
        content={MissionContent.text}
        icon="magnifying-glass-data-analysis.png"
        id="mission"
      />
      {isMobile
       ? (
        <ContentBlock
        type="right"
        title={ProductContent.title}
        content={ProductContent.text}
        icon="section5mainbg.png"
        id="product"
      />
      ):(
<ContentBlock
        type="left"
        title={ProductContent.title}
        content={ProductContent.text}
        icon="section5mainbg.png"
        id="product"
      />
      )}
      
   
      <RefrenceContentBlock
        type="right"
        title={RefrencesContent.title}
        content=""
        section={RefrencesContent.section}
        icon=""
        id="pro"
      />
    </Container>
  );
};

export default Home;
