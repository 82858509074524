import React, { useState, useEffect, useRef } from "react";
import WordCloudComp from "./DashComp/WordCloudComp";
import MonthlyTrendChart from "./DashComp/MonthlyTrendChart";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Dashboard.css";

import ScoreWheel from "./DashComp/ScoreWheel";
import FrequentPhrases from "./DashComp/FrequentPhrases";
import Options_intellisence from "./DashComp/Intellisence/Intellisence_Options";
import ScoreDisplay from "./DashComp/ScoreDisplay";
import KeyOpinionTopics from "./DashComp/KeyOpinionTopics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { calculateSentimentScore } from "../../services/arraycontainsrequiredvalues";
import DetailedFreqPhrases from "./DashComp/DetailedFreqPhrases";
import DetailedKeyOpinionTopics from "./DashComp/DetailedKeyOpinionTopics";
const apiUrl = process.env.REACT_APP_API_URL;

function Dashboard({ selecteduserid, selectedReportId }) {
  const [data, setData] = useState(null);
  const [overallsentiment, setoverallsentiment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clusteredResults, setClusteredResults] = useState([]);
  const [sentimentScore, setSentimentScore] = useState(0);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const isMobile = window.innerWidth < 605;
  const scrollTo = (id) => {
    console.log("id::", id);
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  const [tabIndex, setTabIndex] = useState(0); // Control active tab index here

  const handleMoreClick = () => {
    setTabIndex(0); // Set active tab to the second tab
    scrollTo("DetPhrases"); // Scroll to the component
  };

  const handleMoreClick2 = () => {
    setTabIndex(1); // Set active tab to the second tab
    scrollTo("DetPhrases"); // Scroll to the component
  };

  const handleMoreClick3 = () => {
    setTabIndex(1); // Set active tab to the second tab
    scrollTo("DetKOT"); // Scroll to the component
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/wordcloud_clusters/${selectedReportId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const rawData = await response.json();
        setClusteredResults(rawData);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchData();
  }, [selectedReportId]);

  useEffect(() => {
    fetch(`${apiUrl}/fromToDates/${selectedReportId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.from_date && data.to_date) {
          setDateRange({
            startDate: new Date(data.from_date),
            endDate: new Date(data.to_date),
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching date range:", error);
      });
  }, [selectedReportId]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/sentences/${selectedReportId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    const fecth_overall_sentiment = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/overall_sentiment/${selectedReportId}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const jsonData = await response.json();
        setoverallsentiment(jsonData);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    fecth_overall_sentiment();
  }, [selectedReportId]);
  useEffect(() => {
    const handleSentimentScore = async () => {
      if (overallsentiment && data) {
        const sentimentScore = calculateSentimentScore(overallsentiment, data);
        setSentimentScore(sentimentScore);
      }
    };

    handleSentimentScore();
  }, [selectedReportId, overallsentiment, data]);

  return (
    <div
      className="dash-container container-fluid align-items-center"
      id="dashboard"
    >
      <div className="upper-cont row d-flex justify-content-center">
        <div className="report-container col-lg-4 col-md-6">
          <div className="score-container col-lg col-md-12 d-flex flex-column align-items-center">
            {data != null && overallsentiment != null && (
              <div>
                <div className="scorehead col-12 text-center">
                  <h2 className="fontstyle">Sentiment Score</h2>
                </div>
                <div
                  className="score-wheel col-12"
                  style={{
                    display: "inline-block",
                    height: "250px",
                    width: "100%",
                  }}
                >
                  <ScoreWheel score={sentimentScore} outOf={100} />
                </div>

                <div className="text-details col-12 text-center">
                  <p>Based on {overallsentiment.length} reviews</p>
                  {dateRange.startDate && dateRange.endDate && (
                    <p>
                      from {dateRange.startDate.toLocaleDateString()} to{" "}
                      {dateRange.endDate.toLocaleDateString()}
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className="row d-flex col-lg-8 col-md-6 chart-container"
          style={{ width: "" }}
        >
          <div className=" d-flex flex-column justify-content-around col-lg-6 col-md-6">
            <div>
              <ScoreDisplay tableData={overallsentiment} />
            </div>
            <div>
              {" "}
              <KeyOpinionTopics
                onMore={handleMoreClick3}
                selectedReportId={selectedReportId}
              />{" "}
            </div>
          </div>
          <div className="  col-lg-6 col-md-6">
            <FrequentPhrases
              onMoreClick={handleMoreClick}
              onMoreClick2={handleMoreClick2}
              selectedReportId={selectedReportId}
            />
          </div>
        </div>
      </div>

      <div className="gen-container row d-flex">
        {isMobile ? (
          <>
            <div className="headings_class">
              <h3
                className="fs-4"
                style={{ color: "#4e4e4e", textAlign: "left" }}
              >
                Intelligent Search
              </h3>
            </div>
            <p className="text-dark fs-6">
              [ Please switch to a larger screen to use this feature. ]
            </p>
          </>
        ) : (
          <Options_intellisence
            datapassed={data}
            selectedReportId={selectedReportId}
            passeduserid={selecteduserid}
          />
        )}
      </div>
      <div id="DetKOT" className="row d-flex">
        <DetailedKeyOpinionTopics selectedReportId={selectedReportId} />
      </div>

      <div className="stylish-container row d-flex mt-4">
        <div className="col-lg-6 col-md-12  month-chart-container">
          {isMobile ? (
            <>
              <div className="headings_class">
                <h3 className="fs-4" style={{ color: "#4e4e4e" }}>
                  Sentiment Trend Over Time
                </h3>
              </div>
              <p className="text-dark fs-6">
                [ Please switch to a larger screen to use this feature. ]
              </p>
            </>
          ) : (
            <MonthlyTrendChart selectedReportId={selectedReportId} />
          )}
        </div>
      </div>

      <div id="DetPhrases" className="gen-container row d-flex">
        <DetailedFreqPhrases
          data={data}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          selectedReportId={selectedReportId}
        />
      </div>

      <div
        className="word-cloud-container gen-container row d-flex"
        id="wordcloud"
      >
        <div className="wordcloud_info">
          <div className="wordcloud_heading">
            <h1 style={{ color: "#4e4e4e", fontSize: "28px" }}>Word Cloud</h1>
            <div className="new-tooltip-container">
              <span className="info-icon">
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  size="s"
                  style={{
                    color: "#9e9e9e",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                />
              </span>
              <div className="tooltip-below">
                <a id="content_word_cloud">
                  Discover prevalent words from submitted reviews in word cloud.
                  Larger words are repeated more frequently, offering a quick
                  glimpse into commonly expressed thoughts and feelings.
                </a>
              </div>
            </div>
          </div>
        </div>

        {clusteredResults.clustered_results ? (
          <WordCloudComp
            clusteredResults={clusteredResults}
            selectedReportId={selectedReportId}
          />
        ) : (
          <div>
            Data unavailable: Please ensure you provide enough reviews and
            select the correct column for analysis.
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
