import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Import JavaScript
import "bootstrap/dist/css/bootstrap.min.css";
import "./Search.css";
import Papa from "papaparse";
import Dashboard from "./Dashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronRight,
  faEllipsisV,
  faCircleChevronLeft,
  faShareFromSquare,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import CryptoJS from "crypto-js";
import LoadingIndicator from "./LoadingDataIndicator";
import CallDropdown from "./CallDropdown";
import { useSidebar } from "../../context/SidebarContext";
import FilePreviewToggle from "./PreviewFile/ToggleButton";
import {
  arraycontainskeys,
  xlsxToCsv,
  findMinMaxDates,
} from "../../services/arraycontainsrequiredvalues";

import ShareableLinkModal from "./ShareableLinkModal";
import fileicon from "./output.png"; // Tell webpack this JS file uses this image

import ReportsContainer from "./Histogram/FetchReports";
import { Tooltip } from "antd";
import ReviewStatus from "./ReviewsInfo/ReviewsInfo";
import { toast } from "react-toastify";

// const apiUrlL = process.env.REACT_APP_API_LOCAL;
const apiUrl = process.env.REACT_APP_API_URL;

const Search = (propspassed) => {
  const userId = propspassed.pie_user_id;
  const isMobileScreen = window.innerWidth < 920; // Example breakpoint for mobile view
  const columnToGet = ["URI", "Reviews", "Date"];
  const Required_columns = ["Reviews"];
  const azuredataformat = ["id", "text", "date"];
  const Max_review_length = 5120;
  const Descriptionofcolumn = [
    "URI stands for 'Unique Review Identifier'. Select the column that contains unique number for each sentence.",
    "Choose the column that contains the reviews you wish to process.",
    "Please select the column containing the review posting dates from the uploaded file.",
  ];
  const [showfileformaterror, setshowfileformaterror] = useState(false);
  const [showfilesizeerror, setshowfilesizeerror] = useState(false);
  const [showDropdown, setShowDropdown] = useState(true);

  const [showcolumnsnotfounderror, setshowcolumnsnotfounderror] =
    useState(false);
  const [showselectedfileempty, setshowselectedfileempty] = useState(false);
  const [showloadingindicator, setshowloadingindicator] = useState(false);
  const [showsentencetrimmed, setshowsentencetrimmed] = useState(false);
  const [arecolumnsselected, setarecolumnselected] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [newCsvFileName, setNewCsvFileName] = useState(null);
  const [maxReviewsAllowed, setmaxReviewsAllowed] = useState(null);
  const [minReviewsAllowed, setMinReviewsAllowed] = useState(null);
  const [userPackageData, setuserPackageData] = useState(null);
  const [headerdata, setHeaderdata] = useState([]);

  const [CSVdata, setCSVData] = useState([]);
  let [UserselectedID, setUserselectedID] = useState([]); // Initialize an array to store selected IDs
  let headersExist = true;
  const [similarReportFound, setSimilarReportFound] = useState(false);
  const [reportNameError, setReportNameError] = useState("");
  const [DateColumn, setDateColumn] = useState(0);
  const [Dates, setDates] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });

  const [showPopup, setShowPopup] = React.useState(true);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [editingReportId, setEditingReportId] = useState(null);
  const [editingReportName, setEditingReportName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");

  const [Databaseinvoicerecord, setDatabaseinvoicerecord] = useState(null);
  const [reviewsDet, setReviewsDet] = useState([]);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showhistogram, setshowhistogram] = useState(true);
  const myDivRef = useRef(null);
  const reportRef = useRef(null);
  const { isSidebarOpen, toggleSidebar } = useSidebar() || {};
  const [isChecked, setIsChecked] = useState(true);
  const [processing, setProcessing] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(
    "Analyzing uploaded file data to generate report"
  );
  const [reportToDelete, setReportToDelete] = useState(null);
  const [reportNameToDelete, setReportNameToDelete] = useState(null);
  const [reportreviewsToDelete, setReportreviewsToDelete] = useState(null);
  const DeleteReportmodalMessage =
    "Are you sure you want to delete this report? Once deleted, it cannot be recovered. Please confirm your action.";
  const [DeleteReportmodalOpen, setDeleteReportModalOpen] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setLoadingMessage("Analyzing uploaded file data to generate report");
    setProcessing(true);
    const timer = setTimeout(() => {
      setLoadingMessage(
        "This is taking longer than expected. Your report will continue to process in the background, and you will receive an email once it has been generated."
      );
      setProcessing(false);
    }, 10000); // Sets a new message after 60 seconds

    return () => clearTimeout(timer);
  }, [showloadingindicator]);

  useEffect(() => {
    const fetchVariableValue = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_NODE_SERVER_URL}variable/getvariablevalue`,
          {
            names: ["max_reviews_per_file", "min_reviews_per_file"],
          }
        );

        // Accessing both values from the response
        const { max_reviews_per_file, min_reviews_per_file } = response.data;
        // Setting state with the values fetched
        setmaxReviewsAllowed(Number(max_reviews_per_file));
        setMinReviewsAllowed(Number(min_reviews_per_file));
      } catch (error) {
        console.error("Error fetching variable value:", error);
      }
    };

    fetchVariableValue();
  }, []); // Empty dependency array means this useEffect runs once when the component mounts

  // Function to fetch user subscription data
  const fetchUserData = async () => {
    try {
      // Replace with your actual fetch logic
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}package/usersubscribedpackage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: propspassed.pie_user_id }), // Ensure userId is passed correctly
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch package details");
      }

      const data = await response.json();
      setuserPackageData(data.data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
    }
  };

  useEffect(() => {
    if (propspassed.pie_user_id) {
      fetchUserData();
    }
  }, [propspassed.pie_user_id]); // Dependency array includes userId to refetch when it changes

  const sidebarStyle = {
    left: isSidebarOpen ? "0" : "-250px", // Assuming you want to hide the sidebar by moving it to the left
  };

  // Define the arrow's style dynamically
  const arrowStyle = {
    left: isSidebarOpen ? "250px" : "0", // Move the arrow based on the sidebar's state
  };
  const scrollToDiv = () => {
    myDivRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToReportDiv = () => {
    reportRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Function to toggle the state
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);

    setshowhistogram(!showhistogram);
  };

  // Function to toggle the state
  const handlesidebararrow = () => {
    setReportNameError(null);
    setEditingReportId(null);
    if (toggleSidebar) {
      toggleSidebar();
    }
  };

  // Use useEffect to perform the scroll action after showhistogram changes
  useEffect(() => {
    if (showhistogram) {
      // Use setTimeout to ensure the new content has been rendered
      setTimeout(() => {
        scrollToDiv();
      }, 0); // You might need to adjust this delay
    }
  }, [showhistogram]); // This effect depends on showhistogram

  const [dropdownOpenSidebar, setDropdownOpenSidebar] = useState(false);
  const [currentDropdownSidebar, setCurrentDropdownSidebar] = useState(null);

  const toggleDropdownSidebar = (reportId) => {
    setDropdownOpenSidebar(
      (prevState) => !prevState || currentDropdownSidebar !== reportId
    );
    setCurrentDropdownSidebar(reportId);
  };
  const encryptReportId = async (reportId) => {
    try {
      const secretName = process.env.REACT_APP_Crypto_Secret_Key;
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}variable/getkeyvaultvalue`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ secretName }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      const encrypted = CryptoJS.AES.encrypt(
        reportId.toString(),
        data.secretValue
      ).toString();
      // URL encode the encrypted string to make it URL-safe
      return encodeURIComponent(encrypted);
    } catch (error) {
      console.error("Error initializing PayPal SDK:", error);
    }
  };

  const createShareableLink = async (reportId) => {
    const encryptedId = await encryptReportId(reportId);
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const shareableLink = `${baseUrl}/reports/${encryptedId}`;

    setGeneratedLink(shareableLink);
    setIsModalVisible(true); // Show the modal
    // Copying the link to the clipboard
    navigator.clipboard.writeText(shareableLink).then(
      () => {
        console.log("Link copied to clipboard");
        // You can also set state here to show a message to the user that the link has been copied
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  useEffect(() => {
    if (selectedReportId) {
      localStorage.setItem("selectedReportId", selectedReportId);
    }
  }, [selectedReportId]);
  const handleRenameReport = async (reportId) => {
    if (!editingReportName || editingReportName.trim().length === 0) {
      // If the new report name is empty or contains only spaces, show an error message
      setReportNameError("Report name cannot be empty.");
      return; // Don't proceed with renaming
    } else {
      setReportNameError("");
    }
    // Check if the new report name already exists in reviewsDet
    const isDuplicateName = reviewsDet.some(
      (review) =>
        review.report_id !== reportId && review.reportname === editingReportName
    );

    if (isDuplicateName) {
      setReportNameError("Report name already exists");
      return;
    }
    try {
      const response = await axios.put(`${apiUrl}/update_report/${reportId}`, {
        new_report_name: editingReportName,
      });
      if (response.status === 200) {
        // Update the list of reports with the new name
        const updatedReviews = reviewsDet.map((review) => {
          if (review.report_id === reportId) {
            return { ...review, reportname: editingReportName };
          }
          return review;
        });
        setReviewsDet(updatedReviews);
        // alert('Report renamed successfully');
      }
    } catch (error) {
      console.error("Failed to rename the report", error);
    }
    // Exit edit mode
    setEditingReportId(null);
    setEditingReportName("");
  };

  const fetchReviews = async () => {
    if (!userId) {
      console.error("No user ID found in local storage or session storage.");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/get_reviews`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customer_id: userId }),
      });

      if (response.ok) {
        const reviews = await response.json();
        setReviewsDet(reviews);
      } else {
        // Handle non-200 responses
        console.error("Failed to fetch reviews:", response.status);
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  useEffect(() => {
    const fetchReviews = async () => {
      if (!userId) {
        console.error("No user ID found in local storage or session storage.");
        return;
      }

      try {
        const response = await fetch(`${apiUrl}/get_reviews`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ customer_id: userId }),
        });

        if (response.ok) {
          const reviews = await response.json();
          setReviewsDet(reviews);
        } else {
          // Handle non-200 responses
          console.error("Failed to fetch reviews:", response.status);
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };
    fetchReviews();
  }, [selectedReportId, userId, isSidebarOpen]);

  const handleDeleteReport = async (reportId) => {
    try {
      const response = await axios.delete(
        `${apiUrl}/delete_report/${reportId}`
      );
      if (response.status === 200) {
        fetchReviews();
      }
    } catch (error) {
      console.error("Failed to delete the report", error);
    }
  };

  const confirmDeleteReport = (reportId) => {
    setReportToDelete(reportId);

    toggleSidebar();

    setDeleteReportModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (reportToDelete) {
      handleDeleteReport(reportToDelete);
      if (reportToDelete == selectedReportId) {
        setSelectedReportId(null);
      }
      setDeleteReportModalOpen(false);
      setReportNameToDelete(null);
      setReportreviewsToDelete(null);
      setReportToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setDeleteReportModalOpen(false);

    setReportNameToDelete(null);
    setReportreviewsToDelete(null);
    setReportToDelete(null);
  };

  const selectcoloumnstoprocess = async (e) => {
    e.preventDefault();
    setshowhistogram(false);
    setIsChecked(false);
    setHeaderdata([]);
    setShowDropdown(true);
    setarecolumnselected(false);
    setShowPopup(true);
    setshowfileformaterror(false);
    setshowcolumnsnotfounderror(false);
    setshowselectedfileempty(false);
    setshowfilesizeerror(false);
    propspassed.setanalysisnotperformed(false);
    setDateColumn(0);
    setDates([]);
    setDateRange({
      startDate: "",
      endDate: "",
    });

    if (
      selectedFile.type === "text/csv" ||
      selectedFile.type === "application/vnd.ms-excel" ||
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
    } else {
      setshowfileformaterror(true);
      return 0;
    }

    if (selectedFile.size / (1024 * 1024) > 5) {
      setarecolumnselected(true);
      setshowfilesizeerror(true);
      return 0;
    }
    const isDuplicateFileName = reviewsDet.some(
      (review) => review.reviews_filename === selectedFile.name
    );
    if (isDuplicateFileName) {
      setSimilarReportFound(true);
    }
   
    let file = selectedFile;
    // console.log('File selected >', file)
    setSelectedFile(file);

    const readdata = () => {
      return new Promise((resolve, reject) => {
        if (file) {
          // Read the CSV file content
          const reader = new FileReader();

          reader.onload = async (event) => {
            let data = null;
            if (
              file.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
              // console.log("File is a valid type")
              try {
                const csvData = await xlsxToCsv(file);

                data = csvData;
                // console.log('CSV Data: ', csvData)
              } catch (error) {
                console.error("Error converting file:", error);
              }
            } else {
              // console.log('File is not xlsx type')
              const content = event.target.result;
              console.log('content: ', content)
              const datatoprocess = Papa.parse(content, {
                skipEmptyLines: true, // Skip empty lines
              });
              console.log('Papa parse (datatoprocess)', datatoprocess)
              data = datatoprocess.data;
            }
            if (data.length < minReviewsAllowed) {
              setshowselectedfileempty(true);
              setSimilarReportFound(false);
              return;
            }

            try {
              if (data[0].length > 0) {
                for (
                  let columnindex = 0;
                  columnindex < data[0].length;
                  columnindex++
                ) {
                  if (!isNaN(parseInt(data[0][columnindex]))) {
                    headersExist = false;
                    break;
                  }
                }
                // **New Check**: If headers exist but there's no data after the header row
                if (headersExist && data.length === 1) {
                  // Only one row exists (the header), no data present
                  setSimilarReportFound(false);
                  setshowselectedfileempty(true); // Use same error for no data
                  return;
                }
              }
            } catch (error) {
              setshowcolumnsnotfounderror(true);
              return 0;
            }
            // console.log('Proceeding when header exists')

            // Extract headers if they exist, otherwise resolve with raw data
            if (headersExist) {
              const headers = data[0]; // Remove the first row and assign it to headers
              resolve({ headers, data });
            } else {
              resolve({ data });
            }
          };

          reader.onerror = (event) => {
            reject(event.target.error);
          };

          reader.readAsText(file);
        } else {
          reject("No file selected.");
        }
      });
    };

    const handleSubmission = async () => {
      try {
        // console.log('Made it to handlesubmission..')
        readdata()
          .then(({ headers, data }) => {
            if (headers) {
              // Filter out records where all fields are empty
              const filteredData = data
                .slice(1)
                .filter((record) =>
                  record.some((field) => field.trim() !== "")
                );

              setCSVData(filteredData);
              setHeaderdata(headers);
              // console.log('filteredData, headers in handleSubmit: ', filteredData , "\t", headers)

            } else {
              // console.log('No headers!')
              const dummyHeader = [];
              for (
                let dummyindex = 1;
                dummyindex <= data[0].length;
                dummyindex++
              ) {
                dummyHeader.push(`column-${dummyindex}`);
              }

              // Filter out records where all fields are empty
              const filteredData = data.filter((record) =>
                record.some((field) => field.trim() !== "")
              );

              setHeaderdata(dummyHeader);
              setCSVData(filteredData);
            }
          })
          .catch(() => {
            setIsChecked(true);
            setShowDropdown(false);
            setSimilarReportFound(false);
            setShowPopup(false);
            setshowselectedfileempty(false);
            setshowcolumnsnotfounderror(false);
            setshowhistogram(true); // Or hide it based on your logic
            setSelectedFile(null); // Clear the selected file
            setSelectedReportId(null);
            fileInputRef.current.value = null;
          });
      } catch (error) {
        console.error("Error reading or parsing the file:", error);
      }
    };

    handleSubmission();
  };

  const performsentimentanalysis = async (selectedcolumntoprocess) => {
    setPopupVisible(false);
    setUserselectedID([]);
    setshowsentencetrimmed(false);

    if (
      (await arraycontainskeys(
        selectedcolumntoprocess,
        Required_columns.length
      )) === false
    ) {
      setshowcolumnsnotfounderror(true);
      setarecolumnselected(false);
      return;
    }
    setshowloadingindicator(true);
    const coloumns_selected = columnToGet.map((item) => {
      let processvalue = selectedcolumntoprocess[item];
      const index = headerdata.indexOf(processvalue);
      let obj = {};
      obj[item] = index;
      return obj;
    });

    const readdata = (CSVdata, headerdata) => {
      return new Promise((resolve, reject) => {
        if (CSVdata && headerdata) {
          // console.log('CSVdata >', CSVdata)
          // console.log('headerdata >', headerdata)
          let selectedData;

          if (DateColumn !== -1 && Dates.length === CSVdata.length) {
            CSVdata.forEach((row, rowIndex) => {
              row[DateColumn] = Dates[rowIndex]; // Update the value at DateColumn index
            });
            if (dateRange.startDate !== "") {
              // Convert startDate and endDate to Date objects for comparison
              const startDate = new Date(dateRange.startDate);
              const endDate = new Date(dateRange.endDate);

              // Filter rows based on the date range and update CSVdata
              CSVdata = CSVdata.filter((row) => {
                const rowDate = new Date(row[DateColumn]);
                return rowDate >= startDate && rowDate <= endDate;
              });
            }
          } else {
          }

          const datatoprocess = CSVdata;
          // Extract selected columns
          if (coloumns_selected[0][columnToGet[0]] !== -1) {
            selectedData = datatoprocess.map((row, rownumber) => {
              const selectedRow = {};

              UserselectedID.push(row[coloumns_selected[0][columnToGet[0]]]); // Store the selected ID

              coloumns_selected.map((columnIndex, index) => {
                var valuesArray = Object.values(columnIndex);
                selectedRow["id"] = rownumber.toString();
                selectedRow[azuredataformat[index]] = row[valuesArray[0]];
              });

              return selectedRow;
            });
          } else {
            selectedData = datatoprocess.map((row, rownumber) => {
              const selectedRow = {};

              coloumns_selected.map((columnIndex, index) => {
                var valuesArray = Object.values(columnIndex);
                selectedRow["id"] = rownumber.toString();
                selectedRow[azuredataformat[index]] = row[valuesArray[0]];
              });

              return selectedRow;
            });
          }
          // console.log('selectedData before mapping: ', selectedData)

          selectedData.map((item) => {
            if (item?.text?.length > Max_review_length) {
              item.text = item.text.substring(0, Max_review_length);
              setshowsentencetrimmed(true);
            }
            // else if(!item.text){
            //   item.text = ""
            // }
            // if (item.date.length > Max_review_length) {
            //   item.text = item.text.substring(0, Max_review_length);
            //   setshowsentencetrimmed(true);
            // }
          });
          // console.log('selectedData after mapping: ', selectedData) 

          resolve(selectedData);
        } else {
          reject("No data provided.");
        }
      });
    };

    const handleSubmission = async () => {
      try {
        let parsedData = await readdata(CSVdata, headerdata);
        // console.log('PARSED DATA: ', parsedData)
        let newParsedData = parsedData.map(({ id, text, date }) => {
          return date && date !== false ? { id, text, date } : { id, text };
        });
        if (DateColumn !== -1 && dateRange.startDate === "" && parsedData[0]?.date) {
          // Call the function and get the min and max dates
          const minMaxDates = findMinMaxDates(parsedData);
          // Update the state with the new dates

          await setDateRange({
            startDate: minMaxDates.minDate,
            endDate: minMaxDates.maxDate,
          });
        }
        if (parsedData.length > maxReviewsAllowed) {
          parsedData = parsedData.slice(0, maxReviewsAllowed);
          if (UserselectedID.length !== 0) {
            UserselectedID = UserselectedID.slice(0, maxReviewsAllowed);
          }

          if (DateColumn !== -1) {
            // Call the function and get the min and max dates
            const minMaxDates = findMinMaxDates(parsedData);

            // Update the state with the new dates

            await setDateRange({
              startDate: minMaxDates.minDate,
              endDate: minMaxDates.maxDate,
            });
          }
        }

      // console.log('Pasrsed Data AFTER: ', newParsedData)

        // Get the name of the uploaded CSV file
        const csvFileName = selectedFile ? selectedFile.name : "";

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            data: newParsedData ? newParsedData: parsedData,
            userId: userId,
            csvFileName: csvFileName,
            reportName: newCsvFileName,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            no_views: parsedData.length,
            invoicerecordid: Databaseinvoicerecord,
          }),
          credentials: "include", // This line ensures that cookies are sent with the request
        };

        try {
          const response = await fetch(
            `${apiUrl}/analyze_sentiment_and_cluster2`,
            requestOptions
          );
          setshowsentencetrimmed(false);
          if (response.ok) {
            // Request was successful (status code 200)
            toast.info("Your report is ready!", { hideProgressBar: true });
            const data = await response.json(); // Parse the response body as JSON if it's JSON
            const reportId = data.report_id;
            localStorage.setItem("selectedReportId", reportId);
            setSelectedReportId(reportId);
          } else {
            // Request failed with a non-2xx status code
            console.error(`Request failed with status: ${response}`);
          }
        } catch (error) {
          console.error(`Request failed`);
        }

        await propspassed.onSubmit(parsedData, UserselectedID);
      } catch (error) {
        console.error("Error reading or parsing the file:", error);
      }
      setshowloadingindicator(false);
    };

    handleSubmission();
  };

  useEffect(() => {
    if (Object.keys(selectedColumn).length > 0) {
      setshowcolumnsnotfounderror(false);
      setarecolumnselected(true);

      performsentimentanalysis(selectedColumn);
    }
  }, [selectedColumn]);

  useEffect(() => {
    if (propspassed.analysisnotperformed) {
      setarecolumnselected(false);
    }
  }, [propspassed.analysisnotperformed, setarecolumnselected]);

  const handleSimilarFile = () => {
    setSimilarReportFound(false);
  };

  const handleSimilarFile2 = (reportId) => {
    setSimilarReportFound(false);
    setSelectedReportId(reportId);
    setShowDropdown(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Get the dropdown element
      const dropdownElement = document.querySelector(".dropdown-menu-sidebar");
      // Make sure the click is outside of both the dropdown icon and the dropdown menu
      if (
        dropdownOpenSidebar &&
        dropdownElement &&
        !dropdownElement.contains(event.target) &&
        !event.target.matches(".dropdown-icon")
      ) {
        setDropdownOpenSidebar(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpenSidebar]);

  const [userreviewsdata, setuserreviewsdata] = useState(null);
  const [packagedatareload, setpackagedatareload] = useState(0);
  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}package/isuserpackage1active`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Include other necessary headers
            },
            body: JSON.stringify({ userId }),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch user data, status: ${response.status}`
          );
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching user data:", error);
        return null;
      }
    };

    // Type guard to ensure userId is not null
    if (userId) {
      fetchUserData(userId)
        .then((data) => {
          if (data && data.status === "passed") {
            setuserreviewsdata(data.data); // Store the data
          } else {
            setuserreviewsdata(null); // Set it to null
          }
        })
        .catch((error) => {
          console.error("Error handling user data:", error);
          setuserreviewsdata(null); // Set it to null in case of an error
        });
    } else {
      console.error("userId is null, skipping fetch.");
    }
  }, [userId, packagedatareload]); // useEffect depends on userId, ensuring it re-runs when userId changes

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      // Clear all related states and update the file
      setIsChecked(false);
      setShowDropdown(false);
      setSimilarReportFound(false);
      setshowselectedfileempty(false);
      setshowcolumnsnotfounderror(false);
      setShowPopup(false);
      setshowhistogram(false); // Hide histogram or any related components
      setSelectedFile(e.target.files[0]); // Set the new file
    } else {
      setIsChecked(true);
      setShowDropdown(false);
      setSimilarReportFound(false);
      setShowPopup(false);
      setshowselectedfileempty(false);
      setshowcolumnsnotfounderror(false);
      setshowhistogram(true); // Or hide it based on your logic
      setSelectedFile(null); // Clear the selected file
      fileInputRef.current.value = ""; // Reset the file input field
    }
  };

  const handleFileClick = () => {
    // Clear the input value to ensure the onChange event triggers even for the same file
    if (fileInputRef.current) {
      // If no file is selected, reset the state

      setIsChecked(true);
      setshowselectedfileempty(false);
      setshowcolumnsnotfounderror(false);
      setShowDropdown(false);
      setSimilarReportFound(false);
      setShowPopup(false);
      setshowhistogram(true); // Or hide it based on your logic
      setSelectedFile(null); // Clear the selected file
      fileInputRef.current.value = null;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSelectedReportId(null);
    setshowloadingindicator(false);
    selectcoloumnstoprocess(e);
  };

  return (
    <div
      className={`container mt-5 main-content ${
        isSidebarOpen ? "shifted" : ""
      }`}
    >
      <div className="d-flex justify-content-center align-items-center mb-3">
        <form onSubmit={handleSubmit} className="d-flex">
          <div className="submitfilediv">
            <div className="heading">
              <h1>Upload CSV file to generate Perception Report</h1>
            </div>
            <div className="input-group">
              <input
                ref={fileInputRef} // Reference to the file input
                type="file"
                className="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
                accept=".csv"
                onClick={handleFileClick} // Clear input value on click to allow re-selection
                onChange={handleFileChange} // Handle file selection
              />

              <button
                className="btn btn-outline-secondary"
                type="submit"
                id="inputGroupFileAddon04"
                disabled={!selectedFile}
                style={{ zIndex: "1" }}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>

      {headerdata.length > 0 && showPopup && (
        <FilePreviewToggle
          headers={headerdata}
          data={CSVdata}
          isPopupVisible={isPopupVisible}
          setPopupVisible={setPopupVisible}
        />
      )}

      {showsentencetrimmed && (
        <div className="additional-div">
          Reviews over 5120 characters are trimmed at the end.
        </div>
      )}

      {showloadingindicator && (
        <LoadingIndicator processing={processing} text={loadingMessage} />
      )}
      {showfileformaterror && (
        <div className="additional-div">
          Uploaded file format is not acceptable. Please upload file in csv/Xlsx
          format.
        </div>
      )}

      {showfilesizeerror && (
        <div className="additional-div">
          Uploaded file size is greater than 5MB. Please upload a csv file with
          size less than 5MB.
        </div>
      )}

      {showcolumnsnotfounderror && (
        <div className="additional-div">
          Please select all required fields to process.
        </div>
      )}

      {showselectedfileempty && (
        <div className="additional-div">
          The uploaded file must contain at least {minReviewsAllowed} reviews.
          Please select a file with sufficient data.
        </div>
      )}

      {propspassed.analysisnotperformed && (
        <div className="additional-div">
          Selected Columns are not correct. Please select the Correct option for
          each field.
        </div>
      )}
      {similarReportFound && (
        <div className="upper-similarfile-warning">
          <div className="similarfile-warning">
            <p className="similarfile-text">
              The file "<span className="text-break">{selectedFile.name}</span>"{" "}
              has been processed, and one or more reports have been generated
              accordingly. While the report names may vary, they are all
              associated with this file.
            </p>
            <p className="similarfile-text">
              If you are attempting to upload the same file again, please select
              existing report(s) from the sidebar or report(s) shown below to
              avoid being charged for a new or similar analysis
              of the same file.
            </p>{" "}
            {/* <p className="similarfile-text">
              This approach helps you avoid the cost associated with conducting a new analysis.</p> */}
            <div className="review-tile-container">
              {[...reviewsDet]
                .reverse()
                .filter(
                  (review) => review.reviews_filename === selectedFile.name
                )
                .map((review) => (
                  <div
                    key={review.report_id}
                    className="review-tile"
                    onClick={() => handleSimilarFile2(review.report_id)}
                  >
                    <h3
                      className="sidebar-item-filename"
                      style={{ fontSize: "12px", color: "#6C757D" }}
                    >
                      Report Name:
                    </h3>
                    <span
                      className="sidebar-item-filename"
                      title={
                        review.reportname
                          ? review.reportname
                          : review.reviews_filename
                      }
                    >
                      {review.reportname
                        ? review.reportname.length > 12
                          ? review.reportname.slice(0, 12) + "..."
                          : review.reportname
                        : review.reviews_filename.length > 12
                        ? review.reportname.slice(0, 12) + "..."
                        : review.reviews_filename}
                    </span>
                    <div className="sidebar-item-details">
                      <span className="sidebar-item-date sidebar_report_details">
                        <p
                          className="sidebar-item-date"
                          title={review.reviews_filename}
                        >
                          {" "}
                          <br /> Filename:{" "}
                          {review.reviews_filename.length > 12
                            ? review.reviews_filename.slice(0, 12) + "..."
                            : review.reviews_filename}
                          <br />
                          No. of reviews: {review.no_reviews_processed}
                        </p>
                        <span>
                          {new Date(review.datetime + "Z").toLocaleString()}
                        </span>
                      </span>
                    </div>
                  </div>
                ))}
            </div>
            <button className="proceed-button" onClick={handleSimilarFile}>
              Proceed with new analysis{" "}
              {userreviewsdata?.complimentary_reviews === 0 &&
                userreviewsdata?.free_reviews === 0 && (
                  <span>(This will incur separate charges)</span>
                )}
            </button>
          </div>
        </div>
      )}

      {headerdata.length > 0 &&
        arecolumnsselected === false &&
        showfileformaterror === false &&
        similarReportFound === false &&
        showDropdown === true && (
          <CallDropdown
            headerdata={headerdata}
            csvdata={CSVdata}
            columnToGet={columnToGet}
            setSelectedColumn={setSelectedColumn}
            Required_columns={Required_columns}
            Descriptionofcolumn={Descriptionofcolumn}
            setDates={setDates}
            setDateRange={setDateRange}
            setDateColumn={setDateColumn}
            csvDefault={selectedFile.name}
            updateReportName={setNewCsvFileName}
            reviewsDet={reviewsDet}
            userId={userId}
            maxReviewsAllowed={maxReviewsAllowed}
            minReviewsAllowed={minReviewsAllowed}
            userPackageData={userPackageData}
            setDatabaseinvoicerecord={setDatabaseinvoicerecord}
            packagedatareload={packagedatareload}
            setpackagedatareload={setpackagedatareload}
          />
        )}

      <div className="both-columns-div">
        <div className="sidebar-container" style={sidebarStyle}>
          {isSidebarOpen && (
            <div
              className="sidebar-menu"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
              }}
            >
              {/* ... Other menu items ... */}
              <div className="sidebar-item-header" onClick={toggleDropdown}>
                <div>
                  <img
                    src={fileicon}
                    alt="open-side-bar"
                    className="sidebar-icon"
                  />
                </div>

                <div
                  style={{
                    display: "flex",

                    alignItems: "center",
                    paddingTop: "5px",

                    minWidth: "250px",
                  }}
                  className="label-font"
                >
                  {" "}
                  All Reports
                </div>
              </div>

              {dropdownOpen && (
                <div className="sidebar-dropdown">
                  {[...reviewsDet].reverse().map((review) => (
                    <div className="full-cont-report">
                      <div
                        key={review.report_id}
                        className="sidebar-item"
                        onClick={(e) => {
                          handleFileChange({ target: { files: [] } });
                          setSelectedReportId(review.report_id);
                          isMobileScreen && toggleSidebar();
                          scrollToReportDiv();
                        }}
                      >
                        {editingReportId === review.report_id ? (
                          <div>
                            <input
                              type="text"
                              value={editingReportName}
                              maxLength={50}
                              onChange={(e) =>
                                setEditingReportName(e.target.value)
                              }
                              onBlur={() =>
                                handleRenameReport(review.report_id)
                              }
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  handleRenameReport(review.report_id);
                                }
                              }}
                              autoFocus
                            />

                            {reportNameError && (
                              <div className="error-message">
                                <p
                                  style={{
                                    fontSize: "10px",
                                    color: "red",
                                    alignSelf: "flex-start",
                                  }}
                                >
                                  {reportNameError}
                                </p>
                              </div>
                            )}
                          </div>
                        ) : (
                          <span
                            className="sidebar-item-filename label-font"
                            style={{
                              fontWeight: "600",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "20ch", // Adjust this value as needed
                              display: "inline-block",
                            }}
                            title={
                              review.reportname
                                ? review.reportname
                                : review.reviews_filename
                            }
                          >
                            {review.reportname
                              ? review.reportname
                              : review.reviews_filename}
                          </span>
                        )}
                        <div className="sidebar-item-details">
                          <span className="sidebar-item-date sidebar_report_details">
                            No. of reviews: {review.no_reviews_processed}
                            <br />
                            <span>
                              {new Date(review.datetime + "Z").toLocaleString()}
                            </span>
                          </span>
                        </div>
                      </div>

                      <div className="the-dots">
                        <FontAwesomeIcon
                          icon={faEllipsisV}
                          className="dropdown-icon"
                          onClick={() =>
                            toggleDropdownSidebar(review.report_id)
                          }
                        />
                        {dropdownOpenSidebar &&
                          currentDropdownSidebar === review.report_id && (
                            <div className="dropdown-menu-sidebar">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevents the click from propagating to the document
                                  toggleSidebar();
                                  createShareableLink(review.report_id);
                                }}
                              >
                                Share
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevents the click from propagating to the document
                                  setEditingReportId(review.report_id);
                                  setDropdownOpenSidebar(false);
                                  setEditingReportName(
                                    review.reportname
                                      ? review.reportname
                                      : review.reviews_filename
                                  );
                                }}
                              >
                                Rename
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevents the click from propagating to the document
                                  setReportNameToDelete(
                                    review.reportname
                                      ? review.reportname
                                      : review.reviews_filename
                                  );
                                  setReportreviewsToDelete(
                                    review.no_reviews_processed
                                  );
                                  confirmDeleteReport(review.report_id);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {reviewsDet.length > 1 && (
                <div className="sidebar-item-header">
                  <div
                    style={{
                      display: "flex",
                      minWidth: "200px",
                      paddingTop: "5px",
                      alignItems: "center",
                    }}
                  >
                    {/* Wrap the checkbox in its own div and control the width */}
                    <div
                      style={{
                        display: "flex",
                        marginTop: "-1rem",
                        marginLeft: "12px",
                        minWidth: "15px",
                      }}
                    >
                      <label className="control control--checkbox label-font">
                        View comprehensive overview of sentiment scores
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        <div className="control__indicator"></div>
                      </label>
                    </div>
                  </div>
                </div>
              )}

              <div
                className="sidebar-item-header"
                style={{ marginTop: "auto", paddingBottom: "2rem" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginLeft: "10px", marginBottom: "15px" }}>
                    {userreviewsdata != null ? (
                      <ReviewStatus userreviewsdata={userreviewsdata} />
                    ) : (
                      console.log("No user Id")
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="arrow-content" style={arrowStyle}>
          <div className="arrow-toggle">
            <FontAwesomeIcon
              icon={isSidebarOpen ? faCircleChevronLeft : faCircleChevronRight}
              onClick={handlesidebararrow}
              className="sidebar-arrow-icon"
              color="#364545"
            />
          </div>
        </div>
      </div>
      {/* </div> */}

      {DeleteReportmodalOpen && (
        <>
          <div className="modal">
            <div className="modal-overlay"></div>
            <div className="modal-content-pay">
              <div className="heading-container">
                <p className="heading-text">Confirm Deletion</p>
              </div>
              <hr />
              <p style={{ fontSize: "17px", color: "#364545" }}>
                {DeleteReportmodalMessage}
              </p>

              <p style={{ fontSize: "17px", color: "#364545" }}>
                Report Name:{" "}
                <span style={{ fontWeight: "bold", wordBreak: "break-word" }}>
                  {reportNameToDelete}
                </span>
              </p>
              <p
                style={{
                  fontSize: "17px",
                  color: "#364545",
                  marginTop: "-15px",
                }}
              >
                Report Reviews:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {reportreviewsToDelete}
                </span>
              </p>

              <div className="button-group">
                <button type="button" onClick={handleCancelDelete}>
                  Cancel
                </button>
                <button
                  type="button"
                  style={{ backgroundColor: "rgb(167, 28, 28)" }}
                  onClick={handleConfirmDelete}
                >
                  Delete
                </button>
              </div>
            </div>
            do
          </div>
        </>
      )}

      {showhistogram && reviewsDet.length > 1 && (
        <>
          <div
            ref={myDivRef}
            style={{
              maxWidth: "1220px",
              margin: "auto",
              marginTop: "5rem",
            }}
            className="month-chart-container"
          >
            <div style={{ display: "flex" }}>
              {" "}
              <div>
                {" "}
                <h1
                  style={{
                    color: "rgb(78, 78, 78)",
                    fontSize: "24px",
                    marginBottom: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <div className="headings_class">
                    <h3>Comprehensive Overview of Sentiment Scores</h3>
                  </div>
                </h1>
              </div>
              <div
                style={{
                  alignItems: "center",

                  paddingLeft: "3px",
                }}
              >
                {" "}
                <Tooltip
                  title="This chart visualizes sentiment scores from past reports, showing trends over time. Each bar indicates an individual report's sentiment, offering a snapshot of evolving perception."
                  overlayClassName="custom-tooltip"
                  placement="right"
                >
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    style={{
                      color: "#9e9e9e",
                      paddingTop: "3px",
                      paddingLeft: "5px",
                      width: "18px",
                    }}
                  />
                </Tooltip>
              </div>
            </div>
            <div
              style={{
                maxWidth: reviewsDet.length > 5 ? "1220px" : "800px",
                width: "100%", // Ensure this div takes up the full width of its parent
                display: "flex", // Use flex for this div to center its content
                alignItems: "center", // Vertical centering of its children
                justifyContent: "center", // Horizontal centering of its children
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <ReportsContainer reportsdata={reviewsDet} />
            </div>
          </div>
          <hr
            style={{
              height: "3px",
              marginTop: "2rem",
              backgroundColor: "black",
              border: "none",
              zIndex: 10,
            }}
          />
        </>
      )}

      {selectedReportId && (
        <div className="">
          <div className="share-button-2" ref={reportRef}>
            {reviewsDet
              .filter((review) => review.report_id === selectedReportId)
              .map((review) => (
                <h3
                  style={{
                    fontSize: "18px",
                    color: "#4E4E4E",
                    wordBreak: "break-all",
                  }}
                  key={review.report_id}
                >
                  Report Name:{" "}
                  {review.reportname
                    ? review.reportname.length > 35
                      ? review.reportname.slice(0, 35) + "..."
                      : review.reportname
                    : review.reviews_filename.length > 35
                    ? review.reviews_filename.slice(0, 35) + "..."
                    : review.reviews_filename}
                </h3>
              ))}
            <button
              onClick={(e) => {
                e.stopPropagation(); // Prevents the click from propagating to the document
                createShareableLink(selectedReportId);
              }}
            >
              Share Report <FontAwesomeIcon icon={faShareFromSquare} />
            </button>
          </div>
          <Dashboard
            selecteduserid={userId}
            selectedReportId={selectedReportId}
          />
        </div>
      )}

      <ShareableLinkModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        link={generatedLink}
      />
    </div>
  );
};

export default Search;
