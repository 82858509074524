import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.css";

import Router from "./router"; // Ensure this path is correct

const App = () => (
  <BrowserRouter>
    <Router />
  </BrowserRouter>
);

const rootElement = document.getElementById("root");
if (rootElement) {
  createRoot(rootElement).render(<App />);
} else {
  console.error("Failed to find the root element");
}
