import React, { useState, useEffect } from "react";
import ListItem from "./ListItem";
import "./ListItem.css";
import PlanCard from "./PlanCard";
import { FaRegCheckCircle } from "react-icons/fa";
import { Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function Plans() {
  const items = [
    "Free Reviews",
    "Sentiment Score",
    "Intelligent Search",
    "Key Opinion Topics",
    "Positive & Negative Phrases",
    "Custom Review Analysis",
    "Sentiment Percentages",
    "Trend Analysis Graph",
    "Sentiments Curve",
    "Word Cloud",
  ];
  const [packageDetails, setPackageDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const fetchPackages = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`/package/plans`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setPackageDetails(data);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPackages();
  }, []);

  return (
    <div className="feature_container">
      <div className="arrow-container_feature">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={handleBack}
          className="back-icon-feature"
        />
      </div>
      {isLoading ? (
        <div className="centered-loader_report_tab">
          <Spin size="large" />
        </div>
      ) : (
        <div className="container plans-container">
          <h2 className="text-left fs-1 fw-bold theme-color mt-4">
            Plans & Pricing
          </h2>
          <p className="text-dark fs-5 mt-4">
            At PIE, we offer a flexible{" "}
            <span
              style={{ color: "teal", fontWeight: "bold", fontSize: "22px" }}
            >
              Pay As You Go
            </span>{" "}
            model. Each month, the first{" "}
            {packageDetails.packages.max_subscribers} subscribers are assigned
            with the "PIE Subscription (with preview)" package which allows them
            to process the first {packageDetails.packages.no_free_reviews}{" "}
            reviews for free. Once this threshold is reached, any new
            subscribers are assigned with the standard "PIE Subscription"
            package. Both the packages provide access to all the features in PIE
            and anaylsis is charged at £
            {packageDetails.reviewCost.price_per_review} per review.
          </p>
          <div className="container my-5">
            <div className="row">
              <div className="col mb-5 visible">
                <div className="border rounded-top width-90 bg-mute">
                  <h4
                    className="text-center fs-3 fw-bold py-4 px-2"
                    style={{ color: "#364545" }}
                  >
                    Choose Plan
                  </h4>
                </div>
                <ListItem
                  items={items}
                  users={packageDetails.packages.max_subscribers}
                  freeReviews={packageDetails.packages.no_free_reviews}
                />
              </div>
              <div className="col mb-5">
                <div className="border bg-gray rounded-top width-90">
                  <h4 className="text-center fs-4 fw-bold px-2 text-light pd-heading">
                    PIE Subscription (with preview)
                  </h4>
                </div>
                <PlanCard
                  items={items}
                  icon={<FaRegCheckCircle style={{ color: "teal" }} />}
                />
              </div>
              <div className="col mb-5">
                <div className="border bg-theme2 rounded-top width-90">
                  <h4 className="text-center fs-3 fw-bold py-4 px-2 text-light">
                    PIE Subscription
                  </h4>
                </div>
                <PlanCard
                  items={items}
                  icon={<FaRegCheckCircle style={{ color: "teal" }} />}
                  label="PIE"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
