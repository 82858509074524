import { useNavigate } from "react-router-dom";
import "./style.css"; // Import your CSS file

const UserAccountDeactivated = () => {
  const redirectToHome = () => {
    window.location.href = "/";
  };

  return (
    <>
      <div className="Main_email_div">
        <div className="verification-email-sent">
          <h2>Account Deactivated Successfully</h2>
          <p>
            Your account has been successfully deactivated and your data has
            been deleted in accordance with our privacy policy. Please note, we
            retain minimal information necessary for operational purposes.
            Should you choose to return, we look forward to welcoming you back.
            Thank you for your understanding.
          </p>

          <p>
            <strong>Need Help?</strong>
            <br />
            If you need assistance or have any questions, please don't hesitate
            to reach out to our support team.
          </p>
          <p>
            <strong>Contact Us:</strong>
            <br />
            Email:{" "}
            <a
              href={`${process.env.REACT_APP_support_email}`}
              className="label-font"
              style={{ fontSize: "16px", color: "teal" }}
            >
              {`${process.env.REACT_APP_support_display_address}`}
            </a>
          </p>
          <button onClick={redirectToHome} className="home-button">
            Return to Homepage
          </button>
        </div>
      </div>
    </>
  );
};

export default UserAccountDeactivated;
