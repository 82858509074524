// CallDropdown.js
import React, { useEffect, useState } from "react";
import CSVHeaderdropdown from "./CSVHeaderdropdown";
import ParentComponent from "./selectdatelogic/TwoOptions";
import "./CallDropdown.css";
import { processData } from "./selectdatelogic/DateFunctions/separatedatefromtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import visa from "./logos/visa.png"; // Tell webpack this JS file uses this image
import mastercard from "./logos/mastercard.png"; // Tell webpack this JS file uses this image
import amex from "./logos/amex.png"; // Tell webpack this JS file uses this image
import defaultimg from "./logos/default.png";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Typography, Spin } from "antd";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { verify3DS } from "../../common/utils/verify3DS";
import { capture3DSPayment } from "../../common/utils/capture3DSPayment";
import ErrorModal from "./ErrorModal";
// const [isOnline, setIsOnline] = useState(navigator.onLine);

const { Text } = Typography;

const nodeappUrl = process.env.REACT_APP_NODE_SERVER_URL;

function CallDropdown(propspassed) {
  const [userselectedcolumn, setuserselectedcolumn] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // propspassed.setDateColumn(
  //   propspassed.headerdata.indexOf(userselectedcolumn["Date"])
  // );
  const [lessreviewswarning, setlessreviewswarning] = useState(false);
  const [lessreviewsthanthresold, setlessreviewsthanthresold] = useState(false);
  const [morereviewswarning, setmorereviewswarning] = useState(false);
  const [totalReviews, setTotalReviews] = useState(propspassed.csvdata.length);
  const [processedDates, setProcessedDates] = useState([]);
  const [reportNameError, setReportNameError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [show3DSModal, setShow3DSModal] = useState(false);
  const [orderId3DSModal, setOrderId3DSModal] = useState('');
  const [redirectUrl3DSModal, setRedirectUrl3DSModal] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [loadingdots, setloadingdots] = useState(false);
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);
  const [savedCards, setSavedCards] = useState(null);
  const [FreeagentmodalMessage, setFreeagentModalMessage] = useState(
    "Please click on 'Edit Billing Details' to provide billing details for the invoice. If you wish to use previously provided details for invoice then click on the 'Proceed' button to continue with the analysis."
  );
  const [showToast, setShowToast] = useState(false);

  const [FreeagentmodalOpen, setFreeagentModalOpen] = useState(false);
  const [FreeAgentDetails, setFreeAgentDetails] = useState(false);

  const [isLoading_freeagent, setIsLoading_freeagent] = useState(false);
  const [checkboxSelected, setCheckboxSelected] = useState(false);

  const [userreviewsdata, setuserreviewsdata] = useState(null);
  const [CompensatoryReviews, setCompensatoryReviews] = useState(null);

  const errorMessage3DS =
    "The 3D Secure verification could not be completed. This might be due to a closed verification window or an issue during verification. Please try again to complete the process.";
  const errorMessageSessionExpired = "Your session has expired.";
  const errorMessagePopup = "Either the verification tab was closed or blocked by your browser settings. Please check your browser settings to allow pop-ups and redirects, then try again."
  const [sessionExpired, setSessionExpired] = useState(false);
  const [popupBlocked, setPopupBlocked] = useState(false);

  useEffect(() => {
    // console.log('propspassed to callDropdown: ', propspassed)
    propspassed.setDateColumn(
      propspassed.headerdata.indexOf(userselectedcolumn["Date"])
    );
  }, [userselectedcolumn, propspassed]);

  
  useEffect(() => {
    // console.log("Updated Selected Columns: ", userselectedcolumn);
  }, [userselectedcolumn]);

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}package/isuserpackage1active`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Include other necessary headers
            },
            body: JSON.stringify({ userId }),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch user data, status: ${response.status}`
          );
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching user data:", error);
        return null;
      }
    };

    // Type guard to ensure userId is not null
    if (propspassed.userId) {
      fetchUserData(propspassed.userId)
        .then((data) => {
          if (data && data.status === "passed") {
            setuserreviewsdata(data.data); // Store the data
            setCompensatoryReviews(data.data.compensatory_reviews);
          } else {
            setuserreviewsdata(null); // Set it to null
            setCompensatoryReviews(data.compensatory_reviews);
          }
        })
        .catch((error) => {
          console.error("Error handling user data:", error);
          setuserreviewsdata(null); // Set it to null in case of an error
        })
        .finally(() => {
          setIsLoadingData(false);
        });
    } else {
      setIsLoadingData(false);
    }
  }, [propspassed.userId]); // useEffect depends on userId, ensuring it re-runs when userId changes

  const checkUserFieldValue = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}user/checkInvoiceDetailField`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userdata: propspassed.userId }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }

      const result = await response.json();

      if (result.invoice_field_value === true) {
        setFreeAgentDetails(true);
      } else {
        setFreeAgentDetails(false);
      }
    } catch (error) {
      console.error("Error fetching field value:", error);
    }
  };

  useEffect(() => {
    checkUserFieldValue();
  }, [propspassed.userId]);

  useEffect(() => {
    if (totalReviews < propspassed.minReviewsAllowed) {
      setIsButtonDisabled(true);
      setlessreviewsthanthresold(true);
    } else {
      setIsButtonDisabled(false);
      setlessreviewsthanthresold(false);
    }
  }, [totalReviews]);

  const handleCheckboxChange = (event) => {
    setCheckboxSelected(event.target.checked);
  };

  const generateUniqueReportName = (baseName) => {
    let uniqueName = baseName;
    let counter = 1;

    while (
      propspassed.reviewsDet.find((review) => review.reportname === uniqueName)
    ) {
      uniqueName = `${baseName} (${counter})`;
      counter++;
    }

    return uniqueName;
  };

  const [reportName, setReportName] = useState(
    generateUniqueReportName(propspassed.csvDefault)
  );

  useEffect(() => {
    let dateColumnArray = propspassed.csvdata.map(
      (row) => row[propspassed.headerdata.indexOf(userselectedcolumn["Date"])]
    );
    console.log('dateColumnArray: ', dateColumnArray)

    let result = processData(dateColumnArray);

    setProcessedDates(result.sorteddates);
    propspassed.setDates(result.dates);
    setTotalReviews(result.sorteddates.length);
  }, [
    propspassed.csvdata,
    propspassed.headerdata.indexOf(userselectedcolumn["Date"]),
  ]);

  useEffect(() => {
    if (totalReviews > propspassed.maxReviewsAllowed) {
      setmorereviewswarning(true);
      setlessreviewswarning(false);
    } else if (totalReviews < propspassed.maxReviewsAllowed) {
      setlessreviewswarning(true);
      setmorereviewswarning(false);
    } else {
      setlessreviewswarning(false);
      setmorereviewswarning(false);
    }

    // Other conditional checks and state updates
  }, [totalReviews]); // Add other dependencies as needed

  // Handle input field changes
  const handleReportNameChange = (event) => {
    const newReportName = event.target.value;
    setReportName(newReportName);

    // Check if report name is empty
    if (!newReportName.trim()) {
      setReportNameError("Report name cannot be empty");
      setIsButtonDisabled(true);
      return;
    }

    // Check if report name already exists
    const existingReport = propspassed.reviewsDet.find(
      (review) => review.reportname === newReportName
    );

    if (existingReport) {
      setReportNameError("Report name already exists");

      setIsButtonDisabled(true);
    } else {
      setReportNameError("");

      setIsButtonDisabled(false);
    }
  };

  // Modal component render method
  const handleCloseModal = () => {
    setModalOpen(false);
    setIsButtonDisabled(false);
  };
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    const fetchAndDisplaySavedCards = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}payment/api/vault/saved-methods`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ userId: propspassed.userId }),
          }
        );
        if (!response.ok)
          throw new Error("Failed to fetch saved payment methods.");

        const savedMethods = await response.json();
        setSavedCards(savedMethods);
      } catch (error) {
        setSavedCards(null);
        console.error("Error fetching saved cards:", error);
      }
    };

    // Fetch initially
    fetchAndDisplaySavedCards();

    // Set up an event listener for storage changes
    const handleStorageChange = (event) => {
      if (event.key === "cardDetailsUpdated") {
        fetchAndDisplaySavedCards();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [propspassed.userId]);

  const createCardElement = (card) => {
    const brandLogoUrl = getBrandLogo(card.brand);
    // setLoading(false);

    return (
      <div className="payment-method-div">
        {" "}
        <a className="co-register_heading" style={{ fontSize: "16px" }}>
          Payment Method
        </a>{" "}
        <div className="co-payment-method" key={card.last_digits}>
          <div className="co-payment-method-inner">
            <img
              src={brandLogoUrl}
              alt={card.brand}
              className="card-brand-logo"
            />
            <div className="co-card-details-text">
              <div className="co-card-holder-name">{card.name}</div>
              <div className="co-card-number">
                •••• •••• •••• {card.last_digits}
              </div>
              <div className="co-card-expiry">Expires {card.expiry}</div>
            </div>
            <a
              href="/editaccount?option=PaymentDetails" // Set the href to the path you want to navigate to
              target="_blank" // This opens the link in a new tab
              rel="noopener noreferrer" // Security measure for opening links in new tabs
              style={{
                fontSize: "14px",
                color: "#007bff", // Styling it like a link
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() =>
                window.localStorage.setItem("accessedFromModal", "true")
              }
            >
              Edit payment details
            </a>
          </div>
        </div>
      </div>
    );
  };
  function getBrandLogo(brand) {
    switch (brand.toLowerCase()) {
      case "visa":
        return `${visa}`; // Replace with the path or URL to the Visa logo
      case "mastercard":
        return `${mastercard}`; // Replace with the path or URL to the Mastercard logo
      case "amex":
        return `${amex}`; // Replace with the path or URL to the American Express logo
      default:
        return `${defaultimg}`; // Replace with the path or URL to a default card logo
    }
  }

  const handleModalSubmit = async (event) => {
    event.preventDefault();
    setIsLoading_freeagent(true);
    if (checkboxSelected) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}edituseraccount/toggleFreeagentDetailsCheckbox`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ userdata: propspassed.userId }),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        const result = await response.json();

        if (result.status === "passed") {
          setFreeAgentDetails(true);
          setFreeagentModalOpen(false);
        }
        setIsLoading_freeagent(false);
      } catch (error) {
        console.error("Error changing field value:", error);
        setIsLoading_freeagent(false);
      }
    } else {
      setFreeAgentDetails(true);
      setFreeagentModalOpen(false);
      setIsLoading_freeagent(false);
    }
  };

  useEffect(() => {
    if (FreeAgentDetails && !FreeagentmodalOpen) {
      handleSubmit();
    }
  }, [FreeagentmodalOpen]);

  const adjustedTotalReviews =
    totalReviews > propspassed.maxReviewsAllowed
      ? propspassed.maxReviewsAllowed
      : totalReviews;
  const checkInternetStatus = async () => {
    if (!navigator.onLine) {
      setShowToast(true);
      toast.error(
        "No internet connection. Please check your connection and try again.",
        {
          toastId: "internet-status-toast",
          hideProgressBar: true
        }
      );
      return true;
    } else {
      setShowToast(false);
      return false;
    }
  };


  const handle3DSVerification = async (redirectUrl, orderID, PaidReviews) => {
    localStorage.setItem('redirect_URL', redirectUrl)
    try {
      setIsLoading(true);
      const response = await verify3DS();
      console.log('Loader started...', isLoading)
      if (response.status === "verified") {
        const checkoutResponseHolder = await capture3DSPayment(propspassed.userId, orderID, PaidReviews)
        return checkoutResponseHolder.response
      }
      else {
        setIsLoading(false)
        setShow3DSModal(true)
        return false
      }
    } catch (err) {
      if (err.status === "closed") {
        setIsLoading(false);
        setShow3DSModal(true);
        setPopupBlocked(true)
        return false;
      } else {
        setIsLoading(false);
        setIsButtonDisabled(false);
        setShow3DSModal(true)
        return false;
      }
    }
  };

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    const isOffline = await checkInternetStatus();
    if (isOffline) {
      return;
    }

    setCheckoutSuccess(false);
    setIsButtonDisabled(true);

    const existingReport = propspassed.reviewsDet.find(
      (review) => review.reportname === reportName
    );
    if (existingReport) {
      setReportNameError("Report name already exists");
      setIsButtonDisabled(false);
      return;
    }
    setReportNameError("");

    const totalReviews = userreviewsdata?.total_reviews ?? 0;
    const reviewsProcessed = userreviewsdata?.reviews_processed ?? 0;

    const availableReviews =
      totalReviews - reviewsProcessed + CompensatoryReviews;

    if (userreviewsdata) {
      if (adjustedTotalReviews > availableReviews) {
        if (!FreeAgentDetails) {
          setFreeagentModalOpen(true);
          return;
        }
        setIsLoading(true);

        // Proceed with checkout
        let checkoutResponse = await fetch(
          `${nodeappUrl}payment/api/checkout`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: propspassed.userId, // Pass the userId to the API
              totalReviews: adjustedTotalReviews - availableReviews,
            }),
            credentials: "include", // This ensures cookies are sent with the request
          }
        );
        if (checkoutResponse.status === 302) {
          const checkoutJson = await checkoutResponse.json()
          setRedirectUrl3DSModal(checkoutJson.redirectUrl)
          setOrderId3DSModal(checkoutJson.orderID)
          checkoutResponse = await handle3DSVerification(checkoutJson.redirectUrl, checkoutJson.orderID, adjustedTotalReviews - availableReviews)
        }
        if (checkoutResponse.status === 310) {

          setShow3DSModal(true)
          setIsLoading(false)
          setIsButtonDisabled(false)
        }
        else if (checkoutResponse.status === 400) {
          window.location.reload();
        } else if (checkoutResponse.status === 311) {
          setIsLoading(false);
          setModalOpen(true);
        } else if (checkoutResponse.status === 500) {
          toast.error(
            "An error occured from server side. Please try again later.",
            {
              hideProgressBar: true,
              toastId: "internet-status-toast"
            }
          );
          setIsLoading(false);
          setIsButtonDisabled(false);
        }
        console.log('MOVING TO PROCESS..', checkoutResponse)
        const checkoutData = await checkoutResponse.json()
        console.log('MOVING TO PROCESS..', checkoutData)
        try {
          if (checkoutData.response.status === "COMPLETED") {
            setCheckoutSuccess(true); // Indicate successful checkout
            propspassed.setDatabaseinvoicerecord(checkoutData.invoiceId);

            await fetch(`${nodeappUrl}package/updateReviewsProcessed`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userId: propspassed.userId,
                increasedAmount: totalReviews - reviewsProcessed,
              }),
            });

            await fetch(`${nodeappUrl}package/reduceCompensatoryReviews`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userId: propspassed.userId,
                reductionAmount: CompensatoryReviews,
              }),
            });
            setTimeout(() => {
              setIsLoading(false); // Stop loading
              propspassed.setpackagedatareload(
                propspassed.packagedatareload + 1
              );
              propspassed.updateReportName(reportName);
              propspassed.setSelectedColumn(userselectedcolumn);
              setIsButtonDisabled(false);
            }, 1000);
          } else {
            setIsLoading(false); // Stop loading
            setModalOpen(true);
            setIsButtonDisabled(false);
          }
        } catch (error) {
          setIsButtonDisabled(false);
          setModalOpen(true);
          setIsLoading(false);
        }
      } else {
        setloadingdots(true);
        const freereviews = totalReviews - reviewsProcessed;
        const freereviewsprocessed =
          adjustedTotalReviews > freereviews
            ? freereviews
            : adjustedTotalReviews;
        if (freereviewsprocessed !== 0) {
          await fetch(`${nodeappUrl}package/updateReviewsProcessed`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: propspassed.userId,
              increasedAmount: freereviewsprocessed,
            }),
          });
        }

        if (adjustedTotalReviews > freereviewsprocessed) {
          await fetch(`${nodeappUrl}package/reduceCompensatoryReviews`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: propspassed.userId,
              reductionAmount: adjustedTotalReviews - freereviewsprocessed,
            }),
          });
        }
        propspassed.setpackagedatareload(propspassed.packagedatareload + 1);
        propspassed.updateReportName(reportName);
        propspassed.setSelectedColumn(userselectedcolumn);
        setIsButtonDisabled(false);
        setloadingdots(false);
      }
    } else {
      if (adjustedTotalReviews > CompensatoryReviews) {
        if (!FreeAgentDetails) {
          setFreeagentModalOpen(true);
          return;
        }
        setIsLoading(true);
        try {
          let checkoutResponse = await fetch(
            `${nodeappUrl}payment/api/checkout`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userId: propspassed.userId, // Pass the userId to the API
                totalReviews: adjustedTotalReviews - CompensatoryReviews,
              }),
              credentials: "include", // This ensures cookies are sent with the request
            }
          );
          if (checkoutResponse.status === 302) {
            console.log("Checkout response: ", checkoutResponse)
            const checkoutJson = await checkoutResponse.json()
            setRedirectUrl3DSModal(checkoutJson.redirectUrl)
            setOrderId3DSModal(checkoutJson.orderID)
            checkoutResponse = await handle3DSVerification(checkoutJson.redirectUrl, checkoutJson.orderID, adjustedTotalReviews - CompensatoryReviews)
            console.log('resp', checkoutResponse)
          }

          if (checkoutResponse.status === 310) {
            setShow3DSModal(true)
            setIsLoading(false)
            setIsButtonDisabled(false)
          }
          else if (checkoutResponse.status === 400) {
            window.location.reload();
          } else if (checkoutResponse.status === 311) {
            console.log('IF checkout Response NOT OK')
            setIsLoading(false);
            setModalOpen(true);
          } else if (checkoutResponse.status === 500) {
            toast.error(
              "An error occured from server side. Please try again later.",
              {
                hideProgressBar: true,
                toastId: "internet-status-toast"
              }
            );
            setIsLoading(false);
            setIsButtonDisabled(false);
            return;
          }

          const checkoutData = await checkoutResponse.json()
          try {
            if (checkoutData.response.status === "COMPLETED") {
              setCheckoutSuccess(true);
              propspassed.setDatabaseinvoicerecord(checkoutData.invoiceId);

              await fetch(`${nodeappUrl}package/reduceCompensatoryReviews`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  userId: propspassed.userId,
                  reductionAmount: CompensatoryReviews,
                }),
              });

              setTimeout(() => {
                propspassed.setpackagedatareload(
                  propspassed.packagedatareload + 1
                );
                setIsLoading(false); // Stop loading
                propspassed.updateReportName(reportName);
                propspassed.setSelectedColumn(userselectedcolumn);
                setIsButtonDisabled(false);
              }, 1000);
            } else {
              setIsLoading(false); // Stop loading
              setIsButtonDisabled(false);
              setModalOpen(true);
            }
          } catch (error) {
            setIsButtonDisabled(false);
            setModalOpen(true);
          }
        } catch (error) {
          console.log("Error", error);
        }
      } else {
        setloadingdots(true);
        await fetch(`${nodeappUrl}package/reduceCompensatoryReviews`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: propspassed.userId,
            reductionAmount: adjustedTotalReviews,
          }),
        });
        propspassed.setpackagedatareload(propspassed.packagedatareload + 1);
        propspassed.updateReportName(reportName);
        propspassed.setSelectedColumn(userselectedcolumn);
        setIsButtonDisabled(false);
        setloadingdots(false);
      }
    }
  };


  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!reportName.trim()) {
      setReportNameError("Report name cannot be empty");
      return;
    }
  };

  return (
    <div className="call-dropdown-container">
      <div className="alldropdowns-container">
        <form
          className="search-form-d"
          onSubmit={handleFormSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <label>
            Report Name:
            <input
              type="text"
              name="name"
              value={reportName}
              maxLength={50}
              onChange={handleReportNameChange}
              className="search-input-d"
            />
          </label>
          {reportNameError && (
            <div className="error-message">
              <p style={{ fontSize: "12px", color: "#EA7581" }}>
                {reportNameError}
              </p>
            </div>
          )}
        </form>

        {propspassed.columnToGet.map((columnName, index) => (
          <div key={index} className="column-dropdown">
            <CSVHeaderdropdown
              keyvalue={index}
              Required_columns={propspassed.Required_columns}
              headerdata={propspassed.headerdata}
              columnName={columnName}
              setuserselectedcolumn={setuserselectedcolumn}
              Descriptionofcolumn={propspassed.Descriptionofcolumn[index]}
              userselectedcolumn={userselectedcolumn}
            />
          </div>
        ))}

        {userselectedcolumn["Date"] !== undefined &&
          (!processedDates.every((date) => date) ||
            processedDates.some((date) => date === "NaN-NaN-NaN")) && (
            <div>
              <p className="date_column_error">
                The selected column contains dates in an unsupported format.
                Please ensure dates are in either yyyy-mm-dd or dd-mm-yyyy
                format. Proceeding without correcting this will result in the
                unavailability of the "Sentiment Curve" feature.
              </p>
            </div>
          )}

        {userselectedcolumn["Date"] !== undefined &&
          processedDates.every((date) => date && date !== "NaN-NaN-NaN") && (
            <ParentComponent
              csvdata={propspassed.csvdata}
              processedDates={processedDates}
              totalReviews={totalReviews}
              setTotalReviews={setTotalReviews}
              datecolumn={propspassed.headerdata.indexOf(
                userselectedcolumn["Date"]
              )}
              setDates={propspassed.setDates}
              setDateRange={propspassed.setDateRange}
              setmorereviewswarning={setmorereviewswarning}
              setlessreviewswarning={setlessreviewswarning}
            />
          )}

        <div style={{ marginTop: ".7rem" }}>
          <p className="subscriptions_main_info">
            Total reviews in uploaded file:{" "}
            <span className="total-reviews_number">
              {propspassed.csvdata.length}
            </span>
          </p>
          <p
            className="subscriptions_main_info"
            style={{ paddingLeft: "10px" }}
          >
            Maximum reviews allowed:{" "}
            <span className="max_allowed_by_subscription">
              {propspassed.maxReviewsAllowed}
            </span>
          </p>
        </div>
      </div>

      {lessreviewsthanthresold && (
        <div className="warningdiv">
          <p
            className="warnings_main_info"
            style={{ color: "red", fontSize: "15px" }}
          >
            The number of selected reviews must not be less than{" "}
            {propspassed.minReviewsAllowed}.
          </p>
        </div>
      )}

      {lessreviewswarning && (
        <div className="warningdiv">
          <p
            className="warnings_main_info"
            style={{ color: "rgb(9, 155, 4)", fontSize: "15px" }}
          >
            Your current submission includes fewer reviews than your
            subscription allows. Feel free to submit additional reviews to fully
            utilize your subscription benefits.
          </p>
        </div>
      )}

      <div className="Required_fields_div">
        <span style={{ fontSize: "30px" }}>*</span>
        <p> Required fields.</p>
      </div>

      <div className="Analyze_div">
        {isLoadingData ? (
          <div className="form-skeleton">
            <div className="skeleton-input"></div>
          </div>
        ) : userreviewsdata ? (
          // If total_reviews exists, decide which button to display based on the condition
          adjustedTotalReviews >
            userreviewsdata.total_reviews -
            userreviewsdata.reviews_processed +
            CompensatoryReviews ? (
            <>
              {CompensatoryReviews < adjustedTotalReviews &&
                savedCards &&
                savedCards.cardDetails &&
                savedCards.cardDetails.payment_source &&
                savedCards.cardDetails.payment_source.card &&
                createCardElement(savedCards.cardDetails.payment_source.card)}

             
              <div className="package-name-container">
                <Text className="styled-font">Subscribed package name:</Text>
                <Text className="styled-font">
                  {propspassed.userPackageData.packageDetails.name}
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "10px",
                }}
              >
                <Text className="styled-font">Quantity (reviews)</Text>
                <Text className="styled-font">
                  {totalReviews > propspassed.maxReviewsAllowed
                    ? propspassed.maxReviewsAllowed
                    : totalReviews}
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "10px",
                }}
              >
                <Text className="styled-font">Cost per paid review:</Text>
                <Text className="styled-font">
                  £ {propspassed.userPackageData.per_review_price}
                </Text>
              </div>

              {userreviewsdata.total_reviews -
                userreviewsdata.reviews_processed !=
                0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "10px",
                    }}
                  >
                    <Text className="styled-font">Free reviews:</Text>
                    <Text className="styled-font">
                      {userreviewsdata.total_reviews -
                        userreviewsdata.reviews_processed}
                    </Text>
                  </div>
                )}

              {CompensatoryReviews != 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px",
                  }}
                >
                  <Text className="styled-font">Complimentary reviews:</Text>
                  <Text className="styled-font">{CompensatoryReviews}</Text>
                </div>
              )}

              {adjustedTotalReviews >
                CompensatoryReviews +
                (userreviewsdata.total_reviews -
                  userreviewsdata.reviews_processed) ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px",
                  }}
                >
                  <Text className="styled-font" style={{ color: "#364545" }}>
                    Total cost:
                  </Text>
                  <Text className="styled-font" style={{ color: "#364545" }}>
                    £{" "}
                    {(
                      (adjustedTotalReviews -
                        CompensatoryReviews -
                        (userreviewsdata.total_reviews -
                          userreviewsdata.reviews_processed)) *
                      propspassed.userPackageData.per_review_price
                    ).toFixed(2)}
                  </Text>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px",
                  }}
                >
                  <Text className="styled-font" style={{ color: "#364545" }}>
                    Total cost:
                  </Text>
                  <Text className="styled-font" style={{ color: "#364545" }}>
                    £ 0.00
                  </Text>
                </div>
              )}
              {/* </div> */}

              <button
                className="submit-button"
                onClick={handleSubmit}
                disabled={!userselectedcolumn.Reviews || isButtonDisabled}
              >
                Confirm Payment & Analyze Report (£{" "}
                {(
                  (adjustedTotalReviews -
                    (userreviewsdata.total_reviews -
                      userreviewsdata.reviews_processed +
                      CompensatoryReviews)) *
                  propspassed.userPackageData.per_review_price
                ).toFixed(2)}
                )
              </button>
            </>
          ) : (
            <button
              className="submit-button"
              onClick={handleSubmit}
              disabled={
                !userselectedcolumn.Reviews ||
                loadingdots ||
                adjustedTotalReviews === 0 ||
                userreviewsdata.total_reviews -
                userreviewsdata.reviews_processed +
                CompensatoryReviews ===
                0
              }
            >
              {loadingdots ? (
                <span>
                  <span className="loading-dot"></span>
                  <span className="loading-dot"></span>
                  <span className="loading-dot"></span>
                </span>
              ) : (
                `Analyze ${userreviewsdata.total_reviews -
                userreviewsdata.reviews_processed +
                CompensatoryReviews >
                0 &&
                `(You have got ${userreviewsdata.total_reviews -
                userreviewsdata.reviews_processed +
                CompensatoryReviews
                } reviews)`
                }`
              )}
            </button>
          )
        ) : (
          <>
            {CompensatoryReviews < adjustedTotalReviews &&
              savedCards &&
              savedCards.cardDetails &&
              savedCards.cardDetails.payment_source &&
              savedCards.cardDetails.payment_source.card &&
              createCardElement(savedCards.cardDetails.payment_source.card)}

           
            <div className="package-name-container">
              <Text className="styled-font">Subscribed package name:</Text>
              <Text className="styled-font">
                {propspassed.userPackageData.packageDetails.name} 
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px",
              }}
            >
              <Text className="styled-font">Quantity (reviews)</Text>
              <Text className="styled-font">
                {totalReviews > propspassed.maxReviewsAllowed
                  ? propspassed.maxReviewsAllowed
                  : totalReviews}
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px",
              }}
            >
              <Text className="styled-font">Cost per paid review:</Text>
              <Text className="styled-font">
                £ {propspassed.userPackageData.per_review_price}
              </Text>
            </div>

            {CompensatoryReviews != 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "10px",
                }}
              >
                <Text className="styled-font">Complimentary reviews:</Text>
                <Text className="styled-font">{CompensatoryReviews}</Text>
              </div>
            )}

            {adjustedTotalReviews > CompensatoryReviews ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "10px",
                }}
              >
                <Text className="styled-font" style={{ color: "#364545" }}>
                  Total cost:
                </Text>
                <Text className="styled-font" style={{ color: "#364545" }}>
                  £{" "}
                  {(
                    ((totalReviews > propspassed.maxReviewsAllowed
                      ? propspassed.maxReviewsAllowed
                      : totalReviews) -
                      CompensatoryReviews) *
                    propspassed.userPackageData.per_review_price
                  ).toFixed(2)}
                </Text>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "10px",
                }}
              >
                <Text className="styled-font" style={{ color: "#364545" }}>
                  Total cost:
                </Text>
                <Text className="styled-font" style={{ color: "#364545" }}>
                  £ 0.00
                </Text>
              </div>
            )}

            {userreviewsdata && userreviewsdata.total_reviews && (
              <>
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px",
                  }}
                >
                  <Text className="styled-font">Total free reviews:</Text>
                  <Text className="styled-font">
                    {userreviewsdata.total_reviews}
                  </Text>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px",
                  }}
                >
                  <Text className="styled-font">Remaining free reviews:</Text>
                  <Text className="styled-font">
                    {" "}
                    {userreviewsdata.total_reviews -
                      userreviewsdata.reviews_processed}
                  </Text>
                </div>
              </>
            )}

            {totalReviews > CompensatoryReviews ? (
              <button
                className="submit-button"
                onClick={handleSubmit}
                disabled={!userselectedcolumn.Reviews || isButtonDisabled}
              >
                Confirm Payment & Analyze Report (£{" "}
                {(
                  ((totalReviews > propspassed.maxReviewsAllowed
                    ? propspassed.maxReviewsAllowed
                    : totalReviews) -
                    CompensatoryReviews) *
                  propspassed.userPackageData.per_review_price
                ).toFixed(2)}
                )
              </button>
            ) : (
              <button
                className="submit-button"
                onClick={handleSubmit}
                disabled={!userselectedcolumn.Reviews || loadingdots}
              >
                {loadingdots ? (
                  <span>
                    <span className="loading-dot"></span>
                    <span className="loading-dot"></span>
                    <span className="loading-dot"></span>
                  </span>
                ) : (
                  `Analyze (You have got ${CompensatoryReviews} complimentary
                  reviews)`
                )}
              </button>
            )}
          </>
        )}
      </div>
      {show3DSModal && (
        popupBlocked ?
          <ErrorModal
            message={errorMessagePopup}
            setShow3DSModal={setShow3DSModal}
            setPopupBlocked={setPopupBlocked}
            setIsButtonDisabled={setIsButtonDisabled} /> :
          <ErrorModal
            message={
              sessionExpired ? errorMessageSessionExpired : errorMessage3DS
            }
            setShow3DSModal={setShow3DSModal}
            setPopupBlocked={setPopupBlocked}
            setIsButtonDisabled={setIsButtonDisabled}
          />
      )}

      {modalOpen && (
        <>
          <div className="modal">
            <div className="modal-overlay"></div>
            <div className="modal-content-pay">
              <div className="heading-container">
                <p className="heading-text m-0" style={{ color: "#364545" }}>
                  Error Occured
                </p>
                <div className="ml-2 mt-1">
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    style={{ fontSize: "20px", color: "#C41E3A" }}
                  />
                </div>
              </div>
              <hr />
              <p style={{ fontSize: "17px", color: "#364545" }}>
                The payment could not be completed. This may be due to
                incomplete or incorrect payment details, or insufficient funds.
                Please review your payment details and attempt the transaction
                again.
              </p>

              <form onSubmit={handleCloseModal}>
                <div className="button-group">
                  <button
                    type="submit"
                    onClick={handleCloseModal}
                    style={{ backgroundColor: "#880808" }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      window.localStorage.setItem("accessedFromModal", "true");
                      window.open(
                        "/editaccount?option=PaymentDetails",
                        "_blank"
                      );
                      handleCloseModal();
                    }}
                  >
                    Edit Payment Details
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}

      {isLoading && !showToast && (
        <>
          <div className="modal">
            <div className="modal-overlay"></div>
            <div className="modal-content-pay">
              <div className="heading-container">
                <p className="heading-text">Payment Status</p>
              </div>
              <hr />

              {checkoutSuccess ? (
                <>
                  <div className="loading-container">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ fontSize: "48px", color: "green" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Payment successful!
                  </div>
                </>
              ) : (
                <>
                  <div className="loading-container">
                    <Spin size="large" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Payment in progress...
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {FreeagentmodalOpen && !showToast && (
        <>
          <div className="modal">
            <div className="modal-overlay"></div>
            <div className="modal-content-pay">
              <div className="heading-container">
                <p className="heading-text">Billing Details</p>
                <button
                  className="close-modal-button"
                  onClick={() => {
                    setFreeagentModalOpen(false);
                    setIsButtonDisabled(false);
                  }} // Close the modal
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{
                      fontSize: "20px",
                      color: "#364545",
                      marginTop: "3px",
                    }}
                  />
                </button>
              </div>
              <hr />
              <p style={{ fontSize: "17px", color: "#364545" }}>
                {FreeagentmodalMessage}
              </p>
              <div className="checkbox-container">
                <label>
                  <input type="checkbox" onChange={handleCheckboxChange} />
                  Don't show this message again
                </label>
              </div>

              <form onSubmit={handleModalSubmit}>
                <div className="button-group">
                  <button
                    type="button"
                    onClick={() => {
                      window.localStorage.setItem("accessedFromModal", "true");
                      window.open(
                        "/editaccount?option=BillingDetails",
                        "_blank"
                      );
                    }}
                    disabled={isLoading_freeagent}
                  >
                    Edit Billing Details
                  </button>
                  <button type="submit" disabled={isLoading_freeagent}>
                    Proceed
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CallDropdown;
