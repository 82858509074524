  export const verify3DS = async () => {
    return new Promise((resolve, reject) => {
    try {
      // Open the new tab with the redirectPageUrl
      const redirectPageUrl = `${window.location.origin}/pie/verify3ds`;
      const verifyRedirect =redirectPageUrl && window.open(redirectPageUrl, "_blank");

      // if (verifyRedirect) {
        if (!verifyRedirect) {
          console.error("Failed to open the verification tab due to popup blocker.");
          reject({ status: "failed"});
          return;
        }
        const tabClosedChecker = setInterval(() => {
          if (verifyRedirect.closed) {
            clearInterval(tabClosedChecker);
            console.error("User closed the verification tab.");
            reject({ status: "closed" });
          }
          else {
            console.log('object')
          }
        }, 500);
        const messageListener = (event: { data: string; }) => {
          if (event.data === "actionComplete") {
            console.log("Received actionComplete message from new tab");
            clearInterval(tabClosedChecker); 
            verifyRedirect.close(); 
            window.removeEventListener("message", messageListener); // Clean up listener
            resolve({ status: "verified" });
          }
          else if (event.data === "actionCancelled") {
            console.log("Received actionCancelled message from new tab");
            clearInterval(tabClosedChecker);
            verifyRedirect.close(); 
            window.removeEventListener("message", messageListener); // Clean up listener
            resolve({ status: "cancelled" });
          }
        };

        window.addEventListener("message", messageListener);

      // }  else {
      //   reject({ status: "failed" });
      //   console.log('Else part in verify.ts')
      // }
      
    } catch (error) {
      console.error("Error handling 3D Secure verification:", error);
      reject({ status: "error" });
    }
})
}
