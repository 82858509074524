// Modified function to separate date and time from an array of dateTime strings
export function separateDateTimeArray(dateTimeStrings) {
  return dateTimeStrings.map((dateTimeString) => {
    // Check if dateTimeString is defined
    if (!dateTimeString) {
      return { date: "", time: "" };
    }

    let datePart, timePart;

    if (dateTimeString.includes(" ")) {
      [datePart, timePart] = dateTimeString.split(" ");
    } else if (dateTimeString.includes("T")) {
      [datePart, timePart] = dateTimeString.split("T");
    } else {
      datePart = dateTimeString;
      timePart = "";
    }

    return { date: datePart, time: timePart };
  });
}

export function parseDateArray(dateStrings) {
  let yearAtStart = false;
  let dayIndex; // This will be determined based on the values in the date strings

  // Determine if year is at the start in the first non-null string
  if (dateStrings.length > 0 && dateStrings[0].split(/[-\/]/)[0].length === 4) {
    yearAtStart = true;
  }

  // Check for a value greater than 12 in either potential day or month field to identify the day field
  for (let dateString of dateStrings) {
    const parts = dateString.split(/[-\/]/);
    if (parts.length === 3) {
      // Check first two parts if year is at the start, otherwise check last two parts
      const indexToCheck = yearAtStart ? 1 : 0;
      if (
        parseInt(parts[indexToCheck]) > 12 ||
        parseInt(parts[indexToCheck + 1]) > 12
      ) {
        dayIndex =
          parseInt(parts[indexToCheck]) > 12 ? indexToCheck : indexToCheck + 1;
        break;
      }
    }
  }

  // If dayIndex is still undefined, default to assuming MM-DD-YYYY or YYYY-MM-DD format
  if (dayIndex === undefined) {
    dayIndex = yearAtStart ? 2 : 1;
  }

  // Parse dates
  return dateStrings.map((dateString) => {
    if (!dateString) {
      return false;
    }

    const dateParts = dateString.split(/[-\/]/);
    if (dateParts.length !== 3) {
      return false;
    }

    // Adjust for two-digit years
    let year = parseInt(dateParts[yearAtStart ? 0 : 2]);
    if (year < 100) {
      year += 2000; // Assuming the year is in the 21st century
    }

    let date;
    if (yearAtStart) {
      // Determine the month index based on the identified day index
      const monthIndex = dayIndex === 1 ? 2 : 1;
      date = new Date(year, dateParts[monthIndex] - 1, dateParts[dayIndex]);
    } else {
      const monthIndex = dayIndex === 0 ? 1 : 0;
      date = new Date(year, dateParts[monthIndex] - 1, dateParts[dayIndex]);
    }

    return formatDateToYYYYMMDD(date);
  });
}

export function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function countDatesInRange(datesArray, startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return datesArray.filter((dateString) => {
    const date = new Date(dateString);
    return date >= start && date <= end;
  }).length;
}

// Assuming `dataArray` is your array with data
export function processData(dataArray) {
  // Processing date and time in bulk
  let dateTimeParts = separateDateTimeArray(dataArray);

  let dates = parseDateArray(dateTimeParts.map((part) => part.date));

  let sorteddates = dates.slice();
  sorteddates.sort((a, b) => new Date(a) - new Date(b));

  return { dates, sorteddates };
}
