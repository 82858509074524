import { useEffect, useState } from "react";
import Dashboard from "../../components/FindPerceptionBlock/Dashboard.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CryptoJS from "crypto-js";
import { Spin } from "antd";
import ShareableLinkModal from "../../components/FindPerceptionBlock/ShareableLinkModal.jsx";
import { faShareFromSquare } from "@fortawesome/free-solid-svg-icons";
import("./index.css");

const ReportViewPage = () => {
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [reportName, setreportName] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");
  const [userId, setUserId] = useState(null);

  const [reportAvailable, setReportAvailable] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const storedReportData = sessionStorage.getItem("reportData");
    if (storedReportData) {
      const parsedReportData = JSON.parse(storedReportData); // Parse the JSON string
      setSelectedReportId(parsedReportData.reportId);
      setreportName(parsedReportData.reportName);
    }
  }, []);

  useEffect(() => {
    // Define the async function inside the useEffect
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}decode/decodetoken`,
          {
            method: "POST",
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user ID");
        }

        const data = await response.json();

        if (data.userId !== userId) {
          setUserId(data.userId);
        }

        // Check if reportId is available
        if (selectedReportId) {
          // Call reportExists route
          const reportExistsResponse = await fetch(
            `${process.env.REACT_APP_NODE_SERVER_URL}report/reportExists`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                report_id: selectedReportId,
                user_id: data.userId,
              }),
            }
          );

          if (!reportExistsResponse.ok) {
            throw new Error("Failed to check report association");
          }

          const reportExistsData = await reportExistsResponse.json();
          // Handle the response from the reportExists route
          if (reportExistsData.status === "associated") {
            setReportAvailable(true);
          } else {
            setReportAvailable(false);
            setTimeout(() => {}, 2000);
          }
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error during fetching or report check", error);
        setIsLoading(false);
      }
    };

    // Call the async function
    fetchData();
  }, [selectedReportId]); // Add selectedReportId and userId to the dependency array

  const encryptReportId = async (reportId) => {
    try {
      const secretName = process.env.REACT_APP_Crypto_Secret_Key;
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}variable/getkeyvaultvalue`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ secretName }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      const encrypted = CryptoJS.AES.encrypt(
        reportId.toString(),
        data.secretValue
      ).toString();
      // URL encode the encrypted string to make it URL-safe
      return encodeURIComponent(encrypted);
    } catch (error) {
      console.error("Error initializing PayPal SDK:", error);
    }
  };

  const createShareableLink = async (reportId) => {
    const encryptedId = await encryptReportId(reportId);
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const shareableLink = `${baseUrl}/reports/${encryptedId}`;
    setGeneratedLink(shareableLink);
    setIsModalVisible(true); // Show the modal
    // Copying the link to the clipboard
    navigator.clipboard.writeText(shareableLink).then(
      () => {},
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  return (
    <>
      <div className="main_div_reports_page">
        <div>
          {isLoading ? (
            <div className="centered-loader_report_tab">
              <Spin size="large" />
            </div>
          ) : reportAvailable ? (
            selectedReportId &&
            userId && (
              <div>
                <div className="share-button-2 view_report_name">
                  <h3
                    style={{
                      fontSize: "18px",
                      color: "#4E4E4E",
                      fontFamily: "sans-serif",
                      fontWeight: "bolder",
                    }}
                    key={selectedReportId}
                  >
                    Report Name:{" "}
                    {reportName && reportName.length > 35
                      ? reportName.slice(0, 35) + "..."
                      : reportName}
                  </h3>

                  <button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents the click from propagating to the document
                      createShareableLink(selectedReportId);
                    }}
                  >
                    Share Report <FontAwesomeIcon icon={faShareFromSquare} />
                  </button>
                </div>
                <Dashboard
                  selecteduserid={userId}
                  selectedReportId={selectedReportId}
                />
              </div>
            )
          ) : (
            <div
              className="Dash-Cont"
              style={{ marginTop: "10rem", width: "100%" }}
            >
              <div className="no-reports">Report not available.</div>
            </div>
          )}

          <ShareableLinkModal
            isVisible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            link={generatedLink}
          />
        </div>
      </div>
    </>
  );
};

export default ReportViewPage;
