import "./style.css";

const SecuritypolicyDeclaration = () => {
  return (
    <>
      <div className="policies_maindiv">
        <div className="cookie-policy-maindiv">
          <p style={{ fontSize: "25px", color: "#364545", fontWeight: "bold" }}>
            Data Protection and Data Security Policy
          </p>
        </div>
        <div className="cookie-policy-maindivs">
          <div class="cookie-table-container">
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Statement and purpose of policy
            </p>

            <p>
              <strong>A.</strong> Resilient Technologies Ltd is committed to
              ensuring that all personal data handled by us will be processed
              according to legally compliant standards of data protection and
              data security.
            </p>
            <p>
              <strong>B.</strong> We confirm for the purposes of the data
              protection laws, that Resilient Technologies Ltd is a data
              controller of the personal data in connection with our employees,
              customers, suppliers and any third parties. This means that we
              determine the purposes for which, and the manner in which, the
              personal data is processed.
            </p>
            <p>
              <strong>C.</strong> The purpose of this policy is to help us
              achieve our data protection and data security aims by:
            </p>
            <ol>
              <li>
                notifying our staff of the types of personal information that we
                may hold about them, our customers, suppliers and other third
                parties and what we do with that information;
              </li>
              <li>
                setting out the rules on data protection and the legal
                conditions that must be satisfied when we collect, receive,
                handle, process, transfer and store personal data and ensuring
                staff understand our rules and the legal standards;
              </li>
              <li>
                clarifying the responsibilities and duties of staff in respect
                of data protection and data security.
              </li>
            </ol>
            <p>
              <strong>D.</strong> This is a statement of policy only and does
              not form part of any contract. We may amend this policy at any
              time, in our absolute discretion.
            </p>
            <p>
              <strong>E.</strong> For the purposes of this policy:
            </p>
            <ol>
              <li>
                Criminal records data means information about an individual's
                criminal convictions and offences, and information relating to
                criminal allegations and proceedings.
              </li>
              <li>
                Data protection laws means all applicable laws relating to the
                processing of personal data, including, for the period during
                which it is in force, the UK General Data Protection Regulation.
              </li>
              <li>
                Data subject means the individual to whom the personal data
                relates.
              </li>
              <li>
                Personal data means any information that relates to an
                individual who can be identified from that information.
              </li>
              <li>
                Processing means any use that is made of data, including
                collecting, storing, amending, disclosing, or destroying it.
              </li>
              <li>
                Special categories of personal data means information about an
                individual’s racial or ethnic origin, political opinions,
                religious or philosophical beliefs, trade union membership,
                health, sex life or sexual orientation and biometric data.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Data protection principles
            </p>

            <p>
              1. Staff whose work involves using personal data relating to Staff
              or others must comply with this policy and with the following data
              protection principles which require that personal information is:
            </p>
            <ol type="a">
              <li>
                <strong>
                  processed lawfully, fairly and in a transparent manner.
                </strong>{" "}
                We must always have a lawful basis to process personal data, as
                set out in the data protection laws. Personal data may be
                processed as necessary to perform a contract with the data
                subject, to comply with a legal obligation which the data
                controller is the subject of, or for the legitimate interest of
                the data controller or the party to whom the data is disclosed.
                The data subject must be told who controls the information (us),
                the purpose(s) for which we are processing the information and
                to whom it may be disclosed.
              </li>
              <li>
                <strong>
                  collected only for specified, explicit and legitimate
                  purposes.
                </strong>{" "}
                Personal data must not be collected for one purpose and then
                used for another. If we want to change the way we use personal
                data, we must first tell the data subject.
              </li>
              <li>
                <strong>
                  processed only where it is adequate, relevant and limited to
                  what is necessary for the purposes of processing.
                </strong>{" "}
                We will only collect personal data to the extent required for
                the specific purpose notified to the data subject.
              </li>
              <li>
                <strong>
                  accurate and the Employer takes all reasonable steps to ensure
                  that information that is inaccurate is rectified or deleted
                  without delay.
                </strong>{" "}
                Checks to personal data will be made when collected and regular
                checks must be made afterwards. We will make reasonable efforts
                to rectify or erase inaccurate information.
              </li>
              <li>
                <strong>
                  kept only for the period necessary for processing.
                </strong>{" "}
                Information will not be kept longer than it is needed and we
                will take all reasonable steps to delete information when we no
                longer need it. For guidance on how long particular information
                should be kept, contact the founder(s).
              </li>
              <li>
                <strong>
                  secure, and appropriate measures are adopted by the Employer
                  to ensure as such.
                </strong>
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Who is responsible for data protection and data security?
            </p>

            <ol>
              <li>
                Maintaining appropriate standards of data protection and data
                security is a collective task shared between us and you. This
                policy and the rules contained in it apply to all staff of the
                Employer, irrespective of seniority, tenure and working hours,
                including all employees, directors and officers, consultants and
                contractors, casual or agency staff, trainees, homeworkers and
                fixed-term staff and any volunteers (<strong>Staff</strong>).
              </li>
              <li>
                Questions about this policy, or requests for further
                information, should be directed to the founder(s) of the
                company.
              </li>
              <li>
                All Staff have personal responsibility to ensure compliance with
                this policy, to handle all personal data consistently with the
                principles set out here and to ensure that measures are taken to
                protect the data security. Managers have special responsibility
                for leading by example and monitoring and enforcing compliance.
                The founder must be notified if this policy has not been
                followed, or if it is suspected this policy has not been
                followed, as soon as reasonably practicable.
              </li>
              <li>
                Any breach of this policy will be taken seriously and may result
                in disciplinary action up to and including dismissal.
                Significant or deliberate breaches, such as accessing Staff or
                customer personal data without authorisation or a legitimate
                reason to do so, may constitute gross misconduct and could lead
                to dismissal without notice.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              What personal data and activities are covered by this policy?
            </p>

            <ol>
              <li>
                This policy covers personal data:
                <ol type="a">
                  <li>
                    which relates to a natural living individual who can be
                    identified either from that information in isolation or by
                    reading it together with other information we possess;
                  </li>
                  <li>
                    is stored electronically or on paper in a filing system;
                  </li>
                  <li>
                    in the form of statements of opinion as well as facts;
                  </li>
                  <li>
                    which relates to Staff (present, past or future) or to any
                    other individual whose personal data we handle or control;
                  </li>
                  <li>
                    which we obtain, is provided to us, which we hold or store,
                    organise, disclose or transfer, amend, retrieve, use,
                    handle, process, transport or destroy.
                  </li>
                </ol>
              </li>
              <li>
                This personal data is subject to the legal safeguards set out in
                the data protection laws.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              What personal data do we process about Staff?
            </p>

            <ol>
              <li>
                We collect personal data about you which:
                <ol type="a">
                  <li>
                    you provide or we gather before or during your employment or
                    engagement with us;
                  </li>
                  <li>
                    is provided by third parties, such as references or
                    information from suppliers or another party that we do
                    business with; or
                  </li>
                  <li>is in the public domain.</li>
                </ol>
              </li>
              <li>
                The types of personal data that we may collect, store and use
                about you include records relating to your:
                <ol type="a">
                  <li>
                    home address, contact details and contact details for your
                    next of kin;
                  </li>
                  <li>
                    recruitment (including your application form or curriculum
                    vitae, references received and details of your
                    qualifications);
                  </li>
                  <li>
                    pay records, national insurance number and details of taxes
                    and any employment benefits such as pension and health
                    insurance (including details of any claims made);
                  </li>
                  <li>
                    telephone, email, internet, fax or instant messenger use;
                  </li>
                  <li>
                    performance and any disciplinary matters, grievances,
                    complaints or concerns in which you are involved.
                  </li>
                </ol>
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Sensitive personal data
            </p>

            <ol>
              <li>
                We may from time to time need to process sensitive personal
                information (sometimes referred to as 'Special categories of
                personal data').
              </li>
              <li>
                We will only process sensitive personal information if:
                <ol type="a">
                  <li>
                    we have a lawful basis for doing so, e.g., it is necessary
                    for the performance of the employment contract; and
                  </li>
                  <li>
                    one of the following special conditions for processing
                    personal information applies:
                    <ol type="i">
                      <li>the data subject has given explicit consent.</li>
                      <li>
                        the processing is necessary for the purposes of
                        exercising the employment law rights or obligations of
                        the Company or the data subject.
                      </li>
                      <li>
                        the processing is necessary to protect the data
                        subject's vital interests, and the data subject is
                        physically incapable of giving consent.
                      </li>
                      <li>
                        the processing relates to personal data which are
                        manifestly made public by the data subject.
                      </li>
                      <li>
                        the processing is necessary for the establishment,
                        exercise, or defence or legal claims; or
                      </li>
                      <li>
                        the processing is necessary for reasons of substantial
                        public interest.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                Before processing any sensitive personal information, Staff must
                notify the founder of the proposed processing, in order for the
                founder to assess whether the processing complies with the
                criteria noted above.
              </li>
              <li>
                Sensitive personal information will not be processed until the
                assessment above has taken place and the individual has been
                properly informed of the nature of the processing, the purposes
                for which it is being carried out and the legal basis for it.
              </li>
              <li>
                Our Privacy Notice sets out the type of sensitive personal
                information that we process, what it is used for and the lawful
                basis for the processing.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Criminal records information
            </p>
            <ol>
              <li>
                Criminal records information will be processed in accordance
                with our Criminal Records Information Policy.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              How we use personal data of staff, customers, suppliers and third
              parties
            </p>

            <p>
              1. We will tell you the reasons for processing your personal data,
              how we use such information and the legal basis for processing in
              our Privacy Notice. We will not process Staff personal information
              for any other reason.
            </p>
            <p>
              2. In general, we will use information to carry out our business,
              to administer employment or engagement and to deal with any
              problems or concerns you may have, including, but not limited to:
            </p>
            <ul>
              <li>
                <strong>Customer lists:</strong> to contact you for
                consultation, deliver a service, provide access to our products
                or other customer account management activities.
              </li>
              <li>
                <strong>Staff address lists:</strong> to compile and circulate
                lists of home addresses and contact details, to contact you
                outside working hours.
              </li>
              <li>
                <strong>Sickness records:</strong> to maintain a record of your
                sickness absence and copies of any doctor's notes or other
                documents supplied to us in connection with your health, to
                inform your colleagues and others that you are absent through
                sickness, as reasonably necessary to manage your absence, to
                deal with unacceptably high or suspicious sickness absence, to
                inform reviewers for appraisal purposes of your sickness absence
                level, to publish internally aggregated, anonymous details of
                sickness absence levels.
              </li>
              <li>
                <strong>Monitoring IT systems:</strong> to monitor your use of
                e-mails, internet, telephone and fax, computer or other
                communications or IT resources.
              </li>
              <li>
                <strong>Disciplinary, grievance or legal matters:</strong> in
                connection with any disciplinary, grievance, legal, regulatory
                or compliance matters or proceedings that may involve you.
              </li>
              <li>
                <strong>Performance reviews:</strong> to carry out performance
                reviews.
              </li>
              <li>
                <strong>Equal opportunities monitoring:</strong> to conduct
                monitoring for equal opportunities purposes and to publish
                anonymised, aggregated information about the breakdown of the
                Employer's workforce.
              </li>
            </ul>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Accuracy and relevance
            </p>
            <ol>
              <li>
                We will:
                <ol type="a">
                  <li>
                    ensure that any personal data processed is up to date,
                    accurate, adequate, relevant and not excessive, given the
                    purpose for which it was collected.
                  </li>
                  <li>
                    not process personal data obtained for one purpose for any
                    other purpose, unless you agree to this or reasonably expect
                    this.
                  </li>
                </ol>
              </li>
              <li>
                If you consider that any information held about you is
                inaccurate or out of date, then you should tell the founder. If
                they agree that the information is inaccurate or out of date,
                then they will correct it promptly. If they do not agree with
                the correction, then they will note your comments.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Storage and retention
            </p>
            <ol>
              <li>
                Personal data (and sensitive personal information) will be kept
                securely in accordance with our Data Security Policy.
              </li>
              <li>
                The periods for which we hold personal data are contained in our
                Privacy Notices.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Individual rights
            </p>

            <ol>
              <li>
                You have the following rights in relation to your personal data:
              </li>
              <li>Subject access requests:</li>
              <ol type="a">
                <li>
                  You have the right to make a subject access request. If you
                  make a subject access request, we will tell you:
                  <ol type="i">
                    <li>
                      whether or not your personal data is processed and if so
                      why, the categories of personal data concerned and the
                      source of the data if it is not collected from you;
                    </li>
                    <li>
                      to whom your personal data is or may be disclosed,
                      including to recipients outside of the UK or European
                      Economic Area (EEA) and the safeguards that apply to such
                      transfers;
                    </li>
                    <li>
                      how long your personal data is stored (or how that period
                      is decided);
                    </li>
                    <li>
                      your rights of rectification or erasure of data, or to
                      restrict or object to processing;
                    </li>
                    <li>
                      your right to complain to the Information Commissioner if
                      you think we have failed to comply with your data
                      protection rights; and
                    </li>
                    <li>
                      whether or not we carry out automated decision-making and
                      the logic involved in any such decision making.
                    </li>
                  </ol>
                </li>
                <li>
                  We will provide you with a copy of the personal data
                  undergoing processing. This will normally be in electronic
                  form if you have made a request electronically, unless you
                  agree otherwise.
                </li>
                <li>
                  Please contact us using the form on our website to make a
                  subject access request.
                </li>
                <li>
                  We may need to ask for proof of identification before your
                  request can be processed. We will let you know if we need to
                  verify your identity and the documents we require.
                </li>
                <li>
                  We will normally respond to your request within 28 days from
                  the date your request is received. In some cases, e.g. where
                  there is a large amount of personal data being processed, we
                  may respond within 3 months of the date your request is
                  received. We will write to you within 28 days of receiving
                  your original request if this is the case.
                </li>
                <li>
                  If your request is manifestly unfounded or excessive, we are
                  not obliged to comply with it.
                </li>
              </ol>
              <li>Other rights:</li>
              <ol type="a">
                <li>
                  You have a number of other rights in relation to your personal
                  data. You can require us to:
                  <ol type="i">
                    <li>rectify inaccurate data;</li>
                    <li>
                      stop processing or erase data that is no longer necessary
                      for the purposes of processing;
                    </li>
                    <li>
                      stop processing or erase data if your interests override
                      our legitimate grounds for processing the data (where we
                      rely on our legitimate interests as a reason for
                      processing data);
                    </li>
                    <li>
                      stop processing data for a period if data is inaccurate or
                      if there is a dispute about whether or not your interests
                      override the Employer's legitimate grounds for processing
                      the data.
                    </li>
                  </ol>
                </li>
                <li>
                  To request that we take any of these steps, please send the
                  request through the contact us form on our website.
                </li>
              </ol>
            </ol>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Data security
            </p>
            <ol>
              <li>
                We will use appropriate technical and organisational measures to
                keep personal data secure, and in particular to protect against
                unauthorised or unlawful processing and against accidental loss,
                destruction or damage.
              </li>
              <li>
                Maintaining data security means making sure that:
                <ol type="a">
                  <li>
                    only people who are authorised to use the information can
                    access it;
                  </li>
                  <li>
                    where possible, personal data is pseudonymised or encrypted;
                  </li>
                  <li>
                    information is accurate and suitable for the purpose for
                    which it is processed; and
                  </li>
                  <li>
                    authorised persons can access information if they need it
                    for authorised purposes.
                  </li>
                </ol>
              </li>
              <li>
                By law, we must use procedures and technology to secure personal
                information throughout the period that we hold or control it,
                from obtaining to destroying the information.
              </li>
              <li>
                Personal information must not be transferred to any person to
                process (e.g., while performing services for us or on our
                behalf), unless that person has either agreed to comply with our
                data security procedures or we are satisfied that other adequate
                measures exist.
              </li>
              <li>
                Security procedures include:
                <ol type="a">
                  <li>
                    Any desk or cupboard containing confidential information
                    must be kept locked.
                  </li>
                  <li>
                    Computers should be locked with a strong password that is
                    changed regularly or shut down when they are left unattended
                    and discretion should be used when viewing personal
                    information on a monitor to ensure that it is not visible to
                    others. Where possible, multi-factor authentication should
                    be used.
                  </li>
                  <li>
                    Make sure strong passwords are used on smartphones, laptops,
                    tablets, email accounts and any other devices or accounts
                    where personal information is stored. They must be difficult
                    to guess. Where possible, multi-factor authentication should
                    be used.
                  </li>
                  <li>
                    Take care when sharing your screen in virtual meetings as it
                    might show your device to others exactly as you see it,
                    including any open tabs or documents. Before sharing your
                    screen, close anything you don't need and make sure the
                    notifications and pop-up alerts are switched off.
                  </li>
                  <li>
                    Install anti-virus and malware protection and keep it
                    up-to-date.
                  </li>
                  <li>
                    Be aware of your surroundings: For example, if you're on a
                    train or in a shared workspace, other people may be able to
                    see your screen. A privacy screen might help you.
                  </li>
                  <li>
                    Data stored on CDs or memory sticks must be encrypted or
                    password protected and locked away securely when they are
                    not being used.
                  </li>
                  <li>
                    The founder(s) must approve of any cloud used to store data.
                  </li>
                  <li>
                    Data should never be saved directly to mobile devices such
                    as laptops, tablets or smartphones.
                  </li>
                  <li>
                    All servers containing sensitive personal data must be
                    approved and protected by security software.
                  </li>
                </ol>
                <ol type="a" start="11">
                  <li>
                    Servers containing personal data must be kept in a secure
                    location, away from general office space.
                  </li>
                  <li>
                    Data should be regularly backed up in line with the
                    Employer's back-up procedure.
                  </li>
                  <li>Make sure your Wi-Fi connection is secure.</li>
                </ol>
              </li>
            </ol>
            <ol start="6">
              <li>
                Be wary of suspicious emails: Be diligent to spot suspicious
                emails. Look out for signs such as bad grammar, demands for you
                to act urgently and requests for payment. New technologies mean
                that email attacks are becoming more sophisticated. A phishing
                email could appear to come from a source you recognise. If
                you're not sure, speak to the sender.
              </li>
              <li>
                Telephone precautions: Particular care must be taken by Staff
                who deal with telephone enquiries to avoid inappropriate
                disclosures. In particular: the identity of any telephone caller
                must be verified before any personal information is disclosed;
                if the caller's identity cannot be verified satisfactorily then
                they should be asked to put their query in writing do not allow
                callers to bully you into disclosing information. In case of any
                problems or uncertainty, contact the founder(s).
              </li>
              <li>We won't keep data for longer than it's needed.</li>
              <li>
                Methods of disposal: Copies of personal information, whether on
                paper or on any physical storage devices, must be physically
                destroyed when they are no longer needed. Paper documents should
                be shredded and CDs or memory sticks or similar must be rendered
                permanently unreadable. No personal data should be left on
                computers, laptops, smartphones or any other devices, before
                they are disposed of.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Data impact assessments
            </p>
            <ol>
              <li>
                Some of the processing that the company carries out may result
                in risks to privacy.
              </li>
              <li>
                Where processing would result in a high risk to the rights and
                freedoms of individuals, the company will carry out a data
                protection impact assessment to determine the necessity and
                proportionality of processing. This will include considering the
                purposes for which the activity is carried out, the risks for
                individuals and the measures that can be put in place to
                mitigate those risks.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Data breaches
            </p>
            <ol>
              <li>
                If we discover that there has been a breach of personal data
                that poses a risk to the rights and freedoms of individuals, we
                will report it to the Information Commissioner within 72 hours
                of discovery.
              </li>
              <li>
                We will record all data breaches regardless of their effect in
                accordance with our Breach Response Policy.
              </li>
              <li>
                If the breach is likely to result in a high risk to your rights
                and freedoms, we will tell affected individuals that there has
                been a breach and provide them with more information about its
                likely consequences and the mitigation measures it has taken.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              International data transfers
            </p>
            <ol>
              <li>
                In the course of carrying out our business, we may need to
                transfer your personal information to a country outside the UK
                or European Economic Area (EEA) including to any group company
                or to another person with whom we have a business relationship.
              </li>
              <li>
                Your personal data will only be transferred to a country outside
                of the UK or EEA if there are adequate protections in place. To
                ensure that your personal data receives an adequate level of
                protection, we have put in place appropriate procedures with the
                third parties we share your personal data with, to ensure your
                personal data is treated by those third parties in a way that is
                consistent with and which respects the law on data protection.
              </li>
              <li>
                If you wish to know more about international transfers of your
                personal data, you may contact the founder(s) using the contact
                us form on our website.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Individual responsibilities
            </p>
            <ol>
              <li>
                Staff are responsible for helping the Employer keep their
                personal data up to date.
              </li>
              <li>
                Staff should let the Employer know if personal data provided to
                the Employer changes, e.g., if you move house or change your
                bank details.
              </li>
              <li>
                You may have access to the personal data of other Staff members
                and of our customers in the course of your employment. Where
                this is the case, the Employer relies on Staff members to help
                meet its data protection obligations to Staff and to customers.
              </li>
              <li>
                Individuals who have access to personal data are required:
                <ol type="a">
                  <li>
                    to access only personal data that they have authority to
                    access and only for authorised purposes;
                  </li>
                  <li>
                    not to disclose personal data except to individuals (whether
                    inside or outside of the Employer) who have appropriate
                    authorisation;
                  </li>
                  <li>
                    to keep personal data secure (e.g., by complying with rules
                    on access to premises, computer access, including password
                    protection, and secure file storage and destruction);
                  </li>
                  <li>
                    not to remove personal data, or devices containing or that
                    can be used to access personal data, from the Employer’s
                    premises without adopting appropriate security measures
                    (such as encryption or password protection) to secure the
                    data and the device; and
                  </li>
                  <li>
                    not to store personal data on local drives or on personal
                    devices that are used for work purposes.
                  </li>
                  <li>
                    adhere to ‘Personal Data Security Policy’ which is focused
                    on minimising the risk of personal data breaches.
                  </li>
                </ol>
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Training
            </p>
            <ol>
              <li>
                We will provide training to all individuals about their data
                protection responsibilities as part of the induction process and
                at regular intervals thereafter.
              </li>
              <li>
                Individuals whose roles require regular access to personal data,
                or who are responsible for implementing this policy or
                responding to subject access requests under this policy will
                receive additional training to help them understand their duties
                and how to comply with them.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecuritypolicyDeclaration;
