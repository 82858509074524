import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import PersonalDetails from "./EditPersonaldetails/index";
import LoginDetails from "./Editlogindetails";
import PackageSubscribed from "./Editpackagedetails";
import PayPalComponent from "./Editpaymentdetails";
import Editbillingdetails from "./EditBillingdetails";
import { Spin } from "antd";
import "./style.css"; // Ensure this file contains the necessary CSS

const AccountEditDetails = () => {
  const [userId, setUserId] = useState(null);
  const [disabledBack, setDisabledBack] = useState(false);
  const [loading, setLoading] = useState(true); // Add a loading state
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const providedOption = queryParams.get("option");

  useEffect(() => {
    const accessedFromModal = window.localStorage.getItem("accessedFromModal");
    if (accessedFromModal === "true") {
      setDisabledBack(true);
      window.localStorage.removeItem("accessedFromModal");
    }
  }, []);

  useEffect(() => {
    // Define the async function inside the useEffect
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}decode/decodetoken`,
          {
            method: "POST",
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user ID");
        }

        const data = await response.json();
        if (data.userId != userId) {
          setUserId(data.userId);
        }
      } catch (error) {
        console.error("Failed to load user ID", error);
      } finally {
        setLoading(false); // Set loading to false after fetching data
      }
    };

    // Call the async function
    fetchData();
  }, []); // Dependency array is empty to ensure this effect runs once on mount

  const [selectedOption, setSelectedOption] = useState("PersonalDetails");

  // Function to handle "Go Back" action
  const handleGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    if (providedOption) {
      setSelectedOption(providedOption);
    }
  }, [providedOption]);

  // Function to render the selected component
  const renderSelectedComponent = () => {
    if (loading) {
      // Show centered loading indicator
      return (
        <div className="centered-loader">
          <Spin size="large" />
        </div>
      );
    }

    switch (selectedOption) {
      case "PersonalDetails":
        return <PersonalDetails userId={userId} />;
      case "LoginDetails":
        return <LoginDetails userId={userId} />;
      case "PackageSubscribed":
        return <PackageSubscribed userId={userId} />;
      case "PaymentDetails":
        return <PayPalComponent userId={userId} />;
      case "BillingDetails":
        return <Editbillingdetails userId={userId} />;
      default:
        return <PersonalDetails userId={userId} />;
    }
  };

  return (
    <div>
      <div>
        <div
          className="main_div_editaccount"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="back-arrow-div">
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={handleGoBack}
              className={`back-icon ${disabledBack ? 'disabled-back' : ''}`}
            />
          </div>
          <div className="options-container">
            <div
              className={`option ${selectedOption === "PersonalDetails" ? "active" : "inactive"
                }`}
              onClick={() => setSelectedOption("PersonalDetails")}
            >
              Personal Details
            </div>
            <div
              className={`option ${selectedOption === "LoginDetails" ? "active" : "inactive"
                }`}
              onClick={() => setSelectedOption("LoginDetails")}
            >
              Login Details
            </div>
            <div
              className={`option ${selectedOption === "PackageSubscribed" ? "active" : "inactive"
                }`}
              onClick={() => setSelectedOption("PackageSubscribed")}
            >
              Package Details
            </div>
            <div
              className={`option ${selectedOption === "PaymentDetails" ? "active" : "inactive"
                }`}
              onClick={() => setSelectedOption("PaymentDetails")}
            >
              Payment Details
            </div>
            <div
              className={`option ${selectedOption === "BillingDetails" ? "active" : "inactive"
                }`}
              onClick={() => setSelectedOption("BillingDetails")}
            >
              Billing Details
            </div>
          </div>
        </div>
      </div>
      <div className="content">{renderSelectedComponent()}</div>
    </div>
  );
};

export default AccountEditDetails;
