import React, { useState, useEffect } from "react";
import HistogramChart from "./Histogram.js"; // Ensure this path is correct

const ReportsContainer = ({ reportsdata }) => {
  const [reportsData, setReportsData] = useState([]);

  useEffect(() => {
    const fetchReportsData = async () => {
      try {
        // Extract report_id from each report in the initial data
        const reportIds = reportsdata.map((report) => report.report_id);

        // Make a POST request with the extracted report IDs
        const detailedResponse = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}report/sentimentscores`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ reportIds }), // Adjust this key as needed based on your backend API
          }
        );
        if (!detailedResponse.ok)
          throw new Error("Network response was not ok");
        const detailedData = await detailedResponse.json();

        // Set the detailed data for rendering in the histogram
        setReportsData(detailedData);
      } catch (error) {
        console.error("Failed to fetch reports data:", error);
      }
    };

    fetchReportsData();
  }, [reportsdata]); // Depend on initialReportsData so it updates when the prop changes

  return <HistogramChart reportsdata={reportsData} />;
};

export default ReportsContainer;
