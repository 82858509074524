import "./Preview.css"; // Import the CSS module

const TablePopup = ({ headers, data, onClose }) => {
  // Stops the click from propagating to the background
  const handlePopupClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="popup" onClick={onClose}>
      <div className="popup-inner" onClick={handlePopupClick}>
        <span className="close-btn" onClick={onClose} aria-label="Close popup">
          &times;
        </span>
        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.slice(0, 2).map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TablePopup;
