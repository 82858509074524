export const formatDateAndTime = (datetimeStr) => {
  // Create a Date object, explicitly treating the input as UTC
  const utcDate = new Date(`${datetimeStr}Z`); // Append 'Z' to treat as UTC

  // Convert the UTC time to local time automatically by JavaScript
  const localDate = new Date(utcDate.getTime());

  // Extract the local date and time components
  const date = localDate.toLocaleDateString(); // Local date format
  let hours = localDate.getHours();
  const minutes = localDate.getMinutes().toString().padStart(2, "0");

  // Convert time to 12-hour format with AM/PM
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert hour to 12-hour format and handle 00 as 12

  // Format time as "hh:mm AM/PM"
  const time = `${hours}:${minutes} ${ampm}`;

  return { date, time };
};
