import { useEffect, useState, lazy } from "react";
import { useNavigate } from "react-router-dom";
//import "./App.css";

import Search from "../../components/FindPerceptionBlock/Search";

const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));

const SentimentApp = () => {
  const navigate = useNavigate();

  const [userreviewsdata, setuserreviewsdata] = useState(null);

  const [responseData, setResponseData] = useState(Object);
  const [analysisnotperformed, setanalysisnotperformed] = useState(false);

  const [showsentiment, setShowsentiment] = useState(false);
  const [processingSentiment, setProcessingSentiment] = useState(true);

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // Define the async function inside the useEffect
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}decode/decodetoken`,
          {
            method: "POST",
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user ID");
        }

        const data = await response.json();

        if (data.userId != userId) {
          setUserId(data.userId);
        }
      } catch (error) {
        console.error("Failed to load user ID", error);
      }
    };

    // Call the async function
    fetchData();
  }, []); // Dependency array is empty to ensure this effect runs once on mount

  const FindSentiment = async (parsedData, userSelectedid) => {
    setResponseData([]);
    setShowsentiment(false);
    setProcessingSentiment(true);
    setanalysisnotperformed(false);
  };

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}package/isuserpackage1active`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Include other necessary headers
            },
            body: JSON.stringify({ userId }),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch user data, status: ${response.status}`
          );
        }

        const data = await response.json();

        return data;
      } catch (error) {
        console.error("Error fetching user data:", error);
        return null;
      }
    };

    // Type guard to ensure userId is not null
    if (userId) {
      fetchUserData(userId)
        .then((data) => {
          if (data && data.status === "passed") {
            // Data was fetched successfully and status is "passed"
            setuserreviewsdata(data.data); // Store the data
          } else {
            setuserreviewsdata(null); // Set it to null
          }
        })
        .catch((error) => {
          console.error("Error handling user data:", error);
          setuserreviewsdata(null); // Set it to null in case of an error
        });
    } else {
      console.error("userId is null, skipping fetch.");
    }
  }, [userId]);

  return (
    <>
      {userId != null ? (
        <Container>
          <ScrollToTop />

          <Search
            onSubmit={FindSentiment}
            responseData={responseData}
            analysisnotperformed={analysisnotperformed}
            setanalysisnotperformed={setanalysisnotperformed}
            showsentiment={showsentiment}
            setResponseData={setResponseData}
            pie_user_id={userId}
          />
        </Container>
      ) : (
        navigate("/login")
      )}
    </>
  );
};

export default SentimentApp;
