import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Dashboard from "../../components/FindPerceptionBlock/Dashboard.jsx";
import CryptoJS from "crypto-js";
import { Spin } from "antd";
import "./index.css";
const apiUrl = process.env.REACT_APP_API_URL;

const ReportPage = () => {
  const { id } = useParams(); // This is the encrypted ID from the URL
  const [decryptedId, setDecryptedId] = useState(null);
  const [reportAvailable, setReportAvailable] = useState(null);
  const [reportName, setReportName] = useState("");
  useEffect(() => {
    // Decrypt the report ID
    const decryptId = async (encryptedId) => {
      try {
        const decodedId = decodeURIComponent(encryptedId);

        try {
          const secretName = process.env.REACT_APP_Crypto_Secret_Key;
          const response = await fetch(
            `${process.env.REACT_APP_NODE_SERVER_URL}variable/getkeyvaultvalue`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ secretName }),
            }
          );

          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }

          const data = await response.json();
          const bytes = CryptoJS.AES.decrypt(decodedId, data.secretValue);
          const originalText = bytes.toString(CryptoJS.enc.Utf8);
          return originalText || null;
        } catch (error) {
          console.error("Error initializing PayPal SDK:", error);
        }
      } catch (error) {
        console.error("Decryption failed:", error);
        return null;
      }
    };

    const fetchReport = async () => {
      if (id) {
        const decrypted = await decryptId(id);
        setDecryptedId(decrypted);

        // Fetch the report details
        if (decrypted) {
          axios
            .post(`${apiUrl}/get_report`, { id: decrypted })
            .then((response) => {
              if (response.data.no_views_left > 0) {
                setReportAvailable(true);
                setReportName(response.data.reportname);
              } else {
                setReportAvailable(false);
              }
            })
            .catch((error) => {
              console.error("Error fetching report:", error);
              setReportAvailable(false);
            });
        }
      }
    };

    fetchReport();
  }, [id]);

  return (
    <div>
      {reportAvailable ? (
        <>
          <div className="Dash-Cont" style={{ marginTop: "5rem" }}>
            <h3
              style={{
                fontSize: "18px",
                color: "#4E4E4E",
                marginLeft: "15px",
                wordBreak: "break-word",
              }}
              title={reportName}
            >
              <br />
              <br />
              <br />
              <br />
              Report Name:{" "}
              {reportName.length > 18
                ? reportName.slice(0, 18) + "..."
                : reportName}
            </h3>
            <Dashboard selectedReportId={decryptedId} />
          </div>
        </>
      ) : reportAvailable === false ? (
        <div className="Dash-Cont" style={{ marginTop: "10rem" }}>
          <div className="no-reports">
            Report has reached the maximum number of accesses allowed and is no
            longer accessible.
          </div>
        </div>
      ) : (
        <div className="Dash-Cont" style={{ marginTop: "5rem" }}>
          <div className="centered-loader_report_tab">
            <Spin size="large" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportPage;
