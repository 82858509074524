import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

const PasswordResetPage = () => {
  const [loading, setLoading] = useState(false);
  const [wrongcredentials, setwrongcredentials] = useState(false);
  const [responsemessage, setresponsemessage] = useState("");
  const navigate = useNavigate();
  const { id, token } = useParams();

  // Function to handle "Go Back" action
  const handleGoBack = () => {
    window.history.back();
  };

  // Custom validation rule to check password requirements
  const validatePassword = (_, value) => {
    if (value.length < 8) {
      return Promise.reject(
        new Error(
          "Password must be a minimum of 8 characters in length and must contain at least one special character and one numeric digit."
        )
      );
    }

    const specialCharacterRegex = /[!@#$%^&*()_+[\]{};':"\\|,.<>/?]+/;
    const numberRegex = /\d+/;

    if (!specialCharacterRegex.test(value) || !numberRegex.test(value)) {
      return Promise.reject(
        new Error(
          "Password must include at least one special character and one number."
        )
      );
    }

    return Promise.resolve();
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      setwrongcredentials(false);
      const payload = {
        id,
        token,
        values,
      };
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}reset/verify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.status === "passed") {
        setLoading(false);
        navigate("/resetpasswordemailsent", {
          state: { isPasswordChanged: true },
        });
      } else {
        setwrongcredentials(true);
        setresponsemessage(result["message"]);
        setLoading(false);
        // Handle invalid credentials (show error message, etc.)
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="arrow-container">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={handleGoBack}
          className="back-icon"
        />
      </div>
      <div className="Main_div_forgot">
        <a className="forgot_heading">Reset Password</a>

        <Form
          name="reset_password_form"
          onFinish={onFinish}
          className="login-form"
          layout="vertical"
        >
          <Form.Item
            name="password"
            className="form-item"
            label={<span className="label-font">New Password</span>}
            rules={[
              {
                required: true,
                message: "Please provide your new password.",
              },
              {
                validator: validatePassword,
              },
            ]}
            hasFeedback
          >
            <Input.Password
              className="input-field password-field"
              placeholder="Enter your password"
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            className="form-item"
            dependencies={["password"]}
            label={<span className="label-font">Confirm New Password</span>}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your new password.",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The passwords that you entered do not match.")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              className="input-field password-field"
              placeholder="Re-enter your password"
            />
          </Form.Item>

          {wrongcredentials && (
            <Form.Item className="wrong_credentials_div">
              <p className="label-font" style={{ color: "red" }}>
                {responsemessage}
              </p>
            </Form.Item>
          )}
          
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="submit-button"
              disabled={loading}
            >
              {loading ? (
                <span>
                  <span className="loading-dot"></span>
                  <span className="loading-dot"></span>
                  <span className="loading-dot"></span>
                </span>
              ) : (
                <span
                  className="label-font"
                  style={{
                    color: "white",
                    fontSize: "17px",
                  }}
                >
                  Submit
                </span>
              )}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default PasswordResetPage;
