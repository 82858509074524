import "./style.css";

const DpapolicyDeclaration = () => {
  return (
    <>
      <div className="policies_maindiv">
        <div className="cookie-policy-maindiv mb-0">
          <p style={{ fontSize: "25px", color: "#364545", fontWeight: "bold" }}>
            Data Processing Addendum
          </p>
          <p>Date of last revision: July 10, 2024</p>
        </div>
        <div className="cookie-policy-maindivs">
          <div class="cookie-table-container">
            <p>
              This Data Processing Agreement (DPA) supplements Terms and
              Conditions or other agreement such as Customer Agreement in place
              between the Customer and Resilient Technologies Ltd (Supplier)
              covering the Customer’s use of Resilient Technologies’ Products,
              Support and Advisory Services.
            </p>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Background:
            </p>
            <ol>
              <li>
                The Supplier is a provider of cloud-based AI powered language
                sentiment analysis service to customers who sign up through the
                product’s website.
              </li>
              <li>
                The Supplier and Customer entered into an agreement when the
                Customer signed up for its product and accepted Terms and
                Conditions or Customer Agreement.
              </li>
              <li>
                The Supplier and Customer have agreed to enter into this DPA in
                relation to the processing of personal data by the supplier in
                the course of providing the services. The terms of this DPA are
                intended to apply in addition to and not in substitution of the
                Terms and Conditions agreement or Customer Agreement.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              AGREEMENT
            </p>

            <p>1. In this DPA, the following words are defined:</p>

            <table>
              <tr>
                <th style={{ background: "white", textAlign: "center" }}>
                  Affiliate
                </th>
                <td style={{ background: "white" }}>
                  any entity that directly or indirectly controls, or is
                  controlled by, or is under common control with the subject
                  entity. 'Control' for the purposes of this definition, means
                  direct or indirect ownership or control of more than 50% of
                  the voting interests of the subject entity.
                </td>
              </tr>
              <tr>
                <th style={{ background: "white", textAlign: "center" }}>
                  Data Protection Law
                </th>
                <td style={{ background: "white" }}>
                  <p>
                    a. all laws and regulations, including laws and regulations
                    of the European Union, the European Economic Area and their
                    member states, Switzerland and the United Kingdom applicable
                    to the processing of Personal Data under the Agreement,
                    including, but not limited to EU Directive 95/46/EC, as
                    transposed into domestic legislation of each Member State
                    and as amended, replaced or superseded from time to time,
                    including by the GDPR; and laws implementing or
                    supplementing the GDPR;
                  </p>
                  <p>
                    b. to the extent applicable, the data protection or privacy
                    laws of any other country.
                  </p>
                </td>
              </tr>
              <tr>
                <th style={{ background: "white", textAlign: "center" }}>
                  GDPR
                </th>
                <td style={{ background: "white" }}>
                  Regulation (EU) 2016/679 of the European Parliament and of the
                  Council of 27 April 2016 on the protection of natural persons
                  with regard to the processing of personal data and on the free
                  movement of such data, and repealing Directive 95/46/EC (the
                  EU GDPR); and the EU GDPR as implemented or adopted under the
                  laws of the United Kingdom (UK GDPR) (General Data Protection
                  Regulation).
                </td>
              </tr>
              <tr>
                <th style={{ background: "white", textAlign: "center" }}>
                  International Data Transfer Agreement
                </th>
                <td style={{ background: "white" }}>
                  The International Data Transfer Agreement issued by the
                  Information Commissioner for parties making Restricted
                  Transfers available at{" "}
                  <a
                    href="https://ico.org.uk/media/for-organisations/documents/4019538/international-data-transfer-agreement.pdf"
                    target="_blank"
                    style={{
                      color: "#0000ff",
                      textDecoration: "underline",
                      fontFamily: `"Motiva Sans Light", sans-serif`,
                      fontSize: "16px",
                    }}
                  >
                    https://ico.org.uk/media/for-organisations/documents/4019538/international-data-transfer-agreement.pdf
                  </a>{" "}
                  (as amended or updated from time to time).
                </td>
              </tr>
              <tr>
                <th style={{ background: "white", textAlign: "center" }}>
                  Personnel
                </th>
                <td style={{ background: "white" }}>
                  in relation to a party, those of its employees, workers,
                  agents, consultants, contractors, sub-contractors,
                  representatives or other persons employed or engaged by that
                  party on whatever terms.
                </td>
              </tr>
              <tr>
                <th style={{ background: "white", textAlign: "center" }}>
                  Sub-processor
                </th>
                <td style={{ background: "white" }}>
                  any entity (whether or not an Affiliate of the Supplier, but
                  excluding the Supplier's Personnel) appointed by or on behalf
                  of the Supplier to process Personal Data on behalf of the
                  Customer under this DPA.
                </td>
              </tr>
              <tr>
                <th style={{ background: "white", textAlign: "center" }}>
                  Working Day
                </th>
                <td style={{ background: "white" }}>
                  any day, other than a Saturday, Sunday, or public holiday in
                  England and Wales.
                </td>
              </tr>
            </table>

            <p style={{ marginTop: "15px" }}>
              2. Terms such as "Data Subject", "Processing", "Personal Data",
              "Controller", and "Processor", "Supervisory Authority", and
              "Personal Data Breach" shall have the same meaning as ascribed to
              them in the Data Protection Law.
            </p>

            <p>
              3. In this DPA unless the context requires a different
              interpretation:
            </p>
            <ol type="a">
              <li>the singular includes the plural and vice versa;</li>
              <li>
                references to sub-clauses, clauses, schedules or appendices are
                to sub-clauses, clauses, schedules or appendices of this DPA;
              </li>
              <li>
                a reference to a person includes firms, companies, government
                entities, trusts and partnerships;
              </li>
              <li>
                'including' is understood to mean 'including without
                limitation';
              </li>
              <li>
                reference to any statutory provision includes any modification
                or amendment of it;
              </li>
              <li>
                the headings and sub-headings do not form part of this DPA; and
              </li>
              <li>
                'writing' or 'written' will include fax and email unless
                otherwise stated.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Processing Customer Personal Data
            </p>
            <ol start="4">
              <li>
                For the purpose of Data Protection Law, the Customer shall be
                the Controller and the Supplier shall be the Processor.
              </li>
              <li>
                The Supplier and each Supplier Affiliate shall:
                <ol type="a">
                  <li>
                    comply with all applicable Data Protection Law in the
                    Processing of Customer Personal Data; and
                  </li>
                  <li>
                    only Process Personal Data on the Customer's documented
                    instructions, unless Processing is required by any
                    applicable law to which the Supplier is subject, in which
                    case, the Supplier shall, to the extent permitted by
                    applicable law, inform the Customer of such legal
                    requirement before undertaking the Processing.
                  </li>
                </ol>
              </li>
              <li>
                The Supplier and each Supplier Affiliate shall take reasonable
                steps to ensure the reliability of Personnel who have access to
                the Personal Data, ensuring in each case that such Personnel is
                subject to a strict duty of confidentiality (whether a
                contractual or statutory duty) and that they Process the
                Personal Data in compliance with all applicable law and only for
                the purpose of delivering the Services under the Agreement.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Security
            </p>
            <ol start="7">
              <li>
                The Supplier will establish data security in relation to the
                Processing of Personal Data under this DPA. The measures to be
                taken must guarantee a protection level appropriate to the risk
                concerning confidentiality, integrity, availability and
                resilience of the systems. The state of the art, implementation
                costs, the nature, scope and purposes of the Processing, as well
                as the probability of occurrence and the severity of the risk to
                the rights and freedoms of natural persons must be taken into
                account. Such measures may include, as appropriate:
                <ol type="a">
                  <li>the pseudonymisation and encryption of Personal Data;</li>
                  <li>
                    the ability to ensure the ongoing confidentiality,
                    integrity, availability and resilience of processing systems
                    and services;
                  </li>
                  <li>
                    the ability to restore the availability and access to
                    Personal Data in a timely manner in the event of a physical
                    or technical incident; and
                  </li>
                  <li>
                    processes for regularly testing, assessing and evaluating
                    the effectiveness of technical and organisational measures
                    for ensuring the security of the Processing.
                  </li>
                </ol>
              </li>
              <li>
                In assessing the appropriate level of security, the Supplier
                shall take into account any risks that are presented by the
                Processing, in particular, from a Personal Data Breach.
              </li>
              <li>
                The Supplier has laid down the technical and organisational
                measures in Schedule 2 of this DPA. Technical and organisational
                measures are subject to technical progress and further
                development. In this respect, the Processor may implement
                alternative adequate measures from time to time and shall notify
                the Customer in writing where it has done so.
              </li>
            </ol>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Sub-processors
            </p>

            <ol start="10">
              <li>
                The Customer authorises the Supplier and each Supplier Affiliate
                to appoint the Sub-processors listed in Schedule 3.
              </li>
              <li>
                With respect to each Sub-processor, the Supplier, or the
                Supplier Affiliate shall:
                <ol type="a">
                  <li>
                    carry out appropriate due diligence prior to the Processing
                    by such Sub-processor to ensure that the Sub-processor is
                    capable of providing the level of protection for Personal
                    Data required by the terms of the Agreement and this DPA;
                  </li>
                  <li>
                    enter into a written agreement with the Sub-processor
                    incorporating terms which are substantially similar (and not
                    less onerous) than those set out in this DPA and which meet
                    the requirements of Article 28(3) of UK GDPR; and
                  </li>
                  <li>
                    remain fully liable to the Customer for all acts or
                    omissions of such Sub-processor as though they were its own.
                  </li>
                </ol>
              </li>
              <li>
                The Supplier and each Supplier Affiliate may continue to use
                Sub-processors already engaged by the Supplier or Supplier
                Affiliate as at the date of this DPA subject to the Supplier or
                Supplier Affiliate meeting the obligations set forth in the
                preceding clause as soon as reasonably practicable.
              </li>
              <li>
                The Supplier shall give the Customer prior written notice of the
                appointment of any new Sub-processor, including the name of the
                Sub-processor it seeks to appoint and the Processing activity to
                be undertaken by the Sub-processor.
              </li>
              <li>
                If within 30 days of receipt of notice under the preceding
                clause, the Customer (acting reasonably and in good faith)
                notifies the Supplier in writing of any objections to the
                proposed appointment:
                <ol type="a">
                  <li>
                    the parties will work in good faith to make available a
                    commercially reasonable change in the provision of the
                    Services which avoids the use of the proposed Sub-processor
                    without unreasonably burdening the Customer; and
                  </li>
                  <li>
                    where such a change cannot be made within 30 days of the
                    Supplier’s receipt of the Customer’s notice, the Customer
                    may, notwithstanding the terms of the Agreement, serve
                    written notice on the Supplier to terminate the applicable
                    order form(s) to the extent that the provision of the
                    Services is or would be affected by the appointment. The
                    Supplier will refund the Customer any prepaid fees covering
                    the remainder of the term of such order form(s) with respect
                    to the terminated Services.
                  </li>
                </ol>
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Data subject rights
            </p>
            <ol start="15">
              <li>
                Taking into account the nature of the Processing, the Supplier
                and each Supplier Affiliate shall assist the Customer in
                implementing appropriate technical and organisational measures,
                insofar as this is possible, for the fulfilment of the
                Customer’s obligation to respond to requests for exercising Data
                Subjects' rights under the Data Protection Law.
              </li>
            </ol>

            <ol start="16">
              <li>
                The Supplier shall:
                <ol type="a">
                  <li>
                    Promptly (and in any event, within 24 hours) notify the
                    Customer if it (or any of its Sub-processors) receives a
                    request from a Data Subject; and
                  </li>
                  <li>
                    Fully cooperate with and assist the Customer in relation to
                    any request made by a Data Subject, under the Data
                    Protection Law in respect of Personal Data processed by the
                    Supplier under the terms of the Agreement or this DPA.
                  </li>
                </ol>
              </li>
            </ol>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Personal data breaches
            </p>
            <ol start="17">
              <li>
                The Supplier shall:
                <ol type="a">
                  <li>
                    notify the Customer without undue delay (in any event, no
                    later than 72 hours) upon becoming aware of any Personal
                    Data Breach affecting the Personal Data processed by the
                    Supplier under this DPA;
                  </li>
                  <li>
                    provide sufficient information to enable the Customer to
                    evaluate the impact of the Personal Data Breach and to meet
                    any obligations on the Customer to report the Personal Data
                    Breach to a Supervisory Authority and/or notify the affected
                    Data Subjects in accordance with the Data Protection Law;
                  </li>
                  <li>
                    cooperate with the Customer and take such reasonable
                    commercial steps as directed by the Customer to assist in
                    the evaluation, investigation, mitigation and remediation of
                    each such Personal Data Breach.
                  </li>
                </ol>
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Data Protection Impact Assessment and Prior Consultation
            </p>
            <ol start="18">
              <li>
                The Supplier and each Supplier Affiliate shall provide
                reasonable assistance to the Customer with any data protection
                impact assessments and prior consultations with Supervisory
                Authorities or other competent authorities which the Customer
                considers necessary pursuant to Articles 35 and 36 of the EU
                GDPR.
              </li>
            </ol>

            <p>
              19. Such assistance from the Supplier shall be limited, in each
              case, to the Processing of Personal Data under this DPA.
            </p>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Return and deletion of personal data
            </p>
            <ol start="20">
              <li>
                Subject to the subsequent clause, the Supplier and each Supplier
                Affiliate shall promptly and in any event, within 30 days of the
                expiry or termination of the Agreement, delete or return all
                copies of Personal Data Processed by the Supplier and/or its
                Sub-processors on behalf of the Customer, if it was retained by
                the Supplier after processing, by such means as the parties
                shall agree in writing.
              </li>
              <li>
                The Supplier (and its Sub-processors) may retain Personal Data
                Processed under this DPA to the extent required by any
                applicable law to which the Supplier (or any Sub-processor) is
                subject and only to the extent and for such period as required
                by applicable law. Where applicable, the Supplier shall notify
                the Customer of any such requirement and ensure the
                confidentiality of such Personal Data. Any Personal Data
                Processed under this DPA and retained by the Supplier (or any
                Sub-processor) in accordance with this clause shall be processed
                only for other purposes other than the purposes specified in the
                applicable laws.
              </li>
              <li>
                The Customer may require the Supplier to provide written
                certification confirming that it has complied in full with its
                obligations under this section entitled 'Return and deletion of
                personal data'.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Audits
            </p>
            <ol start="23">
              <li>
                The Supplier and each Supplier Affiliate shall make available to
                the Customer on request all information necessary to demonstrate
                compliance with this DPA.
              </li>
              <li>
                The Supplier shall allow for and contribute to audits, including
                inspections, by the Customer (or any other auditor mandated by
                the Customer) in relation to the Processing of Personal Data
                under this DPA.
              </li>
              <li>
                The Customer (or any other auditor mandated by the Customer)
                shall give the Supplier or Supplier Affiliate reasonable notice
                of any audit or inspection and shall make all reasonable
                endeavours to avoid causing any damage, injury or disruption in
                the course of the audit or inspection.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Restricted transfers
            </p>
            <ol start="27">
              <li>
                For the purposes of this section entitled 'Restricted
                transfers', a 'Restricted Transfer' is an onward transfer of
                Personal Data from the Supplier (or a Sub-Processor) to a
                Sub-Processor, in each case, where such transfer would be
                prohibited by Data Protection Law in the absence of the
                International Data Transfer Agreement.
              </li>
              <li>
                Subject to the subsequent clause, the Supplier (the 'data
                exporter') and/or each Sub-processor as appropriate (the 'data
                importer'), will enter into the International Data Transfer
                Agreement in respect of any Restricted Transfer.
              </li>
              <li>
                The preceding clause shall not apply to a Restricted Transfer
                unless its effect, together with other reasonably practical
                compliance steps (which do not include obtaining consent from
                Data Subjects) is to allow the Restricted Transfer to take place
                without breach of applicable Data Protection Law.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Liability and Indemnity
            </p>
            <ol start={30}>
              <li>
                Nothing in this DPA limits or excludes either party's liability
                for death or personal injury caused by its negligence, fraud or
                fraudulent misrepresentation.
              </li>
              <li>
                Each party shall defend, indemnify, and hold harmless the other
                and its Personnel against any and all claims, costs, losses,
                expenses (including legal fees), demands, and causes of action
                of any kind or character, without limitation, arising from or in
                connection with a breach of a party's obligations or the
                obligations of its Affiliates and/or Sub-processors under this
                DPA.
              </li>
              <li>
                Subject to the two preceding clauses, the total liability of
                either party to the other for any non-compliance with this DPA
                shall be subject to any limitation regarding monetary damages
                set forth in the Terms and Conditions or Customer Agreement.
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              General terms
            </p>
            <ol start="33">
              <li>
                Except in respect of any provision of this DPA that expressly or
                by implication is intended to come into or continue in force on
                or after the expiry or termination of the Agreement, this DPA
                shall be coterminous with the Agreement.
              </li>
              <li>
                No party may assign, transfer or sub-contract to any third party
                the benefit and/or burden of the DPA without the prior written
                consent (not to be unreasonably withheld) of the other party.
              </li>
              <li>
                No variation of the DPA will be valid or binding unless it is
                recorded in writing and signed by or on behalf of both parties.
              </li>
              <li>
                No variation of the Agreement will be valid or binding unless it
                is recorded in writing and signed by or on behalf of both
                parties.
              </li>
              <li>
                The Contracts (Rights of Third Parties) Act 1999 does not apply
                to the DPA and no third party has any right to enforce or rely
                on any provision of the DPA.
              </li>
              <li>
                Unless otherwise agreed, no delay, act or omission by a party in
                exercising any right or remedy will be deemed a waiver of that,
                or any other, right or remedy.
              </li>
              <li>
                If any court or competent authority finds that any provision (or
                part) of the DPA is invalid, illegal or unenforceable, that
                provision or part-provision will, to the extent required, be
                deemed to be deleted, and the validity and enforceability of the
                other provisions of the DPA will not be affected.
              </li>
              <li>
                Any notice or other than in legal proceedings to be delivered
                under the DPA must be in writing and delivered by pre-paid first
                class post or left by hand delivery at the other party's
                registered address or place of business, or sent by email to its
                main email address. Notices:
                <ol type="a">
                  <li>
                    any post will be deemed to have been received, where posted
                    from and to addresses in the United Kingdom, on the second
                    Working Day and, where posted from or to addresses outside
                    the United Kingdom, on the tenth Working Day following the
                    date of posting;
                  </li>
                  <li>
                    delivered by hand will be deemed to have been received at
                    the time the notice is left at the proper address; and
                  </li>
                  <li>
                    sent by email will be deemed to have been received on the
                    next Working Day after transmission.
                  </li>
                </ol>
              </li>
            </ol>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Governing law and jurisdiction
            </p>
            <p>
              41. This DPA will be governed by and interpreted according to the
              law of England and Wales and all disputes arising under the DPA
              (including non-contractual disputes or claims) shall be subject to
              the exclusive jurisdiction of the English and Welsh courts.
            </p>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
                marginTop: "2rem",
              }}
            >
              Schedule 1 - Processing activities
            </p>

            <p>
              This Schedule 1 includes certain details of the Processing of
              Personal Data as required by Article 28(3) UK GDPR. The subject
              matter and duration of the Processing of the Personal Data are set
              out in the Terms and Conditions or Customer Agreement and this
              DPA.
            </p>

            <p style={{ textDecoration: "underline" }}>
              The nature and purpose of the Processing of Personal Data:
            </p>
            <p>
              The Supplier will Process Personal Data as necessary to provide
              the Services pursuant to the Terms and Conditions or Customer
              Agreement, and as further instructed by the Customer in its use of
              the Services. This includes:
            </p>
            <ol type="a">
              <li>
                storing Customer’s Personal Information in secure databases
                after a new account is created or edited. This information will
                be used for providing services and account administration and
                will be withheld until the account is closed. The Supplier will
                be the controller of this data and will ensure that it is kept
                in accordance with terms mentioned elsewhere in this agreement
                or Terms and Conditions or Customer Agreement.
              </li>
              <li>
                use of Personal Information Identifier (PII) software module to
                identify and delete any third-party personal information from
                the content reviews uploaded by the customer for analysis. The
                Customer will be controller of this data and must ensure that
                they have legal rights to upload the reviews data and they have
                gained relevant approvals from the reviewers (where applicable).
              </li>
            </ol>

            <p style={{ textDecoration: "underline" }}>
              The types of Personal Data to be Processed:
            </p>
            <p>
              The Customer may submit Personal Data to the Services, the extent
              of which is determined and controlled by the Customer in its sole
              discretion, and which may include, but is not limited to the
              following types of Personal Data:
            </p>
            <ol type="a">
              <li>
                personal information about the Customers which could include
                first name, second name, email address, phone numbers, company
                name, payment information and billing details.
              </li>
              <li>
                personal information about the reviewers or other persons in the
                reviews data which could include first name, second name, email
                address, phone numbers, company name, city of residence or any
                other information that a reviewer might state about themselves
                or another person.
              </li>
            </ol>

            <p style={{ textDecoration: "underline" }}>
              The categories of Data Subjects to whom the Personal Data relates:
            </p>
            <p>
              The Customer may submit Personal Data to the Services, the extent
              of which is determined and controlled by the Customer in its sole
              discretion, and which may include, but is not limited to Personal
              Data relating to the following categories of Data Subjects:
            </p>
            <ol type="a">
              <li>Customers (including their staff).</li>
              <li>Clients (including their staff).</li>
              <li>Suppliers (including their staff).</li>
              <li>
                Review writers of customers services, products, and business.
              </li>
              <li>
                Third part individuals whose personal details are mentioned in
                reviews posted for the customers services, products, and
                business by reviewers.
              </li>
            </ol>

            <p style={{ textDecoration: "underline" }}>
              The obligations and rights of Customer and Customer Affiliates:
            </p>
            <p>
              The obligations and rights of the Customer (and any Customer
              Affiliates) are set out in the Terms and Conditions of Customer
              Agreement and this DPA.
            </p>

            <p>
              A Personal Information Identifies (PII) module in the software
              will identify and delete any personal information from the
              reviews. Due to nature of technology some personal information
              might still not be identified and deleted but the software is
              designed with data privacy in mind and therefore there is a high
              likelihood that it will not be used and displayed in analysis
              results.
            </p>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Schedule 2 - Technical and organisational measures
            </p>
            <p>
              The Supplier will conduct the activities covered by this DPA in
              compliance with its Information Security Policy, available from
              the Data Protection Officer or another person responsible for data
              protection compliance, and relevant data protection policies and
              guidance, available from the Data Protection Officer or another
              person responsible for data protection compliance.
            </p>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Schedule 3 - Sub-processors
            </p>

            <p>
              The Customer agrees that the Supplier may sub-contract certain
              obligations under this DPA to the following Sub-processors:
            </p>
            <ul>
              <li>Name of Sub-processor: N/A</li>
              <li>Contact details: N/A</li>
              <li>Email address: N/A</li>
              <li>Telephone number: N/A</li>
              <li>Sub-contracted activities: N/A</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default DpapolicyDeclaration;
