import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
interface HeaderSectionProps {
  isPieRoute: boolean;
  isEditRoute: boolean;
}


interface BurgerProps {
  top?: string;
  showForSmallDeviceAndSidebarOpen: boolean;
}

// Define an interface for the NotHidden component's props
interface NotHiddenProps {
  shouldBeHidden: boolean;
}


export const HeaderSection = styled.header<HeaderSectionProps>`
  padding-top: 1rem;
  padding-bottom: 1rem;
  
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;  /* Ensures the header stays above other content */
  background-color: white; /* Or any other color */
  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }
  
`;

// Function to apply conditional styles
const applyConditionalStylesonLogo = ({ isPieRoute,isEditRoute }: HeaderSectionProps) => {
  // Initialize an empty styles object
  let styles = {};

  // Apply styles based on `isEditRoute`
  if (isEditRoute) {
    styles = {
      ...styles,
      '@media (min-width: 420px) and (max-width: 770px)': {
        marginLeft: '-2.0rem',
      },
      '@media (min-width: 300px) and (max-width: 400px)': {
        marginLeft: '-1.2rem',
      },
    };
  }

  // Apply styles based on `isPieRoute`
  if (!isPieRoute && !isEditRoute) {
    styles = {
      ...styles,
      '@media (min-width: 420px) and (max-width: 770px)': {
        marginLeft: '-2.0rem',
      },
      '@media (min-width: 300px) and (max-width: 400px)': {
        marginLeft: '-0.7rem',
      },
    };
  }

  return styles;
};



export const LogoContainer = styled(Link)<HeaderSectionProps>`
  display: flex;
  padding-top: 7px;
  padding-left: 0px;
  margin-left: -1rem;
  ${applyConditionalStylesonLogo}
`;



export const NavLink = styled("div")`
  display: inline-block;
  text-align: center;
  
`;

export const CustomNavLink = styled("div")`
  width: 203px;
  display: inline-block;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const ContactWrapper = styled("div")<any>`
  cursor: pointer;
  width: ${(p) => (p.width ? "100%" : "110px")};
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  display: inline-block;
`;

// Update the CustomNavLinkSmall to center the text for all links
export const CustomNavLinkSmall = styled(NavLink)<NotHiddenProps>`
  font-size: 1.3rem;
  color: #364545;
  transition: color 0.2s ease-in;
  margin: 0.5rem 1.2rem;
  text-align: center; // Center the text for all links

  @media only screen and (max-width: 990px) {
    margin: 1rem 0; // Adjust margin for vertical stacking
    text-align: left; // Center align text for small screens
    width: 100%; // Full width for small screens to center the text properly
  }

  ${props => props.shouldBeHidden && `
    @media only screen and (max-width: 1150px) {
      margin: 1rem 0; // Adjust margin for vertical stacking
      text-align: left; // Center align text for small screens
      width: 100%; // Full width for small screens to center the text properly
    }
  `}
`;


export const Burger = styled.div<BurgerProps>`
  display: none; // Default to not displayed

  @media only screen and (max-width: 990px) {
    display: block;
    position: absolute;
    right: 10px;
    top: ${({ top }) => top || '37px'};
  }

   @media only screen and (max-width: 369px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
    // margin-right: -1.9rem;
  }

  ${({ showForSmallDeviceAndSidebarOpen, top }) => showForSmallDeviceAndSidebarOpen && `
    @media only screen and (min-width: 1000px) and (max-width: 1130px) {
      display: block;
      position: absolute;
      right: 60px;
      
      top: ${top || '37px'};
    }
  `}
`;


// Styled container for navigation links and login button
export const NavAndLoginContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  @media only screen and (max-width: 990px) {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
  
`;

// Adjusting Menu for better text handling on small screens
export const Menu = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: start;
  color: #364545;
  white-space: nowrap; // Prevent word breaking in menu titles
`;

export const NotHidden = styled.div<NotHiddenProps>`
  // Default styles
  display: flex;
  align-items: center;

  // Conditional styling to override default
  ${({ shouldBeHidden }) => shouldBeHidden && `
    display: none; 
  `}

  @media only screen and (max-width: 990px) {
    display: none;
  }
`;


export const Label = styled("span")`
  font-weight: 500;
  color: #364545;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined)`
  font-size: 22px;
`;



export const Span = styled("span")`
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    
    color: #008080;
    // text-underline-position: under;
    text-decoration: #008080 ;
  }
`;

// If there is a separate container for the "Login" button, ensure its alignment for small screens
export const LoginButtonContainer = styled.div`
  @media only screen and (max-width: 890px) {
    
    margin-top: 1rem;
    width: 100%; // Take the full width to align with the links
    display: flex;
    justify-content: center; // Center the button
  }
`;

export const UserMenuContainer = styled.div`
  display: none;
  @media only screen and (max-width: 1100px) {
    display: flex; // or "flex" depending on your design needs
    align-items: center;
    justify-content: center;
    padding-right: 30px;
  }
  
  @media only screen and (min-width: 370px) and (max-width: 890px) {
    display: flex; // or "flex" depending on your design needs
    align-items: center;
    justify-content: center;
    padding-right: 20px;
  }

  @media only screen and (max-width: 369px) {
    display: flex; // or "flex" depending on your design needs
    align-items: center;
    justify-content: center;
    margin-right: 1rem !important;
    
  }
  

`;
