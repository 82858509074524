import React, { useState, useEffect, Suspense } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import "./Intellisence_Options.css";
const SearchSentiment = React.lazy(() => import("./SearchSentiment"));
const ViewPreviousQuestions = React.lazy(() =>
  import("./ViewPreviousQuestions")
);

const Options_intellisence = ({
  datapassed,
  selectedReportId,
  passeduserid,
}) => {
  const [viewMode, setViewMode] = useState("askQuestion");
  const [isProcessing, setIsProcessing] = useState(false); // State to track if a question is being processed
  const [associationStatus, setAssociationStatus] = useState(false);
  const [error, setError] = useState(null);
  // Callback function to update processing state
  const handleProcessingChange = (isProcessing) => {
    setIsProcessing(isProcessing);
  };

  useEffect(() => {
    const checkAssociation = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}report/check-report-association`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              report_id: selectedReportId,
              user_id: passeduserid,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();

        if (result.status === "associated") {
          setAssociationStatus(true);
          setViewMode("askQuestion");
        } else {
          setAssociationStatus(false);
          setViewMode("viewPrevious");
        }
      } catch (error) {
        setError(`Error: ${error.message}`);
      }
    };

    checkAssociation();
  }, [selectedReportId, passeduserid]);

  return (
    <div className="intellisence-options">
      <div className="tabs">
        {associationStatus && (
          <div
            className={`tab ${viewMode === "askQuestion" ? "active" : ""}`}
            onClick={() => setViewMode("askQuestion")}
          >
            <div className="headings_class">
              <h3>Intelligent Search</h3>
            </div>
            <div>
              <div className="new-tooltip-container">
                <span className="info-icon">
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    size="s"
                    style={{
                      color: "#9e9e9e",
                      paddingBottom: "5px",
                      paddingLeft: "10px",
                    }}
                  />
                </span>
                <div className="tooltip-below">
                  <a id="content_word_cloud">
                    AI enabled search query for positive and negative phrases
                    with a cap of 5 searches per file.
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className={`tab ${viewMode === "viewPrevious" ? "active" : ""} ${
            isProcessing ? "disabled" : ""
          }`}
          onClick={() => !isProcessing && setViewMode("viewPrevious")}
        >
          <div className="headings_class">
            <h3>View Previous Searches</h3>
          </div>
        </div>
      </div>
      <div className="tab-content">
        <Suspense fallback={<div>Loading...</div>}>
          {viewMode === "askQuestion" && (
            <SearchSentiment
              key={selectedReportId + "-search"}
              defaultView="newQuestion"
              datapassed={datapassed}
              selectedReportId={selectedReportId}
              onProcessingChange={handleProcessingChange}
              passeduserid={passeduserid}
            />
          )}
          {viewMode === "viewPrevious" && (
            <ViewPreviousQuestions
              key={selectedReportId + "-view"}
              selectedReportId={selectedReportId}
            />
          )}
        </Suspense>
      </div>
    </div>
  );
};

export default Options_intellisence;
