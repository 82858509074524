import React, { forwardRef } from "react";
import { Doughnut } from "react-chartjs-2";

const ScoreWheel = forwardRef(({ score, outOf }, ref) => {
  const getColor = (score, outOf) => {
    const ratio = score / outOf;
    if (ratio < 0.4) return "#FF6384"; // Red
    if (ratio < 0.7) return "#FFCE56"; // Amber
    return "#4BCA81"; // Green
  };

  const backgroundColor = getColor(score, outOf);

  const data = {
    Score: score, // Custom properties prefixed with underscore
    Outof: outOf,
    cutoutPercentage: 90,

    datasets: [
      {
        data: [score, outOf - score],
        backgroundColor: [backgroundColor, "#E0E0E0"],
        hoverBackgroundColor: [backgroundColor, "#E0E0E0"],
        borderWidth: 0,
        hoverOffset: 4,
        spacing: 5,
      },
    ],
  };

  const scoreInsideDoughnutPlugin = {
    id: "scoreInsideDoughnut",

    beforeDraw: (chart) => {
      const ctx = chart.ctx;
      const ctx2 = chart.ctx;
      const { width, height } = chart;
      const fontSize = ((height / 160) * 0.8).toFixed(2); // Slightly smaller font size

      ctx.restore();
      // Improved font style: Use a nicer font if available.
      ctx.font = `bold ${1.2}em "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif`;
      ctx2.font = ` ${1.2}em "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif`;

      ctx.textBaseline = "middle";
      ctx.fillStyle = "#7f8081"; // Darker font color for better contrast
      ctx.textAlign = "center"; // Center the text horizontally

      // Add text shadow or stroke for better visibility
      ctx.shadowColor = "rgba(0,0,0,0.25)";
      ctx.shadowBlur = 3;
      ctx.shadowOffsetX = 2;
      ctx.shadowOffsetY = 2;
      const text = `${score}`;
      const text1 = `out of ${outOf}`,
        textX = width / 2,
        textY = height / 2;

      ctx.fillText(text, textX, textY);
      // ctx2.fillText(text1, textX, textY+17);
      ctx.save();
    },
  };
  const scoreInsideDoughnutPlugin2 = {
    id: "scoreInsideDoughnut2",
    beforeDraw: (chart) => {
      const ctx = chart.ctx;
      const ctx2 = chart.ctx;
      const { width, height } = chart;
      const fontSize = ((height / 160) * 0.8).toFixed(2); // Slightly smaller font size

      ctx.restore();
      // Improved font style: Use a nicer font if available.
      ctx.font = ` ${1.2}em "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif`;

      ctx.textBaseline = "middle";
      ctx.fillStyle = "#7f8081"; // Darker font color for better contrast
      ctx.textAlign = "center"; // Center the text horizontally

      // Add text shadow or stroke for better visibility
      ctx.shadowColor = "rgba(0,0,0,0.25)";
      ctx.shadowBlur = 3;
      ctx.shadowOffsetX = 2;
      ctx.shadowOffsetY = 2;

      const text = `out of ${outOf}`,
        textX = width / 2,
        textY = height / 2;

      ctx.fillText(text, textX, textY + 17);
      ctx.save();
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 80,
    tooltips: { enabled: false },
    hover: { mode: null },
    animation: {
      animateScale: true,
      animateRotate: true,
    },

    plugins: {
      datalabels: {
        display: false,
      },
      beforeDraw: function (chart) {
        var width = chart.chart.width,
          height = chart.chart.height,
          ctx = chart.chart.ctx;

        ctx.restore();
      },
    },
  };

  return (
    <Doughnut
      key={score} // Key on the score value
      data={data}
      options={options}
      plugins={[scoreInsideDoughnutPlugin, scoreInsideDoughnutPlugin2]}
    />
  );
});

export default ScoreWheel;
