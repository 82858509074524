import React, { useEffect } from "react";
import "./ShareableLinkModal.css";

const ShareableLinkModal = ({ isVisible, onClose, link }) => {
  useEffect(() => {
    if (isVisible) {
      document.documentElement.classList.add("modal-open");
    } else {
      document.documentElement.classList.remove("modal-open");
    }

    return () => {
      document.documentElement.classList.remove("modal-open");
    };
  }, [isVisible]);

  if (!isVisible) return null;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link).then(
      () => {
        onClose();
      },
      (err) => {
        console.error("Error copying text: ", err);
      }
    );
  };

  const handleBackgroundClick = (e) => {
    onClose();
  };

  const handleModalContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="modal-overlay-share">
      <div className="modcont-share" onClick={handleBackgroundClick}>
        <div className="modal-share" onClick={handleModalContentClick}>
          <div className="modal-content-share">
            <span className="close" onClick={onClose}>
              &times;
            </span>
            <h2>
              Here's a link that you can share (There is a report viewing limit
              of 15 views)
            </h2>
            <input type="text" value={link} readOnly />
            <button onClick={copyToClipboard}>Copy Link</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareableLinkModal;
