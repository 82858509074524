import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"; // Import the info-circle icon
import "./CallDropdown.css";

function CSVHeaderdropdown(propspassed) {
  const [buttonText, setButtonText] = useState("Select"); // Initial button text
  const [columnDescriptions, setColumnDescriptions] = useState({}); // State for column descriptions

  const handleDropdownItemClick = (headervalue) => {
    if (headervalue === "") {
      // If "None" is selected, set the value to -1
      propspassed.setuserselectedcolumn((prevSelectedColumns) => ({
        ...prevSelectedColumns,
        [propspassed.columnName]: undefined,
      }));
      setButtonText("(None)");
    } else {
      // For any other selection, set the value normally
      propspassed.setuserselectedcolumn((prevSelectedColumns) => ({
        ...prevSelectedColumns,
        [propspassed.columnName]: headervalue,
      }));
      setButtonText(
        headervalue.length > 10 ? headervalue.slice(0, 9) + "..." : headervalue
      );
    }
  };

  const filteredOptions = propspassed.headerdata.filter(
    (option) =>
      !Object.values(propspassed.userselectedcolumn).includes(option) ||
      propspassed.userselectedcolumn[propspassed.columnName] === option
  );

  // Function to toggle visibility of column descriptions
  const toggleDescription = (columnName) => {
    // Toggle the description visibility for the given column name
    setColumnDescriptions((prevDescriptions) => ({
      ...prevDescriptions,
      [columnName]: !prevDescriptions[columnName],
    }));
  };

  return (
    <div className="div_eachdropdown">
      <div className="columnname_and_info">
        <div className="Header_names">
          {propspassed.Required_columns.includes(propspassed.columnName) ? (
            <div style={{ display: "flex" }}>
              <p className="header_column_name">
                {propspassed.columnName}
                <span>*</span>
              </p>
            </div>
          ) : (
            <div>
              <p className="header_column_name">{propspassed.columnName}</p>
            </div>
          )}
        </div>

        <div className="eachfunctionality">
          {/* Add the custom info icon */}
          <div className="data-tooltip-container">
            <span
              className="info-icon"
              onClick={() => toggleDescription(propspassed.columnName)}
            >
              <FontAwesomeIcon
                icon={faCircleInfo}
                size="s"
                style={{
                  color: "#9e9e9e",
                  paddingTop: "5px",
                  paddingLeft: "5px",
                }}
              />
            </span>
            {/* Tooltip content */}
            <div className="tooltip-data">
              {propspassed.Descriptionofcolumn}
            </div>
          </div>
        </div>
      </div>

      <div className="btn-group">
        <button
          className="btn btn-secondary btn-sm dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {buttonText} {/* Display the dynamic button text */}
        </button>
        <div className="dropdown_div">
          <ul className="dropdown-menu scrollable-dropdown-menu">
            <li>
              <a
                className="dropdown-item"
                onClick={() => handleDropdownItemClick("")}
                title="none"
              >
                (None)
              </a>
            </li>
            {filteredOptions.map((headervalue, index) => (
              <li key={index}>
                <a
                  className="dropdown-item"
                  onClick={() => handleDropdownItemClick(headervalue)}
                  title={headervalue}
                >
                  {headervalue.length > 10
                    ? headervalue.slice(0, 9) + "..."
                    : headervalue}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CSVHeaderdropdown;
