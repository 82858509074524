import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

const ResetPasswordEmailSent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isPasswordChanged } = location.state || { isPasswordChanged: false }; // Default to false if no state is passed

  const redirectToLogin = () => {
    navigate("/login");
  };

  // Function to handle "Go Back" action
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <>
      <div className="arrow-container">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={handleGoBack}
          className="back-icon"
        />
      </div>
      {!isPasswordChanged ? (
        <div className="Main_email_div">
          <div className="verification-email-sent">
            <h2>Reset Password Email Sent</h2>
            <p>
              A password reset email has been sent to your email. Please check
              your inbox including junk mail folder and follow the instructions
              to reset your password.
            </p>
            <button onClick={redirectToLogin} className="login-button">
              Login
            </button>
          </div>
        </div>
      ) : (
        <div className="Main_email_div">
          <div className="verification-email-sent">
            <h2>Password Changed Successfully</h2>
            <p>
              Your password has been changed successfully. You can now log in
              with your new password.
            </p>
            <button onClick={redirectToLogin} className="login-button">
              Login
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPasswordEmailSent;
