import { useEffect, useState } from "react";
import "./style.css"; // Adjust the path based on your file structure
import visa from "./visa.png"; // Tell webpack this JS file uses this image
import mastercard from "./mastercard.png"; // Tell webpack this JS file uses this image
import amex from "./amex.png"; // Tell webpack this JS file uses this image
import defaultimg from "./default.png";
import { Form, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
const PayPalComponent = ({ userId }) => {
  const [form] = Form.useForm();
  const [savedCards, setSavedCards] = useState(null);
  const [error, setError] = useState(""); // Error state
  const [allFieldsRequiredError, setAllFieldsRequiredError] = useState(false); // Loading state
  const [newText, setNewText] = useState(true);
  const [cardSaveTrigger, setCardSaveTrigger] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showNewPaymentForm, setShowNewPaymentForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCardholderNameValid, setIsCardholderNameValid] = useState(false);
  const [loading_dots, setloading_dots] = useState(false);
  const [clicked, setClicked] = useState(false);
  const DeletecardmodalMessage =
    "Are you sure you want to delete the payment method?";

  const [DeletecardmodalOpen, setDeletecardModalOpen] = useState(false);
  const [DeletecardError, setDeletecardError] = useState(false);
  const toggleNewPaymentForm = () => {
    setShowNewPaymentForm(!showNewPaymentForm);
  };
  const setUpCardFields = () => {
    const cardFields = window.paypal.CardFields({
      createOrder: () => {
        return fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}verifycard/createorder`,
          {
            method: "post",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              prefer: "return=representation",
            },
          }
        )
          .then((response) => response.json())
          .then((order) => {
            return order.id;
          });
      },
      onApprove: async (data) => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_NODE_SERVER_URL}verifycard/authorizeorder`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                orderid: data,
                userId: userId,
              }),
            }
          );

          if (!response.ok) {
            throw new Error("Payment authorization failed");
          }

          const result = await response.json();
          console.log("Payment processed successfully:", result);
        } catch (error) {
          throw new Error("Payment authorization failed");
        }
      },
      onError: (error) => {
        setError(
          "There was a problem saving your card details. Please review your information and attempt again."
        );
      },
      style: {
        input: {
          "font-size": "14px", // Reduced font size for slimmer look
          "font-family": '"Helvetica Neue", Helvetica, Arial, sans-serif',
          color: "#3a3a3a",
          height: "2rem", // Reduced height for slimmer input fields
          border: "1px solid #909697",
          padding: "4px 8px", // Adjusted padding for a slimmer appearance
          margin: "0px",
        },
        ":focus": {
          color: "black",
        },
        ".valid": {
          // 'color': '#28a745',
        },
        ".invalid": {
          color: "#dc3545",
        },
      },
    });

    if (cardFields.isEligible()) {
      // cardFields.NameField().render("#card-holder-name");

      const cardNameContainer = document.getElementById("card-holder-name");
      const newNameField = cardFields.NameField({
        placeholder: "",
        inputEvents: {
          onChange: (event) => {
            // If the cardholder name is not empty, set a valid state, otherwise set an invalid state
            setIsCardholderNameValid(!event.fields.cardNameField.isEmpty);
            if (event.isEmpty) {
              cardNameContainer.classList.add("invalid");
              cardNameContainer.classList.remove("valid");
            } else {
              cardNameContainer.classList.add("valid");
              cardNameContainer.classList.remove("invalid");
            }
          },
        },
      });

      newNameField.render(cardNameContainer);
      cardFields
        .NumberField({
          placeholder: "**** **** **** 1111",
        })
        .render("#card-number");
      cardFields.ExpiryField().render("#expiration-date");
      cardFields.CVVField().render("#cvv");
      // setNameField(newNameField); // Save the nameField object in the state
    }

    const submitButton = document.getElementById("submit");
    submitButton.addEventListener("click", () => {
      setloading_dots(true);
      setAllFieldsRequiredError(false);
      setDeletecardError(false);
      setError("");
      cardFields
        .submit()
        .then(() => {
          setAllFieldsRequiredError(false);
          setError(""); // Se(t error message
          setCardSaveTrigger((prev) => prev + 1); // Increment to trigger re-fetch
          // Update localStorage to signal that card details have changed
          localStorage.setItem("cardDetailsUpdated", Date.now());
          // setStatus('Card Saved Successfully')
          setShowNewPaymentForm(!showNewPaymentForm);
          setloading_dots(false);
          setClicked(false);
        })
        .catch((error) => {
          setAllFieldsRequiredError(true);
          setloading_dots(false);
          setClicked(false);
        });
    });
  };
  useEffect(() => {
    const loadPayPalScript = async () => {
      try {
        const secretName = process.env.REACT_APP_Paypal_Client_ID;
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}variable/getkeyvaultvalue`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ secretName }),
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        const paypalClientID = data.secretValue;

        if (!paypalClientID) {
          throw new Error("PayPal Client ID not found");
        }

        if (showNewPaymentForm) {
          const script = document.createElement("script");
          script.src = `${process.env.REACT_APP_Paypal_SDK_URL}?components=card-fields&client-id=${paypalClientID}&currency=GBP&intent=authorize`;

          script.onload = () => {
            const checkContainerAndSetup = () => {
              const formContainer = document.getElementById(
                "card_container_input"
              );
              if (formContainer) {
                setUpCardFields();
              } else {
                setTimeout(checkContainerAndSetup, 100); // Adjust the delay as needed
              }
            };

            checkContainerAndSetup();
          };

          document.body.appendChild(script);

          // Cleanup function to remove the script
          return () => {
            script.remove();
          };
        }
      } catch (error) {
        console.error("Error initializing PayPal SDK:", error);
      }
    };

    loadPayPalScript();
  }, [showNewPaymentForm]);

  useEffect(() => {
    const fetchAndDisplaySavedCards = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}payment/api/vault/saved-methods`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ userId: userId }),
          }
        );
        if (!response.ok)
          throw new Error("Failed to fetch saved payment methods.");
        const savedMethods = await response.json();
        setSavedCards(savedMethods);
        setError(""); // Clear any previous error messages
        setLoading(false);
        setNewText(true);
      } catch (error) {
        console.error("Error fetching saved cards:", error);
        setLoading(false);
        setShowNewPaymentForm(true);
        setNewText(false);

        // setError('Failed to fetch saved cards. Please try again.');
      }
    };

    fetchAndDisplaySavedCards();
  }, [userId, cardSaveTrigger]); // Add cardSaveTrigger as a dependency

  useEffect(() => {
    form
      .validateFields()
      .then(() => {
        const allFieldsFilled =
          form.getFieldsValue() &&
          Object.values(form.getFieldsValue()).every(
            (value) => value && value.trim() !== ""
          );
        setIsFormValid(allFieldsFilled && isCardholderNameValid);
      })
      .catch((error) => {
        // Handle form validation failure
        setIsFormValid(false);
      });
  }, [isCardholderNameValid]);

  const handleDeleteCard = async () => {
    try {
      setLoading(true);
      setDeletecardError(false);
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}payment/api/vault/delete-card`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userdata: userId }), // Replace userId with actual user ID variable
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete card.");
      }

      const result = await response.json();

      if (result.status === "passed") {
        setLoading(false);
        setSavedCards(null);
        localStorage.setItem("cardDetailsUpdated", Date.now());
        setShowNewPaymentForm(!showNewPaymentForm);
        setNewText(false);
        // Update the UI accordingly, e.g., remove the card from the displayed list
      } else {
        console.error("Failed to delete card:", result.message);
        setLoading(false);
        setDeletecardError(true);
        // Handle the error, e.g., show an error message to the user
      }
    } catch (error) {
      console.error("Error deleting card:", error);
      setLoading(false);
      setDeletecardError(true);
      // Handle the error, e.g., show an error message to the user
    }
  };

  const createCardElement = (card) => {
    const brandLogoUrl = getBrandLogo(card.brand);

    return (
      <div className="card-container">
        <a
          className="register_heading editaccount-heading"
          style={{ fontSize: "20px" }}
        >
          Saved Payment Method
        </a>
        <div className="payment-method" key={card.last_digits}>
          <div className="payment-method-inner">
            <img
              src={brandLogoUrl}
              alt={card.brand}
              className="card-brand-logo"
            />
            <div className="card-details-text">
              <div className="card-holder-name">{card.name}</div>
              <div className="card-number">
                •••• •••• •••• {card.last_digits}
              </div>
              <div className="card-expiry">Expires {card.expiry}</div>
            </div>
          </div>
          <div className="delete-button-container">
            <button className="delete-button" onClick={confirmDeleteCard}>
              Delete Card
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Helper function to get the logo URL for each card brand
  function getBrandLogo(brand) {
    console.log("brandname:", brand);
    switch (brand?.toLowerCase()) {
      case "visa":
        return `${visa}`; // Replace with the path or URL to the Visa logo
      case "mastercard":
        return `${mastercard}`; // Replace with the path or URL to the Mastercard logo
      case "amex":
        return `${amex}`; // Replace with the path or URL to the American Express logo
      default:
        return `${defaultimg}`; // Replace with the path or URL to a default card logo
    }
  }

  const confirmDeleteCard = (reportId) => {
    setDeletecardModalOpen(true);
  };

  const handleConfirmDelete = () => {
    setDeletecardModalOpen(false);
    handleDeleteCard();
  };

  const handleCancelDelete = () => {
    setDeletecardModalOpen(false);
  };

  if (loading) {
    // Show centered loading indicator
    return (
      <div className="centered-loader">
        <Spin size="large" />
      </div>
    );
  }
  return (
    <div className="pay-det-cont">
      <div className="card_container">
        <div id="payment-methods-container">
          {savedCards &&
            savedCards.cardDetails &&
            savedCards.cardDetails.payment_source &&
            savedCards.cardDetails.payment_source.card &&
            createCardElement(savedCards.cardDetails.payment_source.card)}
        </div>
      </div>
      {DeletecardError && (
        <div className="delete_card_error_div">
          <p className="delete_card_error_div_message">
            The card could not be deleted due to technical issues. Please try
            again later.
          </p>
        </div>
      )}

      <br />
      {error && <div className="error-message text-danger ml-2">{error}</div>}

      {DeletecardmodalOpen && (
        <>
          <div className="modal">
            <div className="modal-overlay"></div>
            <div className="modal-content-pay">
              <div className="heading-container">
                <p className="heading-text">Confirm Deletion</p>
              </div>
              <hr />
              <p style={{ fontSize: "17px", color: "#364545" }}>
                {DeletecardmodalMessage}
              </p>

              <div className="button-group">
                <button type="button" onClick={handleCancelDelete}>
                  Cancel
                </button>
                <button
                  type="button"
                  style={{ backgroundColor: "rgb(167, 28, 28)" }}
                  onClick={handleConfirmDelete}
                >
                  Delete
                </button>
              </div>
            </div>
            do
          </div>
        </>
      )}

      <button onClick={toggleNewPaymentForm} className="submit-button">
        {showNewPaymentForm ? (
          <>
            {newText ? <>Update Payment Details</> : <>Save Payment Details</>}
            <FontAwesomeIcon
              icon={faChevronUp}
              style={{ marginLeft: "20px" }}
            />
          </>
        ) : (
          <>
            {newText ? <>Update Payment Details</> : <>Save Payment Details</>}
            <FontAwesomeIcon
              icon={faChevronDown}
              style={{ marginLeft: "20px" }}
            />
          </>
        )}
      </button>
      <br />
      {showNewPaymentForm && (
        <div id="card_container_input" className="card_container">
          {/* <a className="register_heading" style={{ fontSize: "20px" }}>
        Save new payment details:
      </a> */}
          <Form
            className="pay-input-form"
            form={form}
            // onFinish={handleSubmit}
            layout="vertical"
          >
            <div className="pay-form-row">
              {" "}
              {/* Add this container for the row */}
              <div className="pay-form-row-col" style={{ flexGrow: 9 }}>
                <Form.Item
                  //  label="Card expiry date"
                  required
                  //  tooltip="This is a required field"
                  className="form-item form-item-pay"
                  label={<span className="label-font">Card Number</span>}
                ></Form.Item>
                <div id="card-number"></div>
              </div>
              <div className="pay-form-row-col" style={{ flexGrow: 3 }}>
                <Form.Item
                  // label="Card expiry date"
                  required
                  //  tooltip="This is a required field"
                  className="form-item form-item-pay"
                  label={<span className="label-font">Card Expiry Date</span>}
                  // className="form-item form-item-pay"
                ></Form.Item>
                <div id="expiration-date" className="pay-form-field"></div>{" "}
                {/* Adjusted with className for styling */}
              </div>
            </div>

            <div className="pay-form-row">
              {" "}
              {/* Add this container for the row */}
              <div className="pay-form-row-col" style={{ flexGrow: 10 }}>
                <Form.Item
                  // label="Cardholder Name"
                  required
                  // tooltip="This is a required field"
                  className="form-item form-item-pay"
                  label={<span className="label-font">Cardholder Name</span>}
                  // className="form-item form-item-pay"
                ></Form.Item>
                <div id="card-holder-name"></div>
              </div>
              <div className="pay-form-row-col" style={{ flexGrow: 2 }}>
                <Form.Item
                  //  label="CVV (on the back of your card)"
                  required
                  //  tooltip="This is a required field"
                  className="form-item form-item-pay"
                  label={
                    <span className="label-font">
                      CVV{" "}
                      <small
                        style={{ fontSize: "11px", color: "#bfbdbd" }}
                      ></small>
                    </span>
                  }
                  // className="form-item form-item-pay"
                ></Form.Item>
                <div
                  className="pay-form-row"
                  style={{ justifyContent: "space-between" }}
                >
                  <div id="cvv" className="pay-form-field"></div>{" "}
                  {/* Adjusted with className for styling */}
                </div>
              </div>
            </div>
            {allFieldsRequiredError && (
              <div>
                <p
                  className="error-message text-danger ml-2"
                  style={{ fontSize: "14px", marginTop: "10px" }}
                >
                  Please ensure all required fields are provided.
                </p>
              </div>
            )}
            <button
              value="submit"
              id="submit"
              className={`submit-button ${clicked ? "clicked" : ""}`}
              disabled={!isFormValid}
              onClick={() => setClicked(true)}
              style={{
                backgroundColor: clicked ? "#e9e9e9" : "", // Light background color when clicked
              }}
            >
              {loading_dots ? (
                <span>
                  <span className="loading-dot"></span>
                  <span className="loading-dot"></span>
                  <span className="loading-dot"></span>
                </span>
              ) : (
                "Save Card"
              )}
            </button>
          </Form>
        </div>
      )}
    </div>
  );
};

export default PayPalComponent;
