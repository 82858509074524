import "./OurPromise.css";
import ServiceCard from "./ServiceCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
export default function OurPromise() {
  const handleBack = () => {
    window.history.back();
  };
  return (
    <div className="feature_container">
      <div className="arrow-container_feature">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={handleBack}
          className="back-icon-feature"
        />
      </div>
      <div className="container">
        <h2 className="text-left fs-1 fw-bold theme-color mt-4">Our Promise</h2>
        <div className="container w-100 my-5">
          <p className="text-left my-4 fs-5" style={{ color: "#364545" }}>
            At Resilient Technologies, we are committed to adhere to the highest
            standards for AI based software solutions. This is done by ensuring
            that we build quality in the product through agile software
            development and adoption of relevant standards such as BS ISO/IETC
            42001:2023 through in house policies.
          </p>
          <p className="text-left my-4 pr-5 fs-5" style={{ color: "#364545" }}>
            Following this standard we ensure that our AI systems are developed
            and deployed responsibly, prioritizing security, transparency, and
            sustainability.
          </p>
        </div>
        <div className="container d-flex justify-content-center p-5 flex-wrap values-container">
          <ServiceCard
            title="Transparency"
            description="We maintain clear and open communication with our stakeholders, ensuring that the processes and methodologies behind our AI solutions are easily understood and accessible.​"
          />
          <ServiceCard
            title="Accountability"
            description="Our AI systems are designed with accountability mechanisms that ensure responsible usage and provide mechanisms for feedback and redress.​"
          />
          <ServiceCard
            title="Security and Privacy"
            description="We prioritize the security and privacy of data, implementing robust measures to protect sensitive information and comply with all relevant data protection regulations."
          />
          <ServiceCard
            title="Sustainability"
            description="We are committed to minimizing the environmental impact of our AI operations, utilizing energy-efficient technologies and promoting sustainable practices across our organization.​"
          />
          <ServiceCard
            title="Fairness"
            description="Our AI systems are designed to be unbiased, ensuring fair treatment for all users and mitigating discrimination. We take every measure to ensure equality and fairness in all interactions."
          />
          <ServiceCard
            title="Reliability and Safety"
            description="We rigorously test our AI solutions to ensure they meet high standards of reliability and safety, providing dependable performance.​"
          />
        </div>
        <div className="container w-100 my-4 mb-5 px-0">
          <h2 className="text-left mt-5 fs-3" style={{ color: "#364545" }}>
            Our Commitment to You
          </h2>
          <p className="text-left my-4 fs-5" style={{ color: "#364545" }}>
            At Resilient Technologies, we believe in the power of AI to
            transform lives and drive progress. Our promise to you is built on a
            foundation of trust, integrity, and excellence. By our adherence, we
            ensure that our AI solutions are not only cutting-edge but also
            ethical and responsible. Our software solutions are bench-marked
            against following three criterias: <br />
            <ul className="my-3 custom-list">
              <li>Accuracy</li>
              <li>Reliability</li>
              <li>Timeliness</li>
            </ul>
            Join us in our journey to harness the power of AI for good, creating
            a future where technology serves humanity with transparency,
            fairness and respect.
          </p>
        </div>
      </div>
    </div>
  );
}
