import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import './styles.css';

function DateRangePicker({ minStartDate, maxEndDate, onDateRangeSelect }) {
  console.log('minStartDate', minStartDate, "maxEndDate", maxEndDate)
  const [startDate, setStartDate] = useState(new Date(minStartDate));
  const [endDate, setEndDate] = useState(new Date(maxEndDate));

  const startDatePickerRef = useRef(null);
  const endDatePickerRef = useRef(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date > endDate) {
      setEndDate(date);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    if (date < startDate) {
      setStartDate(date);
    }
  };

  const handleStartDateIconClick = () => {
    startDatePickerRef.current.setFocus(true);
  };

  const handleEndDateIconClick = () => {
    endDatePickerRef.current.setFocus(true);
  };

  const handleSubmit = () => {
    onDateRangeSelect({ startDate, endDate });
  };


  return (
    <div className="date-range-picker">
      <div className='Both_dates_container'>
        <div className="date-picker-container">
          <label htmlFor="start-date" style={{ fontSize: "15px", color: "#364545" }}>
            Start Date:
          </label>
          <div className="date-picker-wrapper">

            <DatePicker
              id="start-date"
              selected={startDate}
              minDate={new Date(minStartDate)}
              maxDate={endDate}
              onChange={handleStartDateChange}
              ref={startDatePickerRef}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd/MM/yyyy"
            />
            <div className="icon-wrapper">
              <FontAwesomeIcon
                color='#364545'
                icon={faCalendar}
                style={{ cursor: 'pointer' }}
                onClick={handleStartDateIconClick}
              />
            </div>
          </div>
        </div>

        <div className="date-picker-container">
          <label htmlFor="end-date" style={{ fontSize: "15px", color: "#364545" }}>
            End Date:
          </label>
          <div className="date-picker-wrapper">

            <DatePicker
              id="end-date"
              selected={endDate}
              minDate={startDate}
              maxDate={new Date(maxEndDate)}
              onChange={handleEndDateChange}
              ref={endDatePickerRef}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd/MM/yyyy"
            />
            <div className="icon-wrapper">
              <FontAwesomeIcon
                icon={faCalendar}
                style={{ cursor: 'pointer' }}
                color='#364545'
                onClick={handleEndDateIconClick}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="submitbutton">
        <button onClick={handleSubmit}>Confirm Dates</button>
      </div>
    </div>
  );
}

export default DateRangePicker;
