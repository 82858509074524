import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SignInPopupModal from "../../components/SinginBlock";
import RegisterUser from "../../components/RegisterBlock";

import "./styles.css";

const LoginRouter = () => {
  const [loginpageshow, setloginpageshow] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get("mode");
    setloginpageshow(mode !== "register");
  }, [location]);

  return (
    <div className="loginrouterdiv">
      {loginpageshow === true ? (
        <SignInPopupModal setloginpageshow={setloginpageshow} />
      ) : (
        <RegisterUser setloginpageshow={setloginpageshow} />
      )}
    </div>
  );
};

export default LoginRouter;
