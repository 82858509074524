import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./style.css"; // Import your CSS file

const VerificationEmailSent = () => {
  const navigate = useNavigate();
  const redirectToLogin = () => {
    navigate("/login"); // Replace with actual navigation code
  };

  // Function to handle "Go Back" action
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <>
      <div className="arrow-container">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={handleGoBack}
          className="back-icon-verification"
        />
      </div>
      <div className="Main_email_div">
        <div className="verification-email-sent">
          <h2>Verification Email Sent</h2>
          <p>
            A verification email has been sent to your email address. Please
            check your inbox including junk mail folder and follow instructions
            to activate your PIE account.
          </p>
          <button onClick={redirectToLogin} className="login-button">
            Login
          </button>
        </div>
      </div>
    </>
  );
};

export default VerificationEmailSent;
