import React, { useState, useEffect } from "react";
import DateRangePicker from "./DateStartFrom";
import { countDatesInRange } from "./DateFunctions/separatedatefromtime";
import "./TwoOptions.css";

function ParentComponent(propspassed) {
  const [selectionType, setSelectionType] = useState("all"); // 'all' or 'range'

  const handleDateRangeSelect = async (range) => {
    await propspassed.setTotalReviews(
      countDatesInRange(
        propspassed.processedDates,
        range["startDate"],
        range["endDate"]
      )
    );
    propspassed.setDateRange(range);
  };

  const handleAllReviewsSelect = () => {
    propspassed.setDateRange({
      startDate: "",
      endDate: "",
    });
    propspassed.setTotalReviews(propspassed.csvdata.length);
  };

  // Handle the checkbox change
  const handleCheckboxChange = (event) => {
    const { name } = event.target;
    setSelectionType(name);
    if (name === "all") {
      handleAllReviewsSelect();
    } else {
      // If 'range' is selected, you may want to trigger any specific logic for range selection
    }
  };

  return (
    <div className="parent-container">
      <div className="controls-container">
        <div>
          <label className="control-checkbox">
            <input
              type="radio"
              name="all"
              checked={selectionType === "all"}
              onChange={handleCheckboxChange}
            />
            <span style={{ fontSize: "15px", color: "#364545" }}>
              Select All Reviews
            </span>
          </label>
        </div>
        <div>
          <label className="control-checkbox">
            <input
              type="radio"
              name="range"
              checked={selectionType === "range"}
              onChange={handleCheckboxChange}
            />
            <span style={{ fontSize: "15px", color: "#364545" }}>
              Select a Date Range
            </span>
          </label>
        </div>
      </div>

      {selectionType === "range" && (
        <DateRangePicker
          minStartDate={propspassed.processedDates[0]}
          maxEndDate={
            propspassed.processedDates[propspassed.processedDates.length - 1]
          }
          onDateRangeSelect={handleDateRangeSelect}
        />
      )}

      <div style={{ marginTop: "0.5rem" }}>
        <p className="total-reviews">
          Number of reviews selected:{" "}
          <span className="total-reviews_number" style={{ paddingLeft: "6px" }}>
            {propspassed.totalReviews}
          </span>
        </p>
      </div>
    </div>
  );
}

export default ParentComponent;
