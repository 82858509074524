import React, { useState } from "react";
import TablePopup from "./Preview"; // Import the TablePopup component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import "./ToggleButton.css"; // Import your CSS styles

const FilePreviewToggle = (propspassed) => {
  const [isDescriptionVisible, setDescriptionVisible] = useState(false);

  const togglePopup = () => {
    propspassed.setPopupVisible(!propspassed.isPopupVisible);
  };

  const toggleDescription = () => {
    setDescriptionVisible(!isDescriptionVisible);
  };

  return (
    <>
      <div className="preview-toggle">
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <div>
            <span className="preview-text">View column names</span>
          </div>
          <div className="new-tooltip-container">
            <span className="info-icon">
              <FontAwesomeIcon
                icon={faCircleInfo}
                size="s"
                style={{ color: "#9e9e9e" }}
              />
            </span>
            <div className="tooltip-below">
              Use the toggle button to view columns in the uploaded file so that
              you can select the correct columns for the required fields as
              requested below.
            </div>
          </div>
        </div>

        <label className="switch">
          <input
            type="checkbox"
            checked={propspassed.isPopupVisible}
            onChange={togglePopup}
          />
          <span className="slider round"></span>
        </label>
      </div>

      {propspassed.isPopupVisible && (
        <TablePopup
          headers={propspassed.headers}
          data={propspassed.data}
          onClose={() => propspassed.setPopupVisible(false)}
        />
      )}
    </>
  );
};

export default FilePreviewToggle;
