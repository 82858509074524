export default function ServiceCard({ title, description }) {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center border rounded card-hw mx-4 my-3 shadow">
      <h6 className="card-title text-center fs-5 mt-4">{title}</h6>
      <div className="w-25 my-3 bar-height bg-white"></div>
      <p className="card-subtitle text-center mb-2 fs-6 px-3 pb-3 text-light text-shadow">
        {description}
      </p>
    </div>
  );
}
