import { useEffect, useRef, useState } from "react";
import Dashboard from "../../components/FindPerceptionBlock/Dashboard.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CryptoJS from "crypto-js";
import {
  faArrowLeft,
  faShareFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Spin } from "antd";
import ShareableLinkModal from "../../components/FindPerceptionBlock/ShareableLinkModal.jsx";
import { formatDateAndTime } from "../../services/formatdatetime.js";
import("./index.css");

const apiUrl = process.env.REACT_APP_API_URL;

const ReportsPage = () => {
  const [isViewingFile, setIsViewingFile] = useState(false);
  const [reviewsDet, setReviewsDet] = useState([]);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");
  const [userId, setUserId] = useState(null);
  const [editingReportId, setEditingReportId] = useState(null);
  const [editedReportName, setEditedReportName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [reportToDelete, setReportToDelete] = useState(null);
  const [reportNameToDelete, setReportNameToDelete] = useState(null);
  const [reportreviewsToDelete, setReportreviewsToDelete] = useState(null);
  const DeleteReportmodalMessage =
    "Are you sure you want to delete this report? Once deleted, it cannot be recovered. Please confirm your action.";
  const [DeleteReportmodalOpen, setDeleteReportModalOpen] = useState(false);

  const [reportNameError, setReportNameError] = useState({
    id: null,
    message: null,
  });
  const inputRef = useRef(null);

  useEffect(() => {
    // Define the async function inside the useEffect
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}decode/decodetoken`,
          {
            method: "POST",
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user ID");
        }

        const data = await response.json();

        if (data.userId != userId) {
          setUserId(data.userId);
        }
      } catch (error) {
        console.error("Failed to load user ID", error);
      }
    };

    // Call the async function
    fetchData();
  }, []); // Dependency array is empty to ensure this effect runs once on mount

  useEffect(() => {
    const fetchReviews = async () => {
      setIsLoading(true);
      if (!userId) {
        console.error("No user ID found in local storage or session storage.");
        return;
      }

      try {
        const response = await fetch(`${apiUrl}/get_reviews`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ customer_id: userId }),
        });

        if (response.ok) {
          const reviews = await response.json();
          setReviewsDet(reviews);
          // Process and display these reviews as needed
        } else {
          // Handle non-200 responses
          console.error("Failed to fetch reviews:", response.status);
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setIsLoading(false); // Set loading to false when fetch completes, regardless of success
      }
    };
    fetchReviews();
  }, [selectedReportId, userId]);

  const handleReportNameChange = (e) => {
    e.stopPropagation();
    setEditedReportName(e.target.value);
  };

  useEffect(() => {
    if (inputRef.current && isEditing) {
      // Ensure we only focus if the input isn't already focused
      if (document.activeElement !== inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isEditing, editedReportName]); // Retaining editedReportName if absolutely necessary

  const handleRenameReport = async (reportId) => {
    if (!editedReportName || editedReportName.trim().length === 0) {
      // If the new report name is empty or contains only spaces, show an error message
      setReportNameError("Report name cannot be empty.");
      return; // Don't proceed with renaming
    }
    // Check if the new report name already exists in reviewsDet
    const isDuplicateName = reviewsDet.some(
      (review) =>
        review.report_id !== reportId && review.reportname === editedReportName
    );

    if (isDuplicateName) {
      setReportNameError({
        id: reportId,
        message: "Report name already exists",
      });

      return;
    }
    try {
      const response = await axios.put(`${apiUrl}/update_report/${reportId}`, {
        new_report_name: editedReportName,
      });
      if (response.status === 200) {
        // Update the list of reports with the new name
        const updatedReviews = reviewsDet.map((review) => {
          if (review.report_id === reportId) {
            return { ...review, reportname: editedReportName };
          }
          return review;
        });
        setReviewsDet(updatedReviews);
        // alert('Report renamed successfully');
      }
    } catch (error) {
      console.error("Failed to rename the report", error);
      alert("Failed to rename the report");
    }
    // Exit edit mode
    setEditingReportId(null);
  };

  const handleSave = () => {
    handleRenameReport(editingReportId);
    setEditingReportId(null);
    setIsEditing(false);
  };

  const handleConfirmDelete = () => {
    if (reportToDelete) {
      handleDeleteReport(reportToDelete);
      setDeleteReportModalOpen(false);
      setReportNameToDelete(null);
      setReportreviewsToDelete(null);
      setReportToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setDeleteReportModalOpen(false);

    setReportNameToDelete(null);
    setReportreviewsToDelete(null);
    setReportToDelete(null);
  };

  // Report component
  const Report = ({ report }) => {
    // Example handlers for buttons
    const handleEdit = (event) => {
      event.stopPropagation();
      setReportNameError({ id: null, message: null });
      setIsEditing(true);
      // Toggle edit mode
      setEditedReportName(report.reportname);
      setEditingReportId(report.report_id);
    };

    const handleDelete = (event) => {
      event.stopPropagation();
      setReportToDelete(report.report_id);
      setReportNameToDelete(
        report.reportname ? report.reportname : report.reviews_filename
      );
      setReportreviewsToDelete(report.no_reviews_processed);
      setDeleteReportModalOpen(true);
      // Define your delete logic here
    };

    const handleShare = (event) => {
      event.stopPropagation();
      createShareableLink(report.report_id);
      // Define your share logic here
    };

    const handleViewFile = (event) => {
      event.stopPropagation();
      const reportData = {
        reportId: report.report_id,
        reportName: report.reportname,
      };

      sessionStorage.setItem("reportData", JSON.stringify(reportData));

      // Open the new tab and navigate to /report/view
      window.open("/report/viewreport", "_blank");
    };

    const { date, time } = formatDateAndTime(report.datetime);

    return (
      <>
        <div
          className={`report_style ${
            editingReportId === report.report_id ? "editing" : ""
          }`}
          onClick={handleViewFile}
        >
          <div className="report_details">
            <p title={report.reportname}>
              Report Name:{" "}
              {editingReportId === report.report_id ? (
                <input
                  key={report.report_id + "_input"}
                  ref={inputRef}
                  type="text"
                  name="report"
                  value={editedReportName}
                  maxLength={50}
                  onClick={(e) => e.stopPropagation()}
                  onChange={handleReportNameChange}
                  onBlur={() => handleSave(report.report_id)}
                  className={isEditing ? "no-hover" : ""}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSave();
                      e.preventDefault(); // Prevent default to avoid any form submission behavior
                    }
                  }}
                />
              ) : (
                <span className="report_text_number">
                  {report.reportname.length > 35
                    ? report.reportname.slice(0, 35) + "..."
                    : report.reportname}
                </span>
              )}
            </p>

            {reportNameError.id === report.report_id &&
              reportNameError.message && (
                <p className="report_name_error_tab" style={{ color: "red" }}>
                  {reportNameError.message}
                </p>
              )}
            <p>
              Number of Reviews Processed:{" "}
              <span className="report_text_number">
                {report.no_reviews_processed}
              </span>
            </p>
            <p>
              Report creation date:{" "}
              <span className="report_text_number">{date}</span>
            </p>
            <p>
              Report creation time:{" "}
              <span className="report_text_number">{time}</span>
            </p>
          </div>

          <div className="report_actions">
            <div className="report_row">
              <span className="report_action" onClick={handleEdit}>
                Rename
              </span>
              <span className="separator">|</span>
              <span className="report_action" onClick={handleDelete}>
                Delete
              </span>
            </div>
            <span className="separator_middle">|</span>
            <div className="report_row">
              <span className="report_action" onClick={handleShare}>
                Share
              </span>
              <span className="separator">|</span>
              <span className="report_action" onClick={handleViewFile}>
                View Report
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleBack = () => {
    setIsViewingFile(false);
    setSelectedReportId(null);
  };

  const fetchReviews = async () => {
    if (!userId) {
      console.error("No user ID found in local storage or session storage.");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/get_reviews`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customer_id: userId }),
      });

      if (response.ok) {
        const reviews = await response.json();
        setReviewsDet(reviews);
        // Process and display these reviews as needed
      } else {
        // Handle non-200 responses
        console.error("Failed to fetch reviews:", response.status);
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  const handleDeleteReport = async (reportId) => {
    try {
      const response = await axios.delete(
        `${apiUrl}/delete_report/${reportId}`
      );
      if (response.status === 200) {
        // You may want to fetch the updated list of reports after deletion
        fetchReviews();
        // alert('Report deleted successfully');
      }
    } catch (error) {
      console.error("Failed to delete the report", error);
      // alert('Failed to delete the report');
    }
  };

  const encryptReportId = async (reportId) => {
    try {
      const secretName = process.env.REACT_APP_Crypto_Secret_Key;
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}variable/getkeyvaultvalue`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ secretName }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      const encrypted = CryptoJS.AES.encrypt(
        reportId.toString(),
        data.secretValue
      ).toString();
      // URL encode the encrypted string to make it URL-safe
      return encodeURIComponent(encrypted);
    } catch (error) {
      console.error("Error initializing PayPal SDK:", error);
    }
  };

  const createShareableLink = async (reportId) => {
    const encryptedId = await encryptReportId(reportId);
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const shareableLink = `${baseUrl}/reports/${encryptedId}`;
    setGeneratedLink(shareableLink);
    setIsModalVisible(true); // Show the modal
    // Copying the link to the clipboard
    navigator.clipboard.writeText(shareableLink).then(
      () => {},
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };
  // Function to handle "Go Back" action
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <>
      {!isViewingFile && (
        <div className="back-arrow-div">
          <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={handleGoBack}
            className="back-icon"
          />
        </div>
      )}

      <div className="main_div_reports_page">
        <div>
          {isLoading ? (
            <div className="centered-loader_report_tab">
              <Spin size="large" />
            </div>
          ) : isViewingFile ? (
            <>
              <div
                className="arrow-container_reports"
                style={{ marginBottom: "2.5rem" }}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  onClick={handleBack}
                  className="back-icon"
                />
              </div>
              {selectedReportId && (
                <div>
                  <div className="share-button-2">
                    {reviewsDet
                      .filter((review) => review.report_id === selectedReportId)
                      .map((review) => (
                        <h3
                          style={{
                            fontSize: "18px",
                            color: "#4E4E4E",
                            fontFamily: "sans-serif",
                            fontWeight: "bolder",
                          }}
                          key={review.report_id}
                        >
                          Report Name:{" "}
                          {review.reportname
                            ? review.reportname.length > 35
                              ? review.reportname.slice(0, 35) + "..."
                              : review.reportname
                            : review.reviews_filename.length > 35
                            ? review.reviews_filename.slice(0, 35) + "..."
                            : review.reviews_filename}
                        </h3>
                      ))}
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents the click from propagating to the document
                        createShareableLink(selectedReportId);
                      }}
                    >
                      Share Report <FontAwesomeIcon icon={faShareFromSquare} />
                    </button>
                  </div>
                  <Dashboard
                    selecteduserid={userId}
                    selectedReportId={selectedReportId}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              {reviewsDet.length > 0 ? (
                <>
                  <p className="all_reports_p">All Reports:</p>
                  <div className="reports_container">
                    {reviewsDet
                      .slice()
                      .reverse()
                      .map((report, index) => (
                        <Report key={index} report={report} />
                      ))}
                  </div>
                </>
              ) : (
                <div className="no-reports">
                  No reports have been generated yet.
                </div>
              )}
            </>
          )}
          <ShareableLinkModal
            isVisible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            link={generatedLink}
          />
        </div>
      </div>

      {DeleteReportmodalOpen && (
        <>
          <div className="modal">
            <div className="modal-overlay"></div>
            <div className="modal-content-pay">
              <div className="heading-container">
                <p className="heading-text">Confirm Deletion</p>
              </div>
              <hr />
              <p style={{ fontSize: "17px", color: "#364545" }}>
                {DeleteReportmodalMessage}
              </p>

              <p style={{ fontSize: "17px", color: "#364545" }}>
                Report Name:{" "}
                <span style={{ fontWeight: "bold" }}>{reportNameToDelete}</span>
              </p>
              <p
                style={{
                  fontSize: "17px",
                  color: "#364545",
                  marginTop: "-15px",
                }}
              >
                Report Reviews:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {reportreviewsToDelete}
                </span>
              </p>

              <div className="button-group">
                <button type="button" onClick={handleCancelDelete}>
                  Cancel
                </button>
                <button
                  type="button"
                  style={{ backgroundColor: "rgb(167, 28, 28)" }}
                  onClick={handleConfirmDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ReportsPage;
