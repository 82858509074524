export const capture3DSPayment = async (userId: string, orderId: string, totalReviews: number) => {
    const nodeappUrl = process.env.REACT_APP_NODE_SERVER_URL;
    console.log('USerID and OrderID in capture Func: ', userId,"---", orderId)
    return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${nodeappUrl}capture3ds/getpayment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          orderID: orderId, 
          totalReviews: totalReviews
        }),
        credentials: "include",
      });
   console.log('rESPONSE',response)
   resolve({response})
      // if (!response.ok) {
      //   console.error("Failed to capture 3DS payment");

      //   reject({status: "failed"})
      // } else {
      // //   const result = await response.json();
      // //   console.log("3DS payment captured successfully:", result);
      //   resolve({response})
      // }
    } catch (error) {
      console.error("Error capturing 3DS payment:", error);
      reject({status: "failed"})
    }
  })
  };