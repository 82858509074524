import React, { useState, useEffect } from "react";
import { Typography, Spin } from "antd";
import "./style.css";
const { Text } = Typography;

const EditPackageDetails = ({ userId }) => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  // Function to fetch user subscription data
  const fetchUserData = async () => {
    setLoading(true);
    try {
      // Replace with your actual fetch logic
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}package/usersubscribedpackage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch package details");
      }
      const data = await response.json();
      setUserData(data.data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  if (loading || !userData) {
    return (
      <div className="centered-loader">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="Main_div_register">
      <p className="register_heading editaccount-heading" style={{ fontSize: "30px" }}>
        Package Details
      </p>
      {userData.packageDetails.package_duration == 0 &&
        userData.packageDetails.id === 1 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
              alignItems: "center",
              backgroundColor: "#f8f9fa", // A light gray background that is gentle on the eyes
              padding: "15px", // Add some padding to create space around the text

              borderRadius: "8px", // Slightly rounded corners for a modern look
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // A slight shadow for depth
            }}
          >
            <p className="styled-font" style={{ margin: 0, color: "#ea7581" }}>
              {" "}
              Your free trial has ended. To continue utilizing services, you may
              proceed with a "pay-as-you-go" payment upon your next file
              submission.
            </p>
          </div>
        )}

      <div className="package-name-container">
        <Text className="styled-font">Subscribed package name:</Text>
        <Text className="bold-styled-font">{userData.packageDetails.name}</Text>
      </div>

      {userData.packageDetails.id === 1 && (
        <>
          <div className="package-name-container">
            <Text className="styled-font">Total free reviews allowed:</Text>
            <Text className="styled-font">
              {userData.packageDetails.no_free_reviews}
            </Text>
          </div>

          <div className="package-name-container">
            <Text className="styled-font">Total free reviews processed:</Text>
            <Text className="styled-font">{userData.reviews_processed}</Text>
          </div>

          <div className="package-name-container">
            <Text className="styled-font">Total free reviews remaining:</Text>
            <Text
              className="styled-font"
              style={{
                color:
                  ((userData && userData.packageDetails.no_free_reviews) || 0) -
                    ((userData && userData.reviews_processed) || 0) ===
                  0
                    ? "#ea7581"
                    : "",
              }}
            >
              {((userData && userData.packageDetails.no_free_reviews) || 0) -
                ((userData && userData.reviews_processed) || 0)}
            </Text>
          </div>

          <div className="package-name-container">
            <Text className="styled-font">Free reviews duration left:</Text>
            <Text
              className="styled-font"
              style={{
                color:
                  userData.packageDetails.package_duration === 0
                    ? "#ea7581"
                    : "",
              }}
            >
              {userData.packageDetails.package_duration} days
            </Text>
          </div>
        </>
      )}

      {userData.compensatory_reviews != 0 && (
        <div className="package-name-container">
          <Text className="styled-font">Complimentary reviews remaining:</Text>
          <Text className="styled-font">{userData.compensatory_reviews}</Text>
        </div>
      )}

      <div className="package-name-container">
        <Text className="styled-font">Cost per paid review:</Text>
        <Text className="styled-font">£ {userData.per_review_price}</Text>
      </div>
    </div>
  );
};

export default EditPackageDetails;
