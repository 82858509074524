import React, { useState, useEffect } from "react";

import "./style.css";

import { Button, Form, Input, Spin, Row, Col } from "antd";

const Edituserdetails = ({ userId }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // Initialize a state to track the pristine (unchanged) state of the form
  const [isFormPristine, setIsFormPristine] = useState(true);
  const [userData, setUserData] = useState(null);

  const fetchUserData = async () => {
    setLoading(true); // Show loading indicator while fetching
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}user/getUserbyId`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }
      const data = await response.json();
      setUserData(data);
      form.setFieldsValue({
        email: data.email,
        firstName: data.firstname,
        secondName: data.lastname,
        backupEmail: data.backup_email,
        contactNumber: data.contact_no,
      });
      setIsFormPristine(true); // Reset form to pristine state
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserData();
    }
  }, [userId]); // Dependency array ensures this effect runs once on mount or when userId changes

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}edituseraccount/editUserData`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Include other necessary headers
          },
          body: JSON.stringify({ userdata: values }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }

      const result = await response.json();
      if (result.status == "passed") {
        setLoading(false);
        if (
          userData.firstName != values.firstName ||
          userData.lastName != values.secondName
        ) {
          // Retrieve the existing userData from localStorage and parse it into an object
          const userData = JSON.parse(localStorage.getItem("userData"));

          userData.firstName = values.firstName;
          userData.lastName = values.secondName;

          // Convert the updated userData object back into a string and save it back to localStorage
          localStorage.setItem("userData", JSON.stringify(userData));

          // Explicitly call syncUserData for the current tab
          window.dispatchEvent(new Event("localstorage-update"));
        }
        fetchUserData();
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      return null;
    }
  };

  const areValuesEqual = (value1, value2) => {
    if (
      (value1 === null || value1 === undefined || value1 === "") &&
      (value2 === null || value2 === undefined || value2 === "")
    ) {
      return true;
    }
    return value1 === value2;
  };

  const deepCompare = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !deepCompare(val1, val2)) ||
        (!areObjects && !areValuesEqual(val1, val2))
      ) {
        return false;
      }
    }

    return true;
  };

  const isObject = (object) => {
    return object != null && typeof object === "object";
  };

  if (loading || !userData) {
    // Show centered loading indicator
    return (
      <div className="centered-loader">
        <Spin size="large" />
      </div>
    );
  }

  const onValuesChange = (_, allValues) => {
    const initialValues = {
      email: userData?.email || "",
      firstName: userData?.firstname || "",
      secondName: userData?.lastname || "",
      backupEmail: userData?.backup_email || "",
      contactNumber: userData?.contact_no || "",
    };

    const isFormPristine = deepCompare(allValues, initialValues);
    setIsFormPristine(isFormPristine);
  };

  return (
    <div className="Main_div_personal">
      <p className="register_heading editaccount-heading" style={{ fontSize: "30px" }}>
        Edit Personal Details
      </p>
      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        layout="vertical"
        initialValues={{
          email: userData?.email,
          firstName: userData?.firstname,
          secondName: userData?.lastname,
          backupEmail: userData?.backup_email,
          contactNumber: userData?.contact_no,
        }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="email"
              label={
                <span className="label-font" style={{ marginTop: "-0.55rem" }}>
                  Email<span className="required-asterisk">*</span>
                </span>
              }
              rules={[
                {
                  type: "email",
                  message: "The input is not valid email.",
                },
                {
                  required: true,
                  message: "Please provide your email.",
                },
              ]}
              className="form-item label-font"
              required={false}
            >
              <Input
                disabled
                maxLength={50}
                style={{ color: "#999999", marginTop: "-0.5rem" }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="backupEmail"
              label={
                <span className="label-font">
                  Backup Email Address{" "}
                  <small style={{ fontSize: "11px", color: "#bfbdbd" }}>
                    (Optional)
                  </small>
                </span>
              }
              dependencies={["email"]} // Add this line
              rules={[
                {
                  type: "email",
                  message: "The input is not valid email.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("email") !== value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Backup email cannot be the same as the email.")
                    );
                  },
                }),
              ]}
            >
              <Input
                className="input-field"
                maxLength={50}
                placeholder="Enter your backup email"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="firstName"
              label={
                <span className="label-font">
                  First Name<span className="required-asterisk">*</span>
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Please provide your first name.",
                },
                {
                  pattern: /^[A-Za-z]+(?:\s+[A-Za-z]+)*$/,
                  message: "first name must contain only english letters.",
                },
              ]}
              className="form-item"
              style={{ marginTop: "-0.55rem" }}
              required={false}
            >
              <Input
                className="input-field"
                style={{ marginTop: "-0.5rem" }}
                maxLength={30}
                placeholder="Enter your first name"
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="secondName"
              label={
                <span className="label-font">
                  Last Name<span className="required-asterisk">*</span>
                </span>
              }
              rules={[
                {
                  pattern: /^[A-Za-z]+(?:\s+[A-Za-z]+)*$/,
                  message: "Last name must contain only english letters.",
                },
                {
                  required: true,
                  message: "Please provide your last name.",
                },
              ]}
              className="form-item"
              style={{ marginTop: "-0.55rem" }}
              required={false}
            >
              <Input
                className="input-field"
                style={{ marginTop: "-0.5rem" }}
                maxLength={30}
                placeholder="Enter your last name"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="contactNumber"
              label={
                <span className="label-font">
                  Contact Number{" "}
                  <small style={{ fontSize: "11px", color: "#bfbdbd" }}>
                    (Optional)
                  </small>
                </span>
              }
              className="form-item"
            >
              <Input
                className="input-field"
                placeholder="Enter your contact number"
                maxLength={30}
              />
            </Form.Item>
          </Col>
        </Row>

        <div class="register-button-container">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="submit-button"
              disabled={isFormPristine || loading}
            >
              {loading ? (
                <span>
                  <span className="loading-dot"></span>
                  <span className="loading-dot"></span>
                  <span className="loading-dot"></span>
                </span>
              ) : (
                "Submit"
              )}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default Edituserdetails;
