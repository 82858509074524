import React, { useEffect, useState } from "react";
import "./KeyOpinionTopics.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
const apiUrl = process.env.REACT_APP_API_URL;

const KeyOpinionTopics = ({ onMore, selectedReportId }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/KeyOpinionsProd/${selectedReportId}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const jsonData = await response.json();
        const parsedData = JSON.parse(jsonData);
        setData(parsedData);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedReportId]);

  if (isLoading) return <div>Loading...</div>;
  if (error)
    return (
      <>
        {" "}
        <h3 style={{ color: "#4e4e4e", fontSize: "24px" }}>
          Key Opinion Topics
        </h3>
        <div>
          Data unavailable: Please ensure you provide enough reviews and select
          the correct column for analysis.
        </div>
      </>
    );
  if (!data || data.length === 0)
    return (
      <>
        {" "}
        <h3 style={{ color: "#4e4e4e", fontSize: "24px" }}>
          Key Opinion Topics
        </h3>
        <div>
          Data unavailable: Please ensure you provide enough reviews and select
          the correct column for analysis.
        </div>
      </>
    );

  const getFontSize = (index) => {
    // Largest font size for the first item and decreasing for each subsequent item
    const maxFontSize = 30;
    const minFontSize = 14;
    return `${Math.max(maxFontSize - index * 2, minFontSize)}px`;
  };

  return (
    <div className="opin-section d-flex">
      <div className="opin-header">
        <div>
          <h3>
            Key Opinion Topics
            <div className="new-tooltip-container">
              <span className="info-icon">
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  size="s"
                  style={{
                    color: "#9e9e9e",
                    paddingTop: "5px",
                    paddingLeft: "10px",
                  }}
                />
              </span>
              <div className="tooltip-below">
                <a id="content_word_cloud">
                  This section contains the most discussed topics. Click 'More'
                  to explore all the topics along with their associated
                  opinions.
                </a>
              </div>
            </div>
          </h3>
        </div>
      </div>

      <div className="topics-section">
        {data.slice(0, 5).map((item, index) => (
          <div
            key={index}
            className={`opin`}
            style={{
              color: "#686868",
              fontSize: getFontSize(index),
            }}
          >
            {item.category}
          </div>
        ))}
      </div>
      <div className="button-more">
        <button onClick={onMore} className="more-button">
          More
        </button>
      </div>
    </div>
  );
};

export default KeyOpinionTopics;
