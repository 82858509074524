import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { verify3DS } from '../../common/utils/verify3DS';

function ErrorModal({ setShow3DSModal,setIsButtonDisabled, setPopupBlocked, message }) {
  const handleCloseModal = () => {
    setShow3DSModal(false);
    setIsButtonDisabled(false);
    setPopupBlocked(false);
  };

  return (
    <div className="modal">
      <div className="modal-overlay"></div>
      <div className="modal-content-pay">
        <div className="heading-container">
          <p className="heading-text m-0" style={{ color: "#364545" }}>
            3DS Verification Failed
          </p>
          <div className="ml-2 mt-1">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ fontSize: "20px", color: "#C41E3A" }}
            />
          </div>
        </div>
        <hr />
        <p style={{ fontSize: "17px", color: "#364545" }}>
          {message}
        </p>

        <div className="button-group">
          <button
            type="button"
            onClick={handleCloseModal}
            style={{ backgroundColor: "#880808" }}
          >
            Cancel
          </button>
  
        </div>
      </div>
    </div>
  );
}

export default ErrorModal;
