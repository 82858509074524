import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "antd";
import "./style.css"; // Import your CSS file

const DeactivateAccount = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errormessage, seterrormessage] = useState(null);

  // Function to handle "Go Back" action
  const handleGoBack = () => {
    window.history.back();
  };

  const handleSignOut = async (shouldReload = false) => {
    try {
      await fetch(`${process.env.REACT_APP_NODE_SERVER_URL}user/signout`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      localStorage.removeItem("userData");

      // Broadcast the sign-out event to other tabs
      localStorage.setItem("signOutEvent", Date.now().toString());

      if (shouldReload) {
        // Broadcast a custom event to trigger reload in all tabs
        window.dispatchEvent(new Event("signout-reload"));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      seterrormessage(null);
      // Function to fetch userId from the backend
      const fetchUserId = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_NODE_SERVER_URL}suspend/deactivate`,
            {
              method: "POST",
              credentials: "include", // Needed to include cookies
            }
          );

          if (!response.ok) {
            throw new Error("Failed to fetch user ID");
          }

          const data = await response.json();
          if (data.status === "passed") {
            await handleSignOut();
            setLoading(false);
            navigate("/accountdeactivated");
          } else {
            setLoading(false);
            seterrormessage(data.message);
          }
        } catch (error) {
          setLoading(false);
        }
      };

      fetchUserId();
    } catch (error) {
      seterrormessage(
        "Account deactivation was unsuccessful. Please try again later."
      );
    }
  };

  return (
    <>
      <div className="arrow-container">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={handleGoBack}
          className="back-icon"
        />
      </div>
      <div className="Main_email_div">
        <div className="verification-email-sent">
          <h2>Account Deactivation</h2>
          <p>Are you sure you want to deactivate your account?</p>
          <p>
            By deactivating your account, you will no longer have access to your
            account, and all associated data will be permanently deleted, except
            for essential information required for future identification
            purposes. This includes basic account details necessary for
            administrative and security reasons.
          </p>
          <p>
            This action cannot be undone. If you wish to continue, please
            confirm your decision below.
          </p>
          <Button
            type="primary"
            htmlType="submit"
            className="confirm-button"
            disabled={loading}
            onClick={onFinish}
          >
            {loading ? (
              <span>
                <span className="loading-dot"></span>
                <span className="loading-dot"></span>
                <span className="loading-dot"></span>
              </span>
            ) : (
              <span
                className="label-font"
                style={{
                  color: "white",
                  fontSize: "17px",
                }}
              >
                Confirm
              </span>
            )}
          </Button>
          {errormessage && (
            <p
              style={{
                color: "red",
                marginTop: "1rem",
                marginBottom: "-0.5rem",
              }}
            >
              {errormessage}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default DeactivateAccount;
