import React, { useState } from "react";
import { Row, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { HeaderSection, LogoContainer } from "./styles";

const HeaderLogoOnly: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const FullPageLoader: React.FC = () => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255, 1)",
        zIndex: 1000,
      }}
    >
      <div
        className="centered-loader_report_tab"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spin size="large" />
      </div>
    </div>
  );

  const handleLogoClick = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault(); // Prevent the default navigation behavior
    setIsLoading(true);

    let redirectToPie = true;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}decode/decodetoken`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user ID");
      }

      const data = await response.json();
      if (!data || data.userId == null) {
        redirectToPie = false;
      }
    } catch (error) {
      console.error("Failed to load user ID", error);
      redirectToPie = false;
    }

    setIsLoading(false); // Stop loading before navigating

    if (redirectToPie) {
      navigate("/pie"); // Use navigate to go to "/pie"
    } else {
      navigate("/"); // Use navigate to stay on "/"
    }
  };

  return (
    <>
      {isLoading && <FullPageLoader />}
      <HeaderSection>
        <Container>
          <Row justify="space-between">
            <LogoContainer
              aria-label="homepage"
              onClick={handleLogoClick}
              to="#" // Change the 'to' prop to '#' to prevent default navigation
            >
              <div className="logo-container">
                <SvgIcon src="pie5.svg" width="80px" height="50px" />
              </div>
            </LogoContainer>
          </Row>
          <hr />
        </Container>
      </HeaderSection>
    </>
  );
};

export default HeaderLogoOnly;
