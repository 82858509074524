import React, { useState, useEffect } from "react";
import "./DetailedKeyOpinionTopics.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faPlus,
  faMinus,
  faSmile,
  faFrown,
} from "@fortawesome/free-solid-svg-icons";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Import the styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const apiUrl = process.env.REACT_APP_API_URL;
function DetailedKeyOpinionTopics({ selectedReportId }) {
  const [data, setData] = useState([]);
  const [progress, setProgress] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTarget, setSelectedTarget] = useState({});
  const [gptData, setGptData] = useState({}); // Initialize gptData as an object
  const [gptData2, setGptData2] = useState({}); // Initialize gptData as an object
  const [isFetchingGptResponse, setIsFetchingGptResponse] = useState(false);
  const [statusText, setStatusText] = useState({});
  const isMobileKOT = window.innerWidth < 770;

  useEffect(() => {
    setGptData2({});
  }, [selectedReportId]);
  useEffect(() => {}, [selectedTarget]);

  useEffect(() => {
    fetch(`${apiUrl}/KeyOpinionsProd/${selectedReportId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((jsonData) => {
        try {
          const parsedData = JSON.parse(jsonData);
          setData(parsedData);
        } catch (parseError) {
          throw new Error("Error parsing JSON data");
        }
      })
      .catch((fetchError) => {
        setError(fetchError.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedReportId]);

  function formatGptResponse2(gptResponse, target) {
    const processBoldText = (text) => {
      // Split the text into segments by "**"
      const segments = text.split(/\*\*(.*?)\*\*/);
      return segments.flatMap((segment, index) => {
        // Handle newlines within segments by replacing them with <br /> elements
        const newlineSegments = segment
          .split("\n")
          .flatMap((newlineSegment, newlineIndex, array) => {
            return newlineIndex < array.length - 1
              ? [newlineSegment, <br key={`br-${index}-${newlineIndex}`} />]
              : newlineSegment;
          });

        // Even segments are regular text, odd segments are bold
        if (index % 2 === 0) {
          // Regular text, possibly with newlines handled
          return newlineSegments;
        } else {
          // Text that should be bold, without explicit newline handling here
          return (
            <strong style={{ fontSize: "15px" }} key={index}>
              {segment}
            </strong>
          );
        }
      });
    };

    const processResponse = (responseText) => {
      // Normalize the response text by trimming and removing outer quotation marks if present
      const normalizedResponse = responseText.trim().replace(/^"|"$/g, "");

      // Split the normalized response text by double newlines to separate sections
      const sections = normalizedResponse.split("\n\n");
      return sections.map((section, index) => {
        // Split the section by single newlines to process each line
        const lines = section.split("\n").filter((line) => line.trim() !== "");

        // Process each line
        const content = lines.map((line, lineIndex) => {
          if (line.trim().startsWith("-")) {
            // It's a list item; remove the dash and trim
            const listItemContent = line.replace("-", "").trim();
            // Process bold text within the list item
            const processedListItemContent = processBoldText(listItemContent);
            // Return the list item without bullet point
            return (
              <li
                key={lineIndex}
                style={{
                  listStyleType: "none",
                  padding: "5px",
                  fontSize: "15px",
                  margin: "0px 20px",
                  borderRadius: "0px",
                  backgroundColor: lineIndex !== 0 ? "#F9F9F9" : "#f1f1f1",
                  borderBottom: lineIndex !== 0 ? "none" : "1px solid #A9A9A9",
                }}
              >
                {" "}
                <p
                  style={{
                    margin: "5px",
                    fontSize: "15px",
                    color: "black",
                    textShadow: "2px 2px 5px #FFFFFF",
                  }}
                  key={lineIndex}
                >
                  {processedListItemContent}
                </p>
              </li>
            );
          } else {
            const processedLine = processBoldText(line.trim());
            return (
              <p
                style={{
                  fontSize: "15px",
                  color: "black",
                  textShadow: "2px 2px 5px #FFFFFF",
                }}
                key={lineIndex}
              >
                {processedLine}
              </p>
            );
          }
        });

        const containsListItems = content.some(
          (element) => element.type === "li"
        );
        return (
          <div
            key={index}
            className="review-section font-small"
            style={{ borderRadius: "4px", backgroundColor: "#fff" }}
          >
            {containsListItems ? (
              <ul
                style={{
                  listStyleType: "none",
                  padding: "0",
                  marginBottom: "40px",
                }}
              >
                {content}
              </ul>
            ) : (
              content
            )}
          </div>
        );
      });
    };

    const responseJsx = processResponse(gptResponse);

    return (
      <div className="gpt-response w-100">
        <strong style={{ fontSize: "15px", color: "#555555" }}>
          Analysis of Customer Reviews for "{target}"
        </strong>
        <br />
        <br /> {responseJsx}
      </div>
    );
  }

  const handleTargetClick = async (index, category) => {
    const isCurrentlyOpen = selectedTarget[index];
    const newSelectedTarget = isCurrentlyOpen ? null : category;
    setSelectedTarget((prev) => ({ ...prev, [index]: newSelectedTarget }));
    setProgress((prev) => ({ ...prev, [index]: 10 }));
    setStatusText((prev) => ({ ...prev, [index]: "Starting.." }));

    if (
      newSelectedTarget === null ||
      gptData[newSelectedTarget] ||
      gptData2[newSelectedTarget]
    )
      return;
    const progressUpdates = [
      { progress: 20, text: "Uploading..." },
      { progress: 40, text: "Processing.." },
      { progress: 60, text: "Generating.." },
      { progress: 80, text: "Finalizing.." },
    ];

    let currentUpdateIndex = 0;
    const timer = setInterval(() => {
      if (currentUpdateIndex < progressUpdates.length) {
        const update = progressUpdates[currentUpdateIndex++];
        setProgress((prev) => ({ ...prev, [index]: update.progress }));
        setStatusText((prev) => ({ ...prev, [index]: update.text }));
      } else {
        clearInterval(timer);
      }
    }, 4000);

    setIsFetchingGptResponse(true);
    try {
      const targetData = data.find(
        (item) => item.category === newSelectedTarget
      );
      if (targetData) {
        const response = await fetch(
          `${apiUrl}/generate-gpt-response-opinion`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              sentences: targetData.sentences,
              category: targetData.category,
              all_positive_assessments: targetData.all_positive_assessments,
              all_negative_assessments: targetData.all_negative_assessments,
              num_positive_assessments: targetData.num_positive_assessments,
              num_negative_assessments: targetData.num_negative_assessments,
              report_id: selectedReportId,
            }),
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setGptData2((prevData) => ({
          ...prevData,
          [newSelectedTarget]: result.gpt_response,
        }));
        setProgress((prev) => ({ ...prev, [index]: 100 }));
        setStatusText((prev) => ({ ...prev, [index]: "Completed!" }));
        setIsFetchingGptResponse(false);
      }
    } catch (error) {
      console.error("Error fetching GPT response:", error);
      setIsFetchingGptResponse(false);
      toast.error(error.message);
    }
  };

  if (isLoading) {
    return (
      <>
        <h3 style={{ color: "#4e4e4e", fontSize: "24px" }}>
          Key Opinion Topics
        </h3>
        <div>Loading content...</div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <h3 style={{ color: "#4e4e4e", fontSize: "24px" }}>
          Key Opinion Topics
        </h3>
        <div>
          Data unavailable: Please ensure you provide enough reviews and select
          the correct column for analysis.
        </div>
      </>
    );
  }

  if (data.length === 0) {
    return (
      <>
        <h3 style={{ color: "#4e4e4e", fontSize: "24px" }}>
          Key Opinion Topics
        </h3>
        <div>
          Data unavailable: Please ensure you provide enough reviews and select
          the correct column for analysis.
        </div>
      </>
    );
  }
  return (
    <div className="p-0 mt-4 mw-100">
      <h3 style={{ color: "#4e4e4e", fontSize: "24px", marginLeft: "2%" }}>
        Key Opinion Topics
        <div className="new-tooltip-container">
          <span className="info-icon">
            <FontAwesomeIcon
              icon={faCircleInfo}
              size="s"
              style={{
                color: "#9e9e9e",
                paddingTop: "5px",
                paddingLeft: "10px",
                cursor: "pointer",
              }}
            />
          </span>
          <div className="tooltip-below">
            <a id="content_word_cloud">
              This section highlights key opinions from customer feedback,
              showing the percentage of positive and negative sentiments for
              each opinion and actionable analysis.
            </a>
          </div>
        </div>
      </h3>
      <div className="d-flex flex-wrap align-items-start justify-content-start w-100 mw-100 height-md overflow-auto">
        {data.map((item, index) => {
          const accordionId = `accordion-${index}`;
          const collapseId = `collapse-${index}`;
          const response = gptData2[item.category]
            ? formatGptResponse2(gptData2[item.category], item.category)
            : null;
          return (
            <div
              key={index}
              className="rounded w-100 d-flex flex-column align-items-center"
            >
              <div
                className={`card-height mw-100 card-border d-flex align-items-center justify-content-between ${
                  selectedTarget[index] ? "border-highlight" : "card-border"
                }`}
                onClick={() => handleTargetClick(index, item.category)}
              >
                <div className="rounded card-height p-2 d-flex align-items-center">
                  <div
                    className={`icon-width rounded-circle d-flex justify-content-center align-items-center ${
                      item.num_negative_assessments >
                      item.num_positive_assessments
                        ? "bg-red"
                        : "bg-green"
                    }  ${
                      item.num_negative_assessments ===
                      item.num_positive_assessments
                        ? "bg-yellow"
                        : ""
                    }`}
                  >
                    <div className="rounded-circle bg-grey w-75 h-75 d-flex justify-content-center align-items-center fw-bold font-opensans">
                      {item.total_assessments}
                    </div>
                  </div>
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title fs-5 m-0 kot-size">
                      {item.category
                        .split(" ")
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()
                        )
                        .join(" ")}
                    </h5>
                  </div>
                  {!isMobileKOT ? <div className="d-flex mt-1">
                    <FontAwesomeIcon
                      icon={faSmile}
                      className="text-success fs-5 opacity-50"
                    />
                    <p className="font-small mx-1 fw-bold font-opensans my-0">
                      {Math.round(
                        (item.num_positive_assessments /
                          item.total_assessments) *
                          100
                      )}
                      %
                    </p>
                    <FontAwesomeIcon
                      icon={faFrown}
                      className="text-danger ml-1 fs-5 opacity-50"
                    />
                    <p className="font-small mx-1 fw-bold font-opensans my-0">
                      {Math.round(
                        (item.num_negative_assessments /
                          item.total_assessments) *
                          100
                      )}
                      %
                    </p>
                  </div> : "" }
                </div>
                <div className="accordin-container mt-1 d-flex justify-content-end align-items-center mr-2">
               {isMobileKOT ? <div className="d-flex mt-1 mr-2">
                    <FontAwesomeIcon
                      icon={faSmile}
                      className="text-success fs-5 opacity-50"
                    />
                    <p className="font-small mx-1 fw-bold font-opensans my-0">
                      {Math.round(
                        (item.num_positive_assessments /
                          item.total_assessments) *
                          100
                      )}
                      %
                    </p>
                    <FontAwesomeIcon
                      icon={faFrown}
                      className="text-danger ml-1 fs-5 opacity-50"
                    />
                    <p className="font-small mx-1 fw-bold font-opensans my-0">
                      {Math.round(
                        (item.num_negative_assessments /
                          item.total_assessments) *
                          100
                      )}
                      %
                    </p>
                  </div> : ""}
                  <div className="accordion-item">
                    <p className="accordion-header" id={accordionId}>
                      <button
                        className="accordion-button fw-bold"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${collapseId}`}
                        aria-expanded={
                          !selectedTarget[index] ? "true" : "false"
                        }
                        aria-controls={collapseId}
                      >
                        {!selectedTarget[index] ? (
                          <FontAwesomeIcon icon={faPlus} />
                        ) : (
                          <FontAwesomeIcon icon={faMinus} />
                        )}
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <div
                id={collapseId}
                className={`accordion-collapse ${
                  selectedTarget[index] ? `show` : `collapse`
                }`}
                aria-labelledby={accordionId}
              >
                <div
                  className="accordion-body font-small mx-auto analysis-box"
                  style={{ width: "80%" }}
                >
                  {response}
                  {isFetchingGptResponse &&
                    !response &&
                    selectedTarget[index] && (
                      <div className="d-flex flex-column align-items-center justify-content-between">
                        <div
                          key={index}
                          style={{ width: "50px", height: "50px" }}
                        >
                          <CircularProgressbar
                            value={progress[index]}
                            key={index}
                            maxValue={100}
                            strokeWidth={15}
                            text={`${progress[index]}%`}
                            styles={buildStyles({
                              strokeLinecap: "round",
                              textSize: "23px",
                              textStyle: "bold",
                              pathColor: "#6C757D",
                              textColor: "#6C757D",
                            })}
                          />
                        </div>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#6C757D",
                          }}
                        >
                          {statusText[index]}
                        </p>
                      </div>
                    )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <ToastContainer />
    </div>
  );
}

export default DetailedKeyOpinionTopics;
