import "./style.css";

const PrivacypolicyDeclaration = () => {
  return (
    <>
      <div className="policies_maindiv">
        <div className="cookie-policy-maindiv mb-0">
          <p style={{ fontSize: "25px", color: "#364545", fontWeight: "bold" }}>
            Resilient Technologies Ltd Privacy Policy
          </p>
          <p>Date of last revision: Nov 04, 2024</p>
        </div>
        <div className="cookie-policy-maindivs">
          <div class="cookie-table-container">
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Our contact details:
            </p>
            <p>
              Our company’s website address is:{" "}
              <a
                href="https://www.resilienttech.co.uk"
                target="_blank"
                style={{
                  color: "#0000ff",
                  textDecoration: "underline",
                  fontFamily: `"Motiva Sans Light", sans-serif`,
                  fontSize: "16px",
                }}
              >
                https://www.resilienttech.co.uk
              </a>{" "}
              and it is operated by Resilient Technologies Ltd who can be
              reached through Contact Us form on this website.
            </p>
            <p>
              Our products’ websites are{" "}
              <a
                href="//www.perceptionie.co.uk"
                target="_blank"
                style={{
                  color: "#0000ff",
                  textDecoration: "underline",
                  fontFamily: `"Motiva Sans Light", sans-serif`,
                  fontSize: "16px",
                }}
              >
                www.perceptionie.co.uk
              </a>{" "}
              and{" "}
              <a
                href="//www.voccopilot.co.uk"
                target="_blank"
                style={{
                  color: "#0000ff",
                  textDecoration: "underline",
                  fontFamily: `"Motiva Sans Light", sans-serif`,
                  fontSize: "16px",
                }}
              >
                www.voccopilot.co.uk
              </a>
              .
            </p>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
                marginTop: "30px",
              }}
            >
              The type of personal information we collect and how we collect it:
            </p>

            <p>
              We currently collect and process the following information from
              our company’s website, products websites or email
            </p>
            <ul>
              <li>
                Through Contact Us Form, Book Consultation Form and Newsletter
                Sign Up from our company’s website: First name, last name, email
                address, company name, country/region.
              </li>
              <li>
                Through Create Account or Edit Account on our product(s)
                website(s): First name, second name, email address, backup email
                address, contact number, company name and billing details.
              </li>
              <li>
                Cookies: Please visit the Cookie Policy pages of our company and
                product(s) website(s) for details.
              </li>
              <li>
                Through other communication channels: Proof of ID and proof of
                address for individuals.
              </li>
            </ul>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
                marginTop: "30px",
              }}
            >
              Why we collect personal information:
            </p>
            <p>
              Most of the personal information we process is provided to us
              directly by you for one of the following reasons:
            </p>
            <ul>
              <li>
                Through Contact Us Form and Book Consultation Form on our
                company’s website:
                <ol>
                  <li>
                    To administer your account and to provide the products and
                    services you requested from us.
                  </li>
                  <li>
                    If you consent to us contacting you for providing you
                    information about our products, services and content that
                    may be of interest to you.
                  </li>
                </ol>
              </li>
              <li>
                Through Newsletter Sign Up from our company’s website: To share
                latest updates about products, services, and industry updates.
              </li>
              <li>
                Through Create Account or Edit Account on our product(s)
                website(s):
                <ol>
                  <li>
                    To administer your account and to provision the product for
                    use after you sign up.
                  </li>
                  <li>
                    If you consent to us contacting you for providing you
                    information about our products, services and content that
                    may be of interest to you.
                  </li>
                </ol>
              </li>
              <li>
                Through Cookies: To facilitate login and uninterrupted use of
                the product. Please visit Cookie Policy pages of our company and
                product(s) website(s) for details.
              </li>
              <li>
                Through other channels of communication: To identify an
                individual before entering into any contracts or business with
                them.
              </li>
              <li>
                We use PayPal for payments and other services. If you wish to
                use one of these services and pay on our website, PayPal may
                collect the personal data you provide, such as payment and other
                identifying information. PayPal uses this information to operate
                and improve the services it provides to us and others, including
                for fraud detection, harm and loss prevention, authentication,
                analytics related to the performance of its services, and to
                comply with applicable legal requirements. The processing of
                this information will be subject to the PayPal Privacy Statement
                available at{" "}
                <a
                  href="https://www.paypal.com/uk/home"
                  target="_blank"
                  style={{
                    color: "#0000ff",
                    textDecoration: "underline",
                    fontFamily: `"Motiva Sans Light", sans-serif`,
                    fontSize: "16px",
                  }}
                >
                  PayPal.com
                </a>
                .
              </li>
            </ul>

            <p>
              We also receive personal information indirectly, from the
              following sources in the following scenarios:
            </p>
            <ul>
              <li>
                Through our product(s): Our product(s) that analyses your
                customers reviews for you will only ingest review IDs and
                reviews data. It <em>would not</em> ingest personal information
                of the customers. However, the reviews content might contain
                personal information mentioned by your customers. Our product
                uses personal information identification API to identify and
                remove any personal information but due to the nature of
                software systems, errors might occur, and personal information
                might not be completely removed from the reviews. After
                performing analyses, the customers review data submitted to us
                is deleted and only insights related to your company, it’s
                services and products are stored in the software product’s
                database.
              </li>
              <li>
                Through Analytics: This website does not collect any analytics
                data, but third-party plug-ins installed on our website{" "}
                <em>might</em> collect anonymous analytics data to help us
                improve offerings for the clients. Please visit Cookie Policy
                pages of our company and product(s) website(s) for details.
              </li>
              <li>
                Through reCAPTCHA: Our company website has implemented reCAPTCHA
                v3 on the site and your use of reCAPTCHA v3 is subject to the
                Google{" "}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  style={{
                    color: "#0000ff",
                    textDecoration: "underline",
                    fontFamily: `"Motiva Sans Light", sans-serif`,
                    fontSize: "16px",
                  }}
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a
                  href="https://policies.google.com/terms"
                  target="_blank"
                  style={{
                    color: "#0000ff",
                    textDecoration: "underline",
                    fontFamily: `"Motiva Sans Light", sans-serif`,
                    fontSize: "16px",
                  }}
                >
                  Terms of Use
                </a>
                . reCAPTCHA is only being used to fight spam and abuse on our
                site. reCAPTCHA will never be used for any other purposes such
                as determining credit worthiness, employment eligibility,
                financial status, or insurability of a user.
              </li>
            </ul>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
                marginTop: "30px",
              }}
            >
              Who we share your data with
            </p>
            <p>
              We may share your Data with the following groups of people for the
              following reasons:
            </p>
            <ol type="a">
              <li>
                our employees, agents and/or professional advisors – to assess
                requirements of the clients and hence provide advise;
              </li>
              <li>
                third party service providers who provide services to us which
                require the processing of personal data -to run marketing
                campaigns including newsletters;
              </li>
              <li>
                third party payment providers who process payments made over the
                Website – to process payments and refunds;
              </li>
              <li>
                relevant authorities – to facilitate the detection of crime or
                the collection of taxes or duties;
              </li>
            </ol>
            <p>In each case, in accordance with this privacy policy.</p>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
                marginTop: "30px",
              }}
            >
              Lawful bases we rely on for processing this information:
            </p>
            <p>
              Under the UK General Data Protection Regulation (UK GDPR), the
              lawful bases we rely on for processing this information are:
            </p>
            <ol>
              <li>
                Your <strong>consent</strong> for receiving product and services
                updates, and newsletter. You are able to remove your consent at
                any time. You can do this by contacting Resilient Technologies
                Ltd through Contact Us form on our company’s website or by
                clicking on the link(s) provided in product updates or
                newsletter emails.
              </li>
              <li>
                We have a <strong>contractual obligation</strong> so that you
                can use our product(s) after creating account for the product.
              </li>
              <li>
                We have a <strong>legitimate interest</strong> to serve our
                customers through our software product(s) for their benefit and
                in return generate revenue to grow our business and make it
                profitable.
              </li>
            </ol>
            <p>
              We balance our business needs with your privacy rights. Please
              feel free to ask for our Legitimate Interests Assessment and/or
              Data Protection Impact Assessment which are reviewed on regular
              basis or when new features are added to our products. Although
              every care is taken to remove Personal Identifiable Information
              (PII) from ingested customer reviews submitted by our customers’,
              but you remain Data Controller of your customers personal data so
              please ensure you have appropriate agreement with your customers
              to serve as legal basis in case the software cannot remove PII in
              full. Our Data Processing Addendum which is considered part of
              Terms and Conditions with you outlines details of our and your
              responsibilities towards protecting personal information of
              individuals.
            </p>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
                marginTop: "30px",
              }}
            >
              How we store your personal information
            </p>
            <p>
              Your information is securely stored on our computers or servers in
              line with our Data Protection Policy and Data Security Policy.
            </p>
            <p>
              The personal information collected through Contact Us and Book
              Consultation forms is kept for 12 months and then deleted from our
              systems.
            </p>
            <p>
              The personal information collected through the newsletter signup
              is deleted when you opt-out from receiving the newsletters.
            </p>
            <p>
              The personal information provided when creating an account to use
              our product(s) or their trials is kept until your account is
              active. All the personal information except first name and email
              address are deleted from our systems when you deactivate your
              account. The personal information retained is used to ensure that
              our product(s) is(are) used as per the agreed terms and conditions
              mentioned in the 'Terms and Conditions'.
            </p>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
                marginTop: "30px",
              }}
            >
              Your data protection rights
            </p>
            <p>Under data protection law, you have rights including:</p>
            <p>
              <strong>Your right of access</strong> - You have the right to ask
              us for copies of your personal information.
            </p>
            <p>
              <strong>Your right to rectification</strong> - You have the right
              to ask us to rectify personal information you think is inaccurate.
              You also have the right to ask us to complete information you
              think is incomplete.
            </p>
            <p>
              <strong>Your right to erasure</strong> - You have the right to ask
              us to erase your personal information in certain circumstances.
            </p>
            <p>
              <strong>Your right to restriction of processing</strong> - You
              have the right to ask us to restrict the processing of your
              personal information in certain circumstances.
            </p>
            <p>
              <strong>Your right to object to processing</strong> - You have the
              right to object to the processing of your personal information in
              certain circumstances.
            </p>
            <p>
              <strong>Your right to data portability</strong> - You have the
              right to ask that we transfer the personal information you gave us
              to another organisation, or to you, in certain circumstances.
            </p>
            <p>
              You are not required to pay any charge for exercising your rights.
              If you make a request, we have one month to respond to you.
            </p>
            <p>
              Please contact us using Contact Us page on our company’s website
              if you wish to make a request.
            </p>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bolder",
                marginTop: "30px",
              }}
            >
              How to complain
            </p>
            <p>
              If you have any concerns about our use of your personal
              information, you can make a complaint to us using the Contact Us
              page on our company’s website.
            </p>
            <p>
              You can also complain to the ICO if you are unhappy with how we
              have used your data.
            </p>
            <p>The ICO’s address:</p>
            <p>
              Information Commissioner’s Office
              <br />
              Wycliffe House
              <br />
              Water Lane
              <br />
              Wilmslow
              <br />
              Cheshire
              <br />
              SK9 5AF
            </p>

            <p>Helpline number: 0303 123 1113</p>
            <p>
              ICO website:{" "}
              <a
                href="https://www.ico.org.uk"
                target="_blank"
                style={{
                  color: "#0000ff",
                  textDecoration: "underline",
                  fontFamily: `"Motiva Sans Light", sans-serif`,
                  fontSize: "16px",
                }}
              >
                https://www.ico.org.uk
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacypolicyDeclaration;
