import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"; // Import the info-circle icon
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormInstance } from "antd/lib/form";
import "./style.css";

import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";

interface YourComponentProps {
  setloginpageshow: (value: boolean) => void;
}

interface SecurityQuestion {
  id: number;
  question: string;
}

interface Package {
  id: number;
  name: string;
}

const RegisterUser = ({ setloginpageshow }: YourComponentProps) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [showSecurityAnswer, setShowSecurityAnswer] = useState(false);
  const [packages, setPackages] = useState<Package[]>([]);
  const [main, setMain] = useState(1);
  const [securityQuestions, setSecurityQuestions] = useState<
    SecurityQuestion[]
  >([]);

  const [loading, setLoading] = useState(false);
  const [userexists, setuserexists] = useState(false);
  const [errorwhileregisteringuser, seterrorwhileregisteringuser] =
    useState(false);

  const [showTermsOfService, setShowTermsOfService] = useState(false);
  const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [AccountDeactivated, setAccountDeactivated] = useState(false);
  const [email, setEmail] = useState<string>(""); // Default or fetched email

  const handleAgree = () => {
    setCheckboxChecked(true);
    form.setFieldsValue({ agreement: true });
    setShowTermsOfService(false);
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    // Allow manual unchecking
    if (!e.target.checked) {
      setCheckboxChecked(false);
      form.setFieldsValue({ agreement: false });
    } else {
      toggleTermsOfService();
    }
  };

  const toggleTermsOfService = () => {
    setShowTermsOfService(!showTermsOfService);
  };

  const checkScrollPosition = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const offset = 5; // You can adjust this value as needed
    const isNearBottom =
      target.scrollHeight - target.scrollTop - offset <= target.clientHeight;
    if (isNearBottom) {
      setHasScrolledToEnd(true);
    }
  };

  const onFinish = async (values: any) => {
    try {
      console.log(values);
      setLoading(true);
      setuserexists(false);
      seterrorwhileregisteringuser(false);
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}user/registerUser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.message === "User exists") {
        setuserexists(true);
        setLoading(false);
      } else if (result.status === "Failed") {
        setLoading(false);
        seterrorwhileregisteringuser(true);
      } else {
        setLoading(false);
        navigate("/verificationEmail");
      }

      // Handle success scenario (e.g., showing a success message to the user)
    } catch (error) {
      console.error("Error during data submission:", error);
    }
  };

  // Custom validation rule to check password requirements
  const validatePassword = (_: any, value: string) => {
    // Minimum length of 8 characters
    if (value.length < 8) {
      return Promise.reject(
        new Error(
          "password must be a minimum of 8 characters in length and must contain at least one special character and one numeric digit."
        )
      );
    }

    // Include at least one special character and one number
    const specialCharacterRegex = /[!@#$%^&*()_+[\]{};':"\\|,.<>/?]+/;
    const numberRegex = /\d+/;

    // Ensure no spaces are present
    if (/\s/.test(value)) {
      return Promise.reject(new Error("Password cannot contain spaces."));
    }

    if (!specialCharacterRegex.test(value) || !numberRegex.test(value)) {
      return Promise.reject(
        new Error(
          "password must include at least one special character and one number."
        )
      );
    }

    return Promise.resolve();
  };

  const handleSecurityQuestionChange = (value: number) => {
    setShowSecurityAnswer(value !== null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}securityquestion/security-questions`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // Update the state with the received security questions
        setSecurityQuestions(data);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    // Call the fetchData function to fetch security questions when the component mounts
    fetchData();
  }, []);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}package/getpackages`
        ); // Adjust the URL as needed
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setPackages(data);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchPackages();
  }, []);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER_URL}package/currentMonthUsersWithPackage1`
        ); // Adjust the URL as needed
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setMain(data.packagetoshow);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchPackages();
  }, []);

  useEffect(() => {
    return () => {
      // Assuming `setEmail` is the method to update the email in your state/context
      setEmail("");
    };
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const handleEmailFocus = () => {
    form.setFieldsValue({
      packageId: undefined, // Clear the package field
    });
  };

  useEffect(() => {
    // This effect runs whenever the AccountDeactivated state changes
    if (AccountDeactivated) {
      // Reset the packageId field value
      form.setFieldsValue({ packageId: undefined });
    }
  }, [AccountDeactivated]); // Dependency array includes AccountDeactivated to run the effect on its change

  // Type the event parameter for the onBlur handler
  const handleEmailBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    const email = event.target.value; // Capture the email from the input field
    console.log("Email: ", email);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER_URL}user/isnewuser`,
        {
          method: "POST", // Specify the method
          headers: {
            "Content-Type": "application/json", // Specify the content type in the headers
          },
          body: JSON.stringify({ email: email }), // Stringify the body data
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.status === "failed") {
        form.setFields([
          {
            name: "email",
            errors: ["User with this email already exists."],
          },
        ]);
      } else if (data.status === "formatError") {
        form.setFields([
          {
            name: "email",
            errors: ["The input is not valid email."],
          },
        ]);
      } else if (data.status === "deactivated") {
        setAccountDeactivated(true); // Store in variable as true for deactivated account
      } else {
        setAccountDeactivated(false);
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <div className="register_main_div">
      <a className="register_heading">Create PIE Account</a>
      <Form
        className="register_form"
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        layout="vertical"
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="firstName"
              label={
                <span
                  className="label-font_register"
                  style={{ marginTop: "-0.5rem" }}
                >
                  First Name<span className="required-asterisk">*</span>
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Please provide your first name.",
                },
                {
                  pattern: /^[A-Za-z]+(?:\s+[A-Za-z]+)*$/,
                  message: "first name must contain only english letters.",
                },
              ]}
              className="form-item"
              required={false}
            >
              <Input
                className="input-field"
                maxLength={30}
                placeholder="Enter your first name"
                style={{ marginTop: "-0.5rem" }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="secondName"
              label={
                <span
                  className="label-font_register"
                  style={{ marginTop: "-0.5rem" }}
                >
                  Last Name<span className="required-asterisk">*</span>{" "}
                </span>
              }
              rules={[
                {
                  pattern: /^[A-Za-z]+(?:\s+[A-Za-z]+)*$/,
                  message: "second name must contain only english letters.",
                },
                {
                  required: true,
                  message: "Please provide your last name.",
                },
              ]}
              className="form-item"
              required={false}
            >
              <Input
                className="input-field"
                maxLength={30}
                placeholder="Enter your last name"
                style={{ marginTop: "-0.5rem" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="email"
              label={
                <span
                  className="label-font_register"
                  style={{ marginTop: "-0.55rem" }}
                >
                  Email<span className="required-asterisk">*</span>
                  <small style={{ fontSize: "11px", color: "#bfbdbd" }}>
                    (This will serve as your username to login)
                  </small>
                </span>
              }
              rules={[
                {
                  type: "email",
                  message: "The input is not valid email.",
                },
                {
                  required: true,
                  message: "Please provide your email.",
                },
              ]}
              className="form-item"
              required={false}
            >
              <Input
                className="input-field"
                placeholder="Enter your email"
                maxLength={50}
                onBlur={handleEmailBlur}
                defaultValue={email}
                onFocus={handleEmailFocus}
                onChange={handleEmailBlur}
                autoComplete="off"
                style={{ marginTop: "-0.5rem" }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="packageId"
              label={
                <span
                  className="label-font_register"
                  style={{ marginTop: "-0.5rem", display: "flex" }}
                >
                  Subscription Package
                  <span
                    className="required-asterisk"
                    style={{ marginTop: "-0.3rem" }}
                  >
                    *
                  </span>
                  <div className="tooltip_div">
                    <Tooltip
                      title={
                        <span
                          className="label-font_register"
                          style={{ fontSize: "13px", fontWeight: "lighter" }}
                        >
                          The package with free reviews is available to the
                          first 100 users each month. Subscribers who have
                          already used it are not eligible for free reviews.
                        </span>
                      }
                      overlayClassName="custom-tooltip"
                      placement="right"
                      style={{ fontSize: "11px" }}
                    >
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        style={{
                          color: "#9e9e9e",
                          paddingTop: "3px",
                          paddingLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  </div>
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Please select a subscription package.",
                },
              ]}
              className="form-item"
              valuePropName="value"
              style={{ marginTop: "-0.1rem" }}
              required={false}
            >
              <Select placeholder="Select a package" className="dropdown-field">
                {packages
                  .filter((pkg) => {
                    // If account is deactivated, exclude the package with id of 1
                    if (AccountDeactivated) {
                      return pkg.id !== 1;
                    }
                    // Else, apply the original logic based on the value of `main`
                    return main === 1 ? pkg.id === 1 : pkg.id !== 1;
                  })
                  .map((pkg) => (
                    <Select.Option key={pkg.id} value={pkg.id}>
                      {pkg.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="password"
              className="form-item"
              label={
                <span
                  className="label-font_register"
                  style={{ marginTop: "-0.5rem" }}
                >
                  Password<span className="required-asterisk">*</span>
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Please provide your password.",
                },
                {
                  validator: validatePassword, // Apply custom validation rule
                },
              ]}
              hasFeedback
              required={false}
            >
              <Input.Password
                className="input-field password-field"
                maxLength={30}
                placeholder="Enter your password"
                style={{ marginTop: "-0.5rem" }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="confirm"
              className="form-item"
              dependencies={["password"]}
              label={
                <span
                  className="label-font_register"
                  style={{ marginTop: "-0.5rem" }}
                >
                  Confirm Password<span className="required-asterisk">*</span>
                </span>
              }
              hasFeedback
              required={false}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The password that you entered do not match.")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                className="input-field password-field"
                placeholder="Re-enter password"
                maxLength={30}
                style={{ marginTop: "-0.5rem" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="securityQuestionId" // Use a different name to store the selected question's ID
              label={
                <span
                  className="label-font_register"
                  style={{ marginTop: "-0.5rem", display: "flex" }}
                >
                  Security Question
                  <span
                    className="required-asterisk"
                    style={{ marginTop: "-0.3rem" }}
                  >
                    *
                  </span>
                  <div className="tooltip_div">
                    <Tooltip
                      title={
                        <span
                          className="label-font_register"
                          style={{ fontSize: "13px", fontWeight: "lighter" }}
                        >
                          Security question will be used to restore access to
                          your PIE account.
                        </span>
                      }
                      overlayClassName="custom-tooltip"
                      placement="right"
                    >
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        style={{
                          color: "#9e9e9e",
                          paddingTop: "3px",
                          paddingLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  </div>
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Please select your security question.",
                },
              ]}
              className="form-item"
              required={false}
              valuePropName="value" // Use valuePropName to specify the value to be submitted
            >
              <Select
                placeholder="Select a security question"
                className="dropdown-field"
                onChange={handleSecurityQuestionChange}
              >
                {securityQuestions.map((secQuestion) => (
                  <Select.Option key={secQuestion.id} value={secQuestion.id}>
                    {secQuestion.question}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {showSecurityAnswer && (
              <Form.Item
                name="securityAnswer"
                label={
                  <span className="label-font_register">
                    Your Answer<span className="required-asterisk">*</span>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please provide your answer.",
                  },
                  {
                    validator: (_, value) => {
                      if (!value || value.trim().length === 0) {
                        return Promise.reject(
                          new Error("answer cannot contain only spaces.")
                        );
                      }

                      if (value !== value.trim()) {
                        return Promise.reject(
                          new Error(
                            "answer cannot have spaces at the beginning or end."
                          )
                        );
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
                className="form-item"
                required={false}
                style={{ marginTop: "-0.3rem" }}
              >
                <Input
                  className="input-field"
                  placeholder="Enter your answer"
                  maxLength={30}
                />
              </Form.Item>
            )}
          </Col>
        </Row>

        {/* This field will show up once a question is selected */}

        <Form.Item
          name="agreement"
          valuePropName="checked"
          className="form-item agreement-checkbox"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        "Please select the checkbox to confirm that you agree to the terms of service."
                      )
                    ),
            },
          ]}
        >
          <div style={{ display: "flex", alignItems: "start" }}>
            <div>
              {" "}
              {/* Add some space between checkbox and text */}
              <Checkbox
                checked={checkboxChecked}
                onChange={handleCheckboxChange}
              />
            </div>

            <div className="terms-service-link">
              <Button
                type="link"
                onClick={toggleTermsOfService}
                style={{
                  color: "rgba(117, 109, 109, 0.973)",
                  padding: 0,
                  height: "fit-content",
                  paddingLeft: "10px",
                  textAlign: "left",
                }} /* Ensure button has no extra padding and height fits to content */
              >
                <p style={{ margin: 0, fontSize: "14px" }}>
                  Please read and accept the Terms & Conditions
                </p>
              </Button>
            </div>
          </div>

          {showTermsOfService && (
            <div className="terms-of-service-container">
              <div
                className="terms-of-service-content"
                style={{
                  overflowY: "auto",
                  maxHeight: "200px",
                  marginTop: "10px",
                }}
                onScroll={checkScrollPosition}
              >
                <h2>Terms & Conditions</h2>
                <p>Date of last revision: Sept 24, 2024</p>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                  }}
                >
                  1. Definitions
                </p>
                <p>
                  In this Subscription Order of software as a service (SaaS)
                  product the following terms have the following meanings:
                </p>
                <p>
                  Account means a ‘Perception Intelligence Elicitor’ or’ Voice
                  of Customer Copilot’ account through which a single employee
                  at your Subscribing Organisation may access these platforms;
                </p>
                <p>
                  Additional Features mean any features that are made available
                  to you via the ‘Perception Intelligence Elicitor’ or’ Voice of
                  Customer Copilot’ platforms, at your request, as part of your
                  Subscription;{" "}
                </p>
                <p>
                  Features mean any features that are made available to you via
                  the ‘Perception Intelligence Elicitor’ or’ Voice of Customer
                  Copilot’ platforms as part of your Subscription;{" "}
                </p>
                <p>
                  PIE Platform means the paid web-based platform constructed,
                  managed and maintained by Resilient Technologies Ltd on{" "}
                  <a
                    href="//www.perceptionie.co.uk"
                    target="_blank"
                    style={{
                      color: "#0000ff",
                      textDecoration: "underline",
                      fontFamily: `"Motiva Sans Light", sans-serif`,
                      fontSize: "16px",
                    }}
                  >
                    www.perceptionie.co.uk
                  </a>
                  ;
                </p>
                <p>
                  VOC Co-pilot Platform mean the paid web-based platform
                  constructed, managed and maintained by Resilient Technologies
                  Ltd on{" "}
                  <a
                    href="//www.voccopilot.co.uk"
                    target="_blank"
                    style={{
                      color: "#0000ff",
                      textDecoration: "underline",
                      fontFamily: `"Motiva Sans Light", sans-serif`,
                      fontSize: "16px",
                    }}
                  >
                    www.voccopilot.co.uk
                  </a>
                  ;
                </p>
                <p>
                  Business Day means a day when banks are open for business in
                  the City of London;
                </p>

                <p>Claim has the meaning given such term in paragraph 14.4;</p>

                <p>
                  Confidential Information means these Subscription Orders
                  (except for its existence), details of all Subscription Orders
                  and all information which is identified at the time of
                  disclosure by the disclosing party as being confidential
                  information, or which may be reasonably regarded as the
                  confidential information of the disclosing party, including
                  information relating to the business, finances, affairs,
                  products, developments, trade secrets, campaign plans, launch
                  dates, know-how, personnel, customers and suppliers of each
                  party;
                </p>

                <p>
                  Event Outside Our Control has the meaning given such term in
                  paragraph 17.2;
                </p>

                <p>
                  Expiry Date has the meaning given such term in paragraph 8.2;
                </p>

                <p>
                  Fee(s) means our total charges for executing analyses on a
                  number of submitted reviews using a standard charge per
                  transaction mentioned on our website;
                </p>

                <p>
                  Payment Method means the way that you will pay your Fees and
                  is outlined in the Subscription Summary. Terms pertaining to
                  the Payment Method can be found in paragraphs 7.4, 7.5, and
                  8.3;
                </p>

                <p>
                  Publish means to publish, share or otherwise make PIE or VOC
                  Co-pilot reports available to any persons other than Data
                  Users or Clients to whom you are permitted to make it
                  available under your applicable subscription. For the sake of
                  clarity, this can be in the form of, but is not limited to
                  making it available in an email, a presentation, a printed or
                  electronic document, or an internet message including but not
                  limited to posting on a blog, social media platform or
                  similar;
                </p>

                <p>
                  Start Date means the date that your Subscription commences, as
                  outlined on your Subscription Summary. This is when you are
                  granted access to the PIE or VOC Co-pilot Platforms;
                </p>

                <p>
                  Subscribing Organisation means you, the business entering into
                  this Subscription Order, as detailed in the Subscription
                  Summary;
                </p>

                <p>
                  Subscribed Team(s) or Team(s) means any and all team(s) within
                  your organisation that are identified in the Subscription
                  Summary;
                </p>

                <p>
                  Subscription means your subscription to use the PIE or VOC
                  Co-pilot Platforms, as dictated by the conditions in your
                  Subscription Order;
                </p>

                <p>
                  Subscription Order means the Subscription Summary and/or
                  Package Details, and these Terms, together with any other
                  documents referred to in any of them under which you are
                  granted a Subscription;
                </p>

                <p>
                  Subscription Summary is part of the Subscription Order and
                  comprises the name of the Subscribing Organisation, the
                  Subscription Type, the Fee, the Payment Method, the Payment
                  Frequency (if applicable), the Period (if applicable), and any
                  other conditions upon which we grant a Subscription to you;
                </p>

                <p>
                  Subscription Type means the kind of Subscription that you are
                  signing up to – either Rolling Subscription with a certain
                  number of free analyses or Rolling Subscription without free
                  analyses – and is detailed in the Subscription Summary;
                </p>

                <p>
                  Special Conditions mean any additional conditions included in
                  the Subscription Summary;
                </p>

                <p>Terms means these terms and conditions;</p>

                <p>
                  User means any individual employee of your Subscribing
                  Organisation who holds an Account;
                </p>

                <p>
                  we, us, our means or refers to Resilient Technologies Ltd;
                </p>

                <p>
                  Web Site means{" "}
                  <a
                    href="//www.perceptionie.co.uk"
                    target="_blank"
                    style={{
                      color: "#0000ff",
                      textDecoration: "underline",
                      fontFamily: `"Motiva Sans Light", sans-serif`,
                      fontSize: "16px",
                    }}
                  >
                    www.perceptionie.co.uk
                  </a>{" "}
                  and{" "}
                  <a
                    href="//www.voccopilot.co.uk"
                    target="_blank"
                    style={{
                      color: "#0000ff",
                      textDecoration: "underline",
                      fontFamily: `"Motiva Sans Light", sans-serif`,
                      fontSize: "16px",
                    }}
                  >
                    www.voccopilot.co.uk
                  </a>
                  ;
                </p>

                <p>You or your refers to the Subscribing Organisation.</p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  2. Information about us
                </p>
                <p>
                  <strong>2.1</strong> We operate the websites
                  www.perceptionie.co.uk and www.voccopilot.co.uk. We are
                  Resilient Technologies Ltd, a company registered in England
                  and Wales under company number 14253939 and with our
                  registered office at 71-75 Shelton Street, Covent Garden,
                  London., WC2H 9JQ.
                </p>

                <p>
                  <strong>2.2</strong> Contacting Us. You or your Users may
                  contact us by filling the Contact Us form on our company’s
                  website www.resilienttech.co.uk. If you wish to give us formal
                  notice of any matter in accordance with these Terms, please do
                  so in accordance with paragraph 18.2.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  3. This is a Binding Agreement
                </p>

                <p>
                  <strong>3.1</strong> The Subscription Order, of which these
                  Terms form part, constitutes the entire agreement between you
                  and us in relation to your Subscription, and supersede and
                  extinguish all previous agreements, promises, assurances,
                  warranties, representations and understandings between us,
                  whether written or oral, relating to its subject matter.
                </p>

                <p>
                  <strong>3.2</strong> You acknowledge that in entering into a
                  Subscription Order you do not rely on any statement,
                  representation, assurance or warranty (whether made innocently
                  or negligently) that is not set out in this Subscription Order
                  or any document expressly referred to in them.
                </p>

                <p>
                  <strong>3.3</strong> You and we agree that neither of us shall
                  have any claim for innocent or negligent misrepresentation or
                  negligent misstatement based on any statement made by you or
                  us to the other.
                </p>

                <p>
                  <strong>3.4</strong> Any and all Special Conditions set out in
                  the Subscription Summary are subject to and governed by the
                  provisions of this Subscription Order and any other documents
                  provided in relation to such Special Conditions.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  4. Our rights to vary these terms
                </p>
                <p>
                  <strong>4.1</strong> We may vary these Terms from time to time
                  by written notice to you. The Terms as varied will come into
                  effect and supersede any and all other terms previously
                  provided 14 days after such notice and all references in this
                  Subscription Order to the Terms shall be deemed to be to the
                  Terms as varied. If you do not wish to accept the varied
                  Terms, you may cancel your subscription at any point during
                  the 14 day period prior to which they are due to come into
                  effect.
                </p>

                <p>
                  <strong>4.2</strong> Subject at all times to our right to vary
                  the Terms as described above, the Fee, Period, Payment
                  Frequency, number of Accounts, Features, Data Usage Rights,
                  Special Conditions, together with any other terms or
                  conditions set out in this Subscription Order may be varied at
                  any time by mutual consent in writing but not otherwise.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  5. Payment terms
                </p>

                <p>
                  <strong>5.1</strong> Our fee is calculated using a Pay As You
                  Go (PAYG) model therefore the Fee that you will pay will be
                  calculated based on the number of reviews that will be
                  processed by our SaaS product. It will be displayed after
                  customers reviews data is ingested in the SaaS software, you
                  will be given the option to accept the fee and make payment
                  before the analysis is executed. An invoice will be generated
                  using the billing details provided in your account details and
                  emailed to the corresponding billing email address.
                </p>

                <p>
                  <strong>5.2</strong> The Fee for your analysis is exclusive of
                  VAT or other similar sales taxes. Relevant rates of VAT or
                  similar taxes will be charged if applicable.
                </p>

                <p>
                  <strong>5.3</strong> We do not charge any recurring
                  subscription fee to use the system. However we reserve the
                  right to charge a subscription Fee or change the Fee per
                  analysis; for example, as a result of (i) our annual Fee
                  review and/or (ii) to reflect the cost of additional cloud
                  infrastructure costs or features on the PIE or VOC Co-pilot
                  Platforms. You will be given an appropriate notice period and
                  retain the right to terminate your account.
                </p>

                <p>
                  <strong>5.4</strong> If we decide to charge a subscription fee
                  and you agree to it, then you will make payments in time and
                  may not pause your Subscription. Please note that payment
                  holidays won’t be granted.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  6. Refund Policy
                </p>
                <p>
                  <strong>6.1</strong> Before performing an analysis the exact
                  amount to be charged to your payment method will be displayed
                  for confirmation. Once payment has been made, the underlying
                  hardware and software infrastrucutre will be used for
                  performing the analysis and charged to us by our cloud service
                  provider(s). If the ingested data is massive in size, the
                  analysis might take longer than expected in which case a
                  message will be displayed, the analysis will be performed and
                  stored in the database and an email with a link to the
                  analysis will be sent to your email address. It is therefore
                  not possible to refund payments made for the analysis. In case
                  you think that your analysis is not completed despite trying
                  to find it in the reports section, please send us an email
                  through help section and we will share the exact link of the
                  analysis with you. If we cannot provide the link of the
                  analysis e.g., the analysis did not complete due to technical
                  reasons, equivalent PIE reviews credits will be allocated to
                  your account. The review credits allocated will be equal to
                  payment made divided by price per review.
                </p>
                <p>
                  <strong>6.2</strong> If an analysis is deleted by the user
                  then a confirmation message is shown to remind the user that
                  their anaylses will be deleted from the software database and
                  they won’t be able to retreive it afterwards. No refunds will
                  be processed for the analyses which are deleted by the user.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  7. Subscription Types
                </p>
                <p>
                  <strong>7.1</strong> The PIE and VOC Co-pilot Platforms offer
                  only one Pay As You Go (PAYG) subscription. Limited time
                  special offers might offer certain number of free reviews on a
                  first come, first get basis.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  9. Accounts & Access
                </p>
                <p>
                  <strong>9.1</strong> You are ultimately responsible for the
                  actions of all your Users and ensuring that they comply fully
                  with your Subscription Order. If any User is in breach,
                  Resilient Technologies Ltd reserves the right to terminate
                  your Subscription and/or their access to the PIE or VOC
                  Co-pilot Platforms, and initiate any other action, legal or
                  otherwise, it deems appropriate to protect its interests and
                  safeguard the Platforms.
                </p>

                <p>
                  <strong>9.2</strong> You must keep and ensure that your Users
                  keep all Account information secure and confidential. Users
                  are forbidden from giving anyone else (including a Team
                  member) access to their Account. We reserve the right to
                  monitor Account usage to prevent this.
                </p>

                <p>
                  <strong>9.3</strong> You may request (but we are not obliged)
                  to provide you with additional Accounts whenever you wish.
                  Please contact us if you wish to do this. We reserve the right
                  to charge for the provision of additional Accounts.
                </p>

                <p>
                  <strong>9.4</strong> Should you wish to transfer an existing
                  Account to a new User (for example if someone leaves the
                  Subscribing Organisation), please contact us directly.
                </p>

                <p>
                  <strong>9.5</strong> Accounts are reserved for employees of
                  the Subscribing Organisation only who must be above 18 years
                  of age.
                </p>

                <p>
                  <strong>9.6</strong> Each Account must be under a single named
                  individual’s Subscribing Organisation’s email address. Email
                  addresses that are accessed by multiple people may not be used
                  (for example operations@xyz.com).
                </p>

                <p>
                  <strong>9.7</strong> You and your Users are strictly forbidden
                  from granting or sharing access to the PIE and VOC Co-pilot
                  Platforms except as expressly permitted in this Subscription
                  Order. If you would like to grant access to a third party (for
                  example in the case of a consultancy working for you) or other
                  person who would otherwise be prohibited under this
                  Subscription Order from having such access, you may ask us for
                  permission, which we reserve the right to grant or deny in our
                  sole discretion. No such person may obtain such access unless
                  and until we have entered into a written agreement with you
                  governing such access.
                </p>

                <p>
                  <strong>9.8</strong> If you or any of your Users suspect that
                  an Account has been compromised, or that Account details, data
                  or any other information has been accessed or shared in breach
                  of your Subscription Order, you must contact us in writing
                  immediately and inform us of the relevant facts, and take all
                  steps requested by us to remedy the situation.
                </p>

                <p>
                  <strong>9.9</strong> We reserve the right to terminate or
                  suspend an Account, multiple Accounts, or your Subscription
                  and block access to the PIE and VOC Co-pilot Platforms with
                  immediate effect if we decide (in our sole discretion) that
                  you or any of your Users have failed to comply with any of the
                  provisions of your Subscription Order. Should this be the
                  case, you and your Users must continue to comply fully with
                  the Subscription Order with respect to any permitted
                  continuing access to the PIE and VOC Co-pilot Platform still
                  in your possession or the possession of any User.
                </p>

                <p>
                  <strong>9.10</strong> For the avoidance of doubt, only human
                  beings are permitted to be Users. This includes, but is not
                  limited to, any application of scraping software.
                </p>

                <p>
                  <strong>9.11</strong> You and your Users may access and use
                  the PIE and VOC Co-pilot Platforms strictly in accordance with
                  this Subscription Order only and for no other purposes. No
                  other rights are granted to you or your Users and any other
                  use without our explicit prior written approval constitutes a
                  breach of this Subscription Order.
                </p>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  10. Additional Features
                </p>
                <p>
                  <strong>10.1</strong> Any Additional Features are included in
                  your Subscription in our sole discretion.
                </p>
                <p>
                  <strong>10.2</strong> There may be a charge for these
                  Additional Features.
                </p>
                <p>
                  <strong>10.3</strong> You may not remove any Additional
                  Features during the course of your Subscription.
                </p>
                <p>
                  <strong>10.4</strong> You acknowledge and understand that if
                  you make any feature requests, suggestions, proposals,
                  recommendations or other comments in respect of the PIE or VOC
                  Co-pilot Platforms, including any features which we agree to
                  provide as Additional Features (“Feature Suggestions“), you
                  will not own or have any rights to or in respect of such
                  Feature Suggestions and you hereby irrevocably assign with
                  full title guarantee any and all such rights, including all
                  relevant intellectual property rights (including in respect of
                  copyright by way of present assignment of future rights), in
                  or relating to such Feature Suggestions, to Resilient
                  Technologies Ltd.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  11. Usage Policy
                </p>
                <p>
                  <strong>11.1</strong> Service capacity limits: PIE and VOC
                  Co-pilot platforms are capable of processing terabytes of AI
                  powered analyses per minute collectively across all users but
                  do not have limitless resources. Therefore to ensure the
                  stability and efficiency of the service for all users, if the
                  system's capacity is reached, new review submissions will be
                  queued and processed on a first-come, first-served basis. This
                  may result in processing delays exceeding one minute.
                </p>
                <p>
                  <strong>11.2</strong> Processing notifications: Users will be
                  informed through the service interface when their review
                  submission is in the queue and processing has commenced. We
                  provide real-time updates on the status of your data analysis,
                  including estimated wait times and completion notifications,
                  to keep you informed of any expected delays or technical
                  issues that may impact the delivery of services.
                </p>
                <p>
                  <strong>11.3</strong> Continuity of service: If you close your
                  browser window or disconnect from the service while your data
                  is being processed, the analysis will continue unaffected.
                  Results will be made available in your account dashboard once
                  the processing is completed.
                </p>
                <p>
                  <strong>11.4:</strong> Completion notification: Upon
                  completion of the analysis, an email notification will be sent
                  to the email address associated with your account, informing
                  you that your results are ready for review.
                </p>
                <p>
                  <strong>11.5:</strong> Fair use and service integrity: You are
                  expected to use the service responsibly and not to engage in
                  activities that intentionally overburden the system, which
                  could impact service availability for other users. Misuse of
                  the service may lead to measures such as temporary or
                  permanent suspension of your account.
                </p>
                <p>
                  <strong>11.6:</strong> Compliance with usage policy: Continued
                  access to and use of the service is contingent upon compliance
                  with this Usage Policy as part of the overall Terms and
                  Conditions. Non-compliance may result in adjustments to your
                  service, imposition of additional fees, or suspension or
                  termination of your account.
                </p>
                <p>
                  <strong>11.3</strong> Upon Account Deactivation, howsoever
                  caused, you and your Users will no longer have any right or
                  access to PIE or VOC Co-pilot Platforms and the associated
                  data including analyses will be permanently deleted except for
                  essential information required for future identification
                  purposes.
                </p>
                <p>
                  <strong>11.4</strong> If a User ceases to be an employee of
                  the Subscribing Organisation, you must ensure that they are no
                  longer able to, and do not, access the PIE of VOC Co-pilot
                  Platforms.
                </p>
                <p>
                  <strong>11.5</strong> You hereby agree to indemnify us in
                  relation to any claim, expense, liability, damages, costs and
                  / or other losses (including legal fees) incurred by us and
                  arising from any breach by you or your Users of the
                  Subscription Order. This shall be without prejudice to any
                  other action Resilient Technologies Ltd may be entitled to
                  take against you in the event of a breach. You will still be
                  liable for any outstanding Fees.
                </p>
                <p>
                  <strong>11.6</strong> Without prejudice to any other provision
                  of this Subscription Order, you acknowledge and agree that any
                  threatened or actual breach by you of this paragraph 11 would
                  be a material breach of this Subscription Order and could
                  cause irreparable injury or harm to us for which monetary
                  damages would be an inadequate remedy. In such case, without
                  prejudice to any other legal or equitable remedies available
                  to us, you agree that we may seek specific performance or
                  other equitable (including injunctive) relief in respect of
                  such threatened or actual breach, without the necessity of
                  proving actual damages and without the necessity of posting
                  any security, whether to stop any breach or avoid any future
                  breach.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  12. Exports and Intelligent Searches
                </p>

                <p>
                  <strong>12.1</strong> Subject to any conditions detailed in
                  your Subscription Order, you can share perception reports with
                  your colleagues with a certain viewing limit imposed on each
                  share. Please contact customer support should you wish to
                  increase the viewing limits.
                </p>
                <p>
                  <strong>12.2</strong> Intelligent search feature incorporates
                  use of natural language for literal and semantic searches
                  across customers reviews and is restricted by a search limit.
                  It uses state of the art Retrieval Augmented Generation
                  technique to provide most relevant information without
                  exhausting underlying hardware resources. Please contact
                  customer support should you wish to increase this search
                  limit.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  13. Our liability to you
                </p>

                <p>
                  <strong>13.1</strong> Nothing in these Terms limits or
                  excludes our liability for: death or personal injury caused by
                  our negligence; fraud or fraudulent misrepresentation; or any
                  other matters in respect of which liability may not be limited
                  or excluded.
                </p>

                <p>
                  <strong>13.2</strong> Subject to paragraph 13.1, we will under
                  no circumstances whatever be liable to you, whether in
                  contract, tort (including negligence), breach of statutory
                  duty, or otherwise, arising under or in connection with a
                  Subscription Order or otherwise for:
                  <br /> any loss of profits, sales, business, or revenue;
                  <br />
                  loss or corruption of data, information or software;
                  <br /> loss of business opportunity;
                  <br /> loss of anticipated savings;
                  <br /> loss of goodwill;
                  <br /> or any indirect or consequential loss.
                </p>

                <p>
                  <strong>13.3</strong> Subject to paragraph 13.1, our total
                  liability to you in respect of any and all claims, expenses,
                  liabilities, damages, costs and / or other losses arising
                  under or in connection with a Subscription Order or otherwise,
                  whether in contract, tort (including negligence), breach of
                  statutory duty, or otherwise, shall in no circumstances exceed
                  the greater of £50.
                </p>

                <p>
                  <strong>13.4</strong> Except as expressly stated in these
                  Terms, we do not give any representation, warranties or
                  undertakings in relation to a Subscription Order (or, for the
                  avoidance of doubt, the PIE and the VOC Co-pilot Platforms).
                  Any representation, condition or warranty which might be
                  implied or incorporated into these Terms by statute, common
                  law or otherwise is excluded to the fullest extent permitted
                  by law. In particular, we will not be responsible for ensuring
                  that a Subscription is suitable for your purposes.
                </p>

                <p>
                  <strong>13.5</strong> The PIE Platform and VOC Co-pilot are
                  developed using latest state of the art Artifical Intelligence
                  models & Retrieval Augmeneted Generation techniques. The
                  language of reviews shall be English only and the product
                  isn’t developed for use with any other languages. Every care
                  has been taken to keep them accurate, reliable and efficient.
                  Human oversight is involved during development, testing and
                  ongoing evolution of the product. The objective is to deploy
                  technology to serve humans by enabling them to do more with
                  less. However, at times, the output might be inaccurate and we
                  are not liable for any loss this may cause.
                </p>

                <p>
                  <strong>13.6</strong> We assume no responsibility for the
                  content of websites linked on our Web Site. Such links should
                  not be interpreted as endorsement by us of those linked
                  websites. We will not be liable for any loss or damage that
                  may arise from your use of them.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  14. Third Party Liabilities
                </p>

                <p>
                  <strong>14.1</strong> Certain information or other content on
                  the PIE and the VOC Co-pilot Platforms may be made available
                  to us under licence and any intellectual property or other
                  rights in such content may therefore belong to third parties.
                </p>

                <p>
                  <strong>14.2</strong> Any breach of your Subscription Order
                  may cause us to breach our agreements with these third
                  parties. To the extent that the acts or omissions of you and /
                  or your Users make us liable to them, you hereby agree to
                  reimburse us to the full extent of any such liability on an
                  indemnity basis, which you acknowledge and agree may be
                  significant and far exceed the value of your Subscription
                  Order with us.
                </p>

                <p>
                  <strong>14.3</strong> For the avoidance of doubt, in the event
                  that we determine, in our sole discretion, that you have fully
                  adhered to your Subscription Order and that your actions did
                  not contribute to any third party action, we will indemnify
                  you against any action from such third party suppliers.
                </p>

                <p>
                  <strong>14.4</strong> Liability under this indemnity is
                  further conditioned on you discharging the following
                  obligations. If any third party makes a claim, or notifies you
                  of an intention to make a claim, against you which may give
                  rise to a liability under the indemnity described in paragraph
                  14.3 (a Claim), you shall: within 7 days, give written notice
                  of the Claim to us, specifying the nature of the Claim in
                  reasonable detail; not make any admission of liability,
                  agreement or compromise in relation to the Claim without our
                  prior written consent; give us and our professional advisers
                  access at reasonable times (on reasonable prior notice) to
                  your premises and your officers, directors, employees, agents,
                  representatives or advisers, and to any relevant assets,
                  accounts, documents and records within your power or control,
                  so as to enable us and our professional advisers to examine
                  them and to take copies (at our expense) for the purpose of
                  assessing the Claim; and be deemed to have given to us the
                  sole authority to avoid, dispute, compromise or defend the
                  Claim.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  15. Linking to our Web Site
                </p>

                <p>
                  <strong>15.1</strong> Subject to the following provisions of
                  this paragraph 15, you may link to our Web Site, provided you
                  do so in a way that complies with the law, and does not take a
                  form likely to damage our reputation or otherwise cause
                  confusion in the mind of a reasonable user as to the identity
                  of you versus us.
                </p>

                <p>
                  <strong>15.2</strong> You must not establish a link in such a
                  way as to suggest any form of association, approval or
                  endorsement on our part where none exists.
                </p>

                <p>
                  <strong>15.3</strong> You must not establish a link to our
                  home page in any website that is not owned by you.
                </p>

                <p>
                  <strong>15.4</strong> We reserve the right to withdraw linking
                  permission without notice and in the event that we withdraw
                  such permission, you agree to immediately remove all links to
                  our Web Site.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  16. Privacy, Personal Information and Cookies
                </p>
                <p>
                  Please review our Privacy Policy for information about our
                  data practices. We use cookies and other tracking
                  technologies, as described in our Cookie Policy. These
                  policies are incorporated by reference into these ‘Terms and
                  Conditions’ and updated by us from time to time. You are
                  responsible for ensuring that you and your Users are familiar
                  and agree that we can collect and use your information in
                  accordance with these policies.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  17. Events outside our control
                </p>

                <p>
                  <strong>17.1</strong> We will not be liable or responsible for
                  any failure to perform, or delay in performance of, any of our
                  obligations under a Subscription Order that is caused by an
                  Event Outside Our Control.
                </p>

                <p>
                  <strong>17.2</strong> An “Event Outside Our Control” means any
                  act or event beyond our reasonable control, including without
                  limitation strikes, lock-outs or other industrial action by
                  third parties, civil commotion, riot, invasion, terrorist
                  attack or threat of terrorist attack, war (whether declared or
                  not) or threat or preparation for war, fire, explosion, storm,
                  flood, earthquake, subsidence, epidemic or other natural
                  disaster, or failure of public or private telecommunications
                  networks, or failure of cloud hosted infrastructure by our
                  providers.
                </p>

                <p>
                  <strong>17.3</strong> If an Event Outside Our Control takes
                  place that affects the performance of our obligations under a
                  Subscription Order: we will contact you as soon as reasonably
                  possible to notify you; and our obligations under a
                  Subscription Order will be suspended and the time for
                  performance of our obligations will be extended for the
                  duration of the Event Outside Our Control.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  18. Communications between us
                </p>
                <p>
                  <strong>18.1</strong> When we refer in this Subscription Order
                  to “in writing”, this includes email.
                </p>

                <p>
                  <strong>18.2</strong> Any notice or other communication given
                  by you to us, or by us to you, under or in connection with the
                  Subscription Order shall be in writing and shall be delivered
                  personally, sent by pre-paid first-class post or other next
                  working day delivery service or e-mail. A notice or other
                  communication shall be deemed to have been received: if
                  delivered personally, when left at our registered office; if
                  sent by pre-paid first-class post or other next working day
                  delivery service, at 9.00 am on the second Business Day after
                  posting or if sent by e-mail, on the next Business Day after
                  transmission. In proving the service of any notice, it will be
                  sufficient to prove, in the case of a letter, that such letter
                  was properly addressed, stamped and placed in the post and, in
                  the case of an email, that such email was sent to
                  support@resilienttech.co.uk. The provisions of this paragraph
                  18 shall not apply to the service of any proceedings or other
                  documents in any legal action.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  19. Confidentiality
                </p>
                <p>
                  <strong>19.1</strong> Each party undertakes to the other (the
                  “disclosing party”) in relation to Confidential Information of
                  the other: to keep confidential all Confidential Information;
                  not to disclose Confidential Information without the other’s
                  prior written consent to any other person except those of its
                  representatives who have a need to know the Confidential
                  Information; and not to use Confidential Information except
                  for the purposes of performing its obligations under this
                  Subscription Order.
                </p>

                <p>
                  <strong>19.2</strong> Paragraph 19.1 does not apply to
                  Confidential Information to the extent that it is or was:
                  already in the possession of the other party from a source
                  other than the disclosing party and free of any obligation of
                  confidentiality on the date of its disclosure; in the public
                  domain other than as a result of a breach of this paragraph
                  19; required to be disclosed: pursuant to applicable laws, or
                  the requirements of any government body or relevant regulatory
                  authority, or the rules of any exchange on which the
                  securities of a party are or are to be listed; or in
                  connection with proceedings before a court of competent
                  jurisdiction or under any court order of such a court or for
                  the purpose of receiving legal advice, but only to the extent
                  and for the purpose of that disclosure.
                </p>

                <p>
                  <strong>19.3</strong> Without prejudice to any of our other
                  rights or remedies, the Subscribing Organisation acknowledges
                  and agrees that damages would not be an adequate remedy for
                  any breach of this paragraph 19 and the remedies of
                  injunction, specific performance and other equitable relief
                  are appropriate for any threatened or actual breach of this
                  provision and no proof of special damages shall be necessary
                  for the enforcement of the rights under this paragraph 19.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  20. Subscribers’ marks
                </p>
                <p>
                  <strong>20.1</strong> If (and only if) you give us explicit
                  permission in writing, we may use your logo or other trade
                  mark in our marketing material for the duration of your
                  Subscription to show that you are a client.
                </p>

                <p>
                  <strong>20.2</strong> You may request that certain exportable
                  material downloaded from the PIE or VOC Co-pilot Platforms is
                  branded with your logo or mark. Please note that there may be
                  a charge for this service. In such circumstances, you hereby
                  grant us a non-exclusive licence to use your logo or mark on
                  any such material so generated and you hereby indemnify us and
                  agree to keep us indemnified against all claims, expenses,
                  liabilities, damages, costs and / or other losses should there
                  be any claim that your logo or other trade mark infringes any
                  intellectual property rights belonging to a third party.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  21. Intellectual Property Rights
                </p>
                <p>
                  <strong>21.1</strong> We are the owner or the licensee of all
                  intellectual property rights in or relating to the PIE or VOC
                  Co-pilot Platforms, and in the material published on it. They
                  are protected by copyright and other intellectual property
                  laws and treaties around the world. All such rights are
                  reserved.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  22. Other important terms
                </p>
                <p>
                  <strong>22.1</strong> We may transfer our rights and
                  obligations under a Subscription Order to another
                  organisation, but this will not affect your rights or our
                  obligations under this Subscription Order. We will always
                  notify you if this happens.
                </p>

                <p>
                  <strong>22.2</strong> Any Subscription Order is between you
                  and us. Subject to paragraph 22.1 above, no other person shall
                  have any rights to enforce any of its terms, whether under the
                  Contracts (Rights of Third Parties) Act 1999 or otherwise.
                </p>

                <p>
                  <strong>22.3</strong> This Subscription Order supersedes any
                  terms you may seek to impose via a purchase order or
                  otherwise. No variation of this Subscription Order shall be
                  effective unless it is in writing and agreed in writing by the
                  parties or their authorised representatives.
                </p>

                <p>
                  <strong>22.4</strong> Each of the paragraphs of this
                  Subscription Order operates separately. If any court or
                  relevant authority decides that any of them are unlawful or
                  unenforceable, the remaining paragraphs will remain in full
                  force and effect and any affected provision will be replaced
                  by an alternative lawful provision which is as close as
                  possible substantively to the affected provision.
                </p>

                <p>
                  <strong>22.5</strong> If we fail to insist that you perform
                  any of your obligations under this Subscription Order, or if
                  we do not enforce our rights against you, or if we delay in
                  doing so, that will not mean that we have waived our rights
                  against you and will not mean that you do not have to comply
                  with those obligations. If we do waive a default by you, we
                  will only do so in writing, and that will not mean that we
                  will automatically waive any later default by you.
                </p>

                <p>
                  <strong>22.6</strong> This Subscription Order and any dispute
                  or claim arising out of or in connection with it or its
                  subject matter or formation (including non-contractual
                  disputes or claims) shall be governed by and construed in
                  accordance with the law of England and Wales.
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    marginTop: "3rem",
                  }}
                >
                  23. Data Processing & Security:
                </p>
                <p>
                  Please review our ‘Data Protection and Security Policy’ and
                  ‘Data Processing Addendum’ (DPA) on this website. These are
                  incorporated by reference into these ‘Terms and Conditions’
                  and can be updated by us from time to time. By accepting these
                  ‘Terms and Conditions’ you and your Users agree to our ‘Data
                  Protection and Security Policy’ and ‘Data Processing Addendum’
                  (DPA).
                </p>

                <Button
                  type="primary"
                  className="Agree_button"
                  disabled={!hasScrolledToEnd}
                  onClick={handleAgree}
                  style={{ marginTop: "10px" }}
                >
                  I Agree
                </Button>
              </div>
            </div>
          )}
        </Form.Item>

        {errorwhileregisteringuser && (
          <Form.Item className="wrong_credentials_div">
            <p className="wrong_credentials_p">
              An error occurred during the registration process. Please try
              again.
            </p>
          </Form.Item>
        )}

        {userexists && (
          <Form.Item className="wrong_credentials_div">
            <p className="wrong_credentials_p">
              Registration Failed: An account with this email already exists.
            </p>
          </Form.Item>
        )}

        <div className="register-button-container">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="submit-button"
              disabled={loading}
            >
              {loading ? (
                <span>
                  <span className="loading-dot"></span>
                  <span className="loading-dot"></span>
                  <span className="loading-dot"></span>
                </span>
              ) : (
                "Register"
              )}
            </Button>
            {userexists && (
              <a
                className="login_name"
                onClick={(e) => {
                  e.preventDefault();
                  setloginpageshow(true);
                }}
              >
                Login Now
              </a>
            )}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default RegisterUser;
