import React, { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import {
  FaLinkedin,
  FaGlobe,
  FaSync,
  FaRegCheckCircle,
  FaRegTimesCircle,
} from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./HelpForm.css";
import axios from "axios";
import { faArrowLeft, faSync } from "@fortawesome/free-solid-svg-icons";

const HelpForm = () => {
  const location = useLocation();
  const { state } = location;
  const fonts = [
    "cursive",
    "sans-serif",
    "serif",
    "monospace",
    "'Courier New', Courier, monospace",
    "'Times New Roman', Times, serif",
    "Arial, Helvetica, sans-serif",
    "'Lucida Console', Monaco, monospace",
    "'Comic Sans MS', cursive, sans-serif",
    "'Impact', Charcoal, sans-serif",
    "'Verdana', Geneva, sans-serif",
  ];
  const [username, setUsername] = useState("");
  const [generatedCaptcha, setGeneratedCaptcha] = useState("");
  const [captchaVerified, setCaptchaVerified] = useState(null);
  const isLoggedIn = localStorage.getItem("userData");

  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      // Parse the JSON string into an object
      const userObject = JSON.parse(userData);
      // Access the username from the parsed object
      const userUsername = userObject.username;
      setUsername(userUsername);
      setFormState((prevState) => ({
        ...prevState,
        email: userUsername,
        option: state?.selectedOption || "",
        subject: isLoggedIn ? formState.option : "General Inquiry",
      }));
    }
    console.log(username);
  }, [state?.selectedOption]);

  useEffect(() => {
    const captcha = generateCaptcha();
    setCaptcha(captcha);
  }, []);

  function generateCaptcha() {
    let value = btoa(Math.random() * 1000000000);
    value = value.substring(0, 4 + Math.random() * 2);
    setGeneratedCaptcha(value);
    return value;
  }

  function setCaptcha(value) {
    const htmlValue = value
      .split("")
      .map((char) => {
        const rotate = -45 + Math.trunc(Math.random() * 90); // Random rotation between -45 to 45 degrees
        const fontSize = 20 + Math.trunc(Math.random() * 10);
        const font = Math.trunc(Math.random() * fonts.length);
        const blur = Math.random() < 0.5 ? "blur(1.2px)" : "";
        const weight = Math.random() < 0.5 ? "bold" : "normal";
        const color = `rgb(${Math.trunc(Math.random() * 255)}, ${Math.trunc(
          Math.random() * 255
        )}, ${Math.trunc(Math.random() * 255)})`;
        const background =
          Math.random() < 0.5
            ? `background: rgba(${Math.trunc(
                Math.random() * 255
              )}, ${Math.trunc(Math.random() * 255)}, ${Math.trunc(
                Math.random() * 255
              )}, 0.1);`
            : "";
        return `<span
                  style="
                  transform: rotate(${rotate}deg);
                  filter: ${blur};
                  font-family: ${fonts[font]};
                  font-size: ${fontSize}px;
                  font-weight: ${weight};
                  color: ${color};
                  ${background}
                  display: inline-block;"
                  >${char}
                </span>`;
      })
      .join("");
    var previewElement = document.querySelector(".preview");
    if (previewElement) {
      previewElement.innerHTML = htmlValue;
    }
  }

  const handleRefresh = (e) => {
    e.preventDefault();
    const captcha = generateCaptcha();
    setCaptcha(captcha);
    setCaptchaVerified(null);
  };

  const [formState, setFormState] = useState({
    description: "",
    email: "",
    option: "",
    captchaText: "",
    subject: "General Inquiry",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "captchaText") {
      if (value === "") {
        setCaptchaVerified(null); // Reset the verification state if the text field is empty
      } else if (value === generatedCaptcha) {
        setCaptchaVerified(true);
      } else {
        setCaptchaVerified(false);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let inputCaptchaValue = document.querySelector(".captcha-input").value;

    if (inputCaptchaValue !== generatedCaptcha) {
      setCaptchaVerified(false);
      return;
    } else {
      setCaptchaVerified(true);
    }

    axios
      .post(`${process.env.REACT_APP_NODE_SERVER_URL}contact/help`, {
        ...formState,
      })
      .then((res) => toast.success("Data sent to backend!"))
      .catch((error) => toast.error(error.response.data.message));
    setFormState({
      description: "",
      email: "",
      captchaText: "",
    });
    const newCaptcha = generateCaptcha();
    setCaptcha(newCaptcha);

    setTimeout(() => {
      setCaptchaVerified(null);
    }, 2000);
  };

  return (
    <div className="feature_container">
      <div className="arrow-container_feature">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={handleBack}
          className="back-icon-feature"
        />
      </div>
      <div className="container w-100 d-flex justify-content-between main-container">
        <div className="contact-container">
          <h2 className="fs-1 text-left theme-color">Get in Touch</h2>
          <p className="fs-5 my-5" style={{ color: "#364545" }}>
            We appreciate your interest in our services. If you have any
            questions, need assistance, or wish to provide feedback, our team is
            available to help.
          </p>
          <div>
            <div className="d-flex align-items-center mb-3">
              <div>
                <FaLinkedin size={30} color="teal" className="mr-4" />
              </div>
              <a
                href="https://www.linkedin.com/company/resilient-technologies-ltd/"
                style={{ color: "#364545", fontSize: "18px" }}
                target="_blank"
                rel="noreferrer"
              >
                resilient-technologies-ltd
              </a>
            </div>
            <div className="d-flex align-items-center mb-3">
              <FaGlobe size={30} color="teal" className="mr-4" />
              <a
                href="https://www.resilienttech.co.uk"
                style={{ color: "#364545", fontSize: "18px" }}
                target="_blank"
                rel="noreferrer"
              >
                www.resilienttech.co.uk
              </a>
            </div>
          </div>
        </div>
        <div
          className="help-container py-5"
          style={{ color: "#364545", marginBottom: "10vw" }}
        >
          <h2
            className="text-left fs-1 fw-bold mb-4"
            style={{ color: "#364545" }}
          >
            Contact Us
          </h2>
          <form onSubmit={handleSubmit} className="w-100">
            <div className="mb-3">
              {isLoggedIn ? (
                <>
                  <label htmlFor="option" className="form-label">
                    Select an option:
                  </label>
                  <select
                    id="option"
                    name="option"
                    className="form-select"
                    value={formState.option}
                    onChange={handleChange}
                  >
                    <option value="Report a bug">Report a bug</option>
                    <option value="Suggest a feature">Suggest a feature</option>
                    <option value="Contact support">
                      Inquire about a service
                    </option>
                  </select>
                </>
              ) : (
                <div className="mb-3">
                  <label htmlFor="subject" className="form-label">
                    Subject:
                  </label>
                  <input
                    id="subject"
                    type="text"
                    name="subject"
                    className="form-control"
                    value="General Inquiry"
                    required
                    readOnly
                  />
                </div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description:
              </label>
              <textarea
                id="description"
                name="description"
                className="form-control"
                value={formState.description}
                onChange={handleChange}
                rows="5"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email:
              </label>
              <input
                id="email"
                type="email"
                name="email"
                maxLength={50}
                className="form-control"
                value={formState.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group mt-4 mb-5">
              {/* <ReCAPTCHA
                        sitekey={SITE_KEY} 
                        onChange={handleCaptchaChange}
                        ref={captchaRef}
                        /> */}
              <div className="preview" id="preview"></div>
              <div className="captcha">
                <input
                  type="text"
                  name="captchaText"
                  maxLength={10}
                  value={formState.captchaText}
                  onChange={handleChange}
                  className="form-control captcha-input"
                  placeholder="Enter captcha text here"
                />
                <span className="mt-1">
                  {captchaVerified === true && (
                    <FaRegCheckCircle
                      size={18}
                      color="green"
                      className="ml-2"
                    />
                  )}
                  {captchaVerified === false && (
                    <FaRegTimesCircle size={18} color="red" className="ml-2" />
                  )}
                </span>
                <button className="captcha-refresh" onClick={handleRefresh}>
                  <FontAwesomeIcon icon={faSync} size="15" />
                </button>
              </div>
            </div>
            <button type="submit" className="rounded border-0 help-button">
              Send
            </button>
            <ToastContainer />
          </form>
        </div>
      </div>
    </div>
  );
};

export default HelpForm;
