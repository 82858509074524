import "./AboutAI.css";
import AI_png from "./images/aboutAI.webp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function AboutAI() {
  const handleBack = () => {
    window.history.back();
  };
  return (
    <div className="feature_container">
      <div className="arrow-container_feature">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={handleBack}
          className="back-icon-feature"
        />
      </div>
      <div className="container aboutImg-container">
        <div className="container ">
          <h2 className="top-margin theme-color fs-1">AI & PIE</h2>
          <p className="theme-color fw-bold">What is AI?</p>
          <p className="text-dark fs-5">
            Artificial Intelligence, or AI, is the science of creating machines
            that can mimic human intelligence. This means programming computers
            to think, learn, and adapt in ways similar to how people do.
            Essentially, AI gives machines the ability to understand and
            interact with their environment in a more human-like way, enabling
            them to perform complex tasks and make informed decisions
            independently.
          </p>
          <p className="theme-color fw-bold mt-5">Idea behind PIE</p>
          <p className="text-dark fs-5">
            In today's fast-paced digital world, understanding customer feedback
            is more important than ever. Businesses rely on the opinions and
            experiences of their customers, and having a tool to analyze and
            interpret this feedback can truly make a difference. That's where
            PIE (Perception Intelligence Elicitor) comes in. This innovative
            platform uses the power of AI to transform vast amounts of customer
            reviews into meaningful insights, helping businesses thrive by
            staying attuned to their customers' needs and sentiments.
          </p>
        </div>
        <div className="img-height">
          <img src={AI_png} alt="" width={"100%"} />
        </div>
      </div>
      <div className="container">
        <div className="container mb-5">
          <p className="theme-color fs-4 fw-bold mt-4">Unveiling PIE</p>
          <p className="text-dark fs-5">
            At PIE, we've harnessed the transformative power of AI to create an
            advanced tool that redefines how businesses understand and act on
            customer feedback. The AI-driven features of PIE offer substantial
            business value, transforming how you perceive and act on customer
            feedback:
          </p>
          <ul>
            <li>
              <p className="fw-bold theme-color mb-1">
                Enhanced Customer Understanding
              </p>
              <p className="text-dark fs-5">
                By analyzing customer sentiments across various channels, PIE
                provides a comprehensive understanding of how customers feel
                about your business, products, and services. This insight is
                crucial for making informed decisions and improving customer
                satisfaction.
              </p>
            </li>
            <li>
              <p className="fw-bold theme-color mb-1">
                Proactive Issue Resolution
              </p>
              <p className="text-dark fs-5">
                PIE helps you spot negative experiences and sentiments early,
                allowing you to address issues proactively before they escalate.
                This proactive approach enhances your reputation and builds
                customer trust.
              </p>
            </li>
            <li>
              <p className="fw-bold theme-color mb-1">Strategic Marketing</p>
              <p className="text-dark fs-5">
                Leveraging sentiment trends, PIE enables you to measure the
                impact of your marketing campaigns and adjust strategies
                accordingly. Discovering the most frequent positive and negative
                phrases helps you craft content that resonates with your
                audience and drives engagement.
              </p>
            </li>
            <li>
              <p className="fw-bold theme-color mb-1">Competitive Advantage</p>
              <p className="text-dark fs-5">
                By analyzing customer reviews from your competitors, PIE
                uncovers their strengths and weaknesses. This competitive
                intelligence helps you craft strategies that place you ahead in
                the marketplace.
              </p>
            </li>
            <li>
              <p className="fw-bold theme-color mb-1">Holistic Insights</p>
              <p className="text-dark fs-5">
                The combination of word clouds and intelligent search features
                in PIE provides a holistic view of customer sentiments,
                behaviors, and trends. This depth of insight allows you to make
                data-driven decisions that enhance the overall customer
                experience.
              </p>
            </li>
            <li>
              <p className="fw-bold theme-color mb-1">
                Improved Products and Services
              </p>
              <p className="text-dark fs-5">
                Understanding key opinion topics and frequent feedback phrases
                helps identify areas for improvement in your products and
                services. Addressing these areas enhances customer satisfaction
                and loyalty.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
