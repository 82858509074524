import React, { useState, useEffect } from "react";
import { count_positive_phrases } from "../../../services/arraycontainsrequiredvalues";

import "./ScoreDisplay.css"; // Your CSS file

const ScoreDisplay = ({ tableData }) => {
  const [sentimentScores, setSentimentScores] = useState(null);

  useEffect(() => {
    const fetchSentimentScores = async () => {
      try {
        const scores = await count_positive_phrases(tableData);
        setSentimentScores(scores);
      } catch (error) {
        console.error("Error processing sentiment scores:", error);
      }
    };

    fetchSentimentScores();
  }, [tableData]);

  if (!sentimentScores) {
    return <div className="text-center">Loading sentiment scores...</div>;
  }

  const formatPercentage = (value) => value.toFixed(1);
  try {
    return (
      <>
        <div className="KOT-text">
          <h3>Sentiments</h3>
          <div className="row score-infographic">
            <div className="score-item promoter col-lg-4 col-md-4 col-sm-12">
              <div className="icon">🙂</div>
              <div className="percentage">
                {formatPercentage(sentimentScores.positive)}%
              </div>
              <div className="label">Positive</div>
            </div>
            <div className="score-item passive  col-lg-4 col-md-4 col-sm-12">
              <div className="icon">😐</div>
              <div className="percentage">
                {formatPercentage(
                  sentimentScores.neutral + sentimentScores.mixed
                )}
                %
              </div>
              <div className="label">Neutral</div>
            </div>

            <div className="score-item detractor col-lg-4 col-md-4 col-sm-12">
              <div className="icon">😟</div>
              <div className="percentage">
                {formatPercentage(sentimentScores.negative)}%
              </div>
              <div className="label">Negative</div>
            </div>
          </div>
        </div>
      </>
    );
  } catch (error) {
    console.error("Error processing sentiment scores:", error);
    return <div className="text-center">Error loading sentiment scores.</div>;
  }
};

export default ScoreDisplay;
