import { useNavigate } from "react-router-dom";
import FeatureItem from "./FeatureItem";
import "./Features.css";
import sentimentIcon from "./img/performance2.png";
import searchIcon from "./img/hunter.png";
import emotionChart from "./img/line-chart.png";
import kot from "./img/KOTs.png";
import wordCloud from "./img/cloud-communication.png";
import posNegPhrases from "./img/testimonial.png";
import percentageIcon from "./img/pie-chart.png";
import customReview from "./img/customization2.png";
import trendGraph from "./img/trend.png";
import reports from "./img/document.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const Features = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    window.history.back();
  };

  const sentimentContent =
    "Provides you with a cumulative score from customer reviews, measuring total customer satisfaction. Use these insights to refine your product/service and enhance business success.";
  const intelligentSearchcontent =
    "Easily query customer reviews using this feature powered by advanced techniques. Get precise answers instantly, unveil the unknown unknowns. Streamline operations and experience seamless data exploration.";
  const sentimentTrendOverTime =
    "Offers time-based sentiment trend analysis to help businesses track emotional shifts, make data-driven decisions, and refine strategies. Gain insights into your audience's evolving sentiments with our sentiments curve.";
  const contentKOT =
    "Extract key topics from reviews and get the sentiment distribution for each one. Receive actionable AI-driven suggestions to improve specific aspects of your product or service, empowering strategic decision-making.";
  const wordCloudContent =
    "Create clusters of frequently appearing words from customer reviews. Identify trending words and understand customer concerns quickly.";
  const sentimentPercentageContent =
    "Analyze reviews to reveal precise percentages of positive, negative, and neutral sentiments. Understand customer perceptions of your product/service to refine operational tactics.";
  const PNPhrases =
    "Gain access to curated positive and negative phrases from customer reviews. Benefit from these phrases by further data mining to maximize the understanding of your customer.";
  const selectiveReview =
    "Upload a data file and choose records to process for report generation. Ensure cost-effective analysis by paying only for selected records, providing flexible and efficient data analysis.";
  const scoreOverview =
    "Presents sentiment scores of your past reports in a bar graph, allowing you  to track and analyze customer trends. Easily identify monthly activity and perceptions of your customers to gauge progress.";
  const shareableReports =
    "It lets you effortlessly share reports with multiple users, eliminating the need for them to process your data files. Simplify collaboration and ensure everyone stays informed with easily accessible reports.";
  return (
    <div className="feature_container">
      <div className="arrow-container_feature">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={handleBack}
          className="back-icon-feature"
        />
        <h2
          className="text-left fs-1 fw-bold feature_heading"
          style={{ color: "#364545" }}
        >
          Features
        </h2>
      </div>

      <div className="d-flex flex-column justify-content-center w-100 container mx-auto feature-marginTop">
        <div className="my-5 d-flex justify-content-center flex-wrap align-self-center feature-container">
          <FeatureItem
            title="Sentiment Score"
            icon={sentimentIcon}
            text={sentimentContent}
          />
          <FeatureItem
            title="Intelligent Search"
            icon={searchIcon}
            text={intelligentSearchcontent}
          />
          <FeatureItem
            title="Sentiments Curve"
            icon={emotionChart}
            text={sentimentTrendOverTime}
          />
          <FeatureItem
            title="Key Opinion Topics"
            icon={kot}
            text={contentKOT}
          />
          <FeatureItem
            title="Word Cloud"
            icon={wordCloud}
            text={wordCloudContent}
          />
          <FeatureItem
            title="Sentiment Percentages"
            icon={percentageIcon}
            text={sentimentPercentageContent}
          />
          <FeatureItem
            title="Positive/Negative Phrases"
            icon={posNegPhrases}
            text={PNPhrases}
          />
          <FeatureItem
            title="Custom Review Analysis"
            icon={customReview}
            text={selectiveReview}
          />
          <FeatureItem
            title="Trend Analysis Graph"
            icon={trendGraph}
            text={scoreOverview}
          />
          <FeatureItem
            title="Shareable Reports"
            icon={reports}
            text={shareableReports}
          />
        </div>
      </div>
    </div>
  );
};
export default Features;
