import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { schemeCategory10 } from "d3-scale-chromatic";
import "./WordCloudComp.css";

const WordCloudComp = ({ clusteredResults }) => {
  const containerRef = useRef();
  let wordArrays;

  // Simulate sentiment scores by adding them to the clustered results
  const simulateSentiments = () => {
    return clusteredResults.clustered_results.map((cluster) => {
      cluster.positiveScore = Math.floor(Math.random() * 11);
      cluster.negativeScore = Math.floor(Math.random() * 6);
      return cluster;
    });
  };

  function cleanData(wordsArray) {
    const mergedWords = wordsArray.reduce((acc, { text, value }) => {
      if (typeof text !== "string") {
        // Skip this entry if text is not a string
        return acc;
      }

      // Create variations of the word to check against
      const lowerText = text.toLowerCase();
      const hyphenReplaced = lowerText.replace(/-/g, " ");
      const hyphenRemoved = lowerText.replace(/-/g, "");

      // Find if any variation or its singular/plural version is already in the accumulator
      const existingKey = Object.keys(acc).find((key) =>
        [lowerText, hyphenReplaced, hyphenRemoved].some(
          (variation) =>
            key === variation || singularPluralMatch(key, variation)
        )
      );

      if (existingKey) {
        // Update the value if the current one is greater, and also increase it by 1
        acc[existingKey] = Math.max(acc[existingKey], value) + 1;
      } else {
        // Or add the new word (original lowercase version)
        acc[lowerText] = value;
      }

      return acc;
    }, {});

    return Object.entries(mergedWords).map(([text, value]) => ({
      text,
      value,
    }));
  }

  function singularPluralMatch(word1, word2) {
    // Handle regular plurals (ending in 's')
    if (word1 === word2 + "s" || word2 === word1 + "s") {
      return true;
    }
    // Handle words ending in 'y' (e.g., party -> parties)
    if (
      (word1.endsWith("y") &&
        word2 === word1.substring(0, word1.length - 1) + "ies") ||
      (word2.endsWith("y") &&
        word1 === word2.substring(0, word2.length - 1) + "ies")
    ) {
      return true;
    }
    // Handle words ending in 'es' (e.g., bus -> buses)
    if (
      (word1.endsWith("s") && word2 === word1 + "es") ||
      (word2.endsWith("s") && word1 === word2 + "es")
    ) {
      return true;
    }
    // Add other rules as needed
    return false;
  }

  if (clusteredResults && clusteredResults.clustered_results) {
    clusteredResults.clustered_results = simulateSentiments();
  }
  const getBackgroundColor = (cluster) => {
    // Simulated sentiment scores for demonstration purposes
    const positiveScore = cluster.positiveScore || Math.random();
    const negativeScore = cluster.negativeScore || Math.random();
    const neutralScore = 1 - (positiveScore + negativeScore); // Assuming the sum of scores is <= 1

    // Define fixed colors for positive, negative, and neutral
    const positiveColor = d3.rgb("#DDDDDD"); // Fixed color for positive
    const negativeColor = d3.rgb("#DDDDDD"); // Fixed color for negative
    const neutralColor = d3.rgb("#DDDDDD"); // Fixed color for neutral

    // Determine which score is dominant and return the corresponding color
    if (positiveScore > negativeScore && positiveScore > neutralScore) {
      return positiveColor.toString();
    } else if (negativeScore > positiveScore && negativeScore > neutralScore) {
      return negativeColor.toString();
    } else {
      // Default to neutral color if neutral is the highest or if there's a tie
      return neutralColor.toString();
    }
  };

  const demoWords = [
    { text: "No", value: 5 },
    { text: "Data", value: 5 },
    { text: "Available", value: 5 },
    // ... add more demo words if needed
  ];

  useEffect(() => {
    if (clusteredResults && containerRef.current) {
      renderWordClouds();
    }
  }, [clusteredResults]);

  const renderWordClouds = () => {
    const container = d3.select(containerRef.current);
    container.selectAll("*").remove();

    if (!clusteredResults || !clusteredResults.clustered_results) {
      wordArrays = [demoWords];
    } else {
      wordArrays = clusteredResults.clustered_results.map((cluster) => {
        const words = cluster.full_text.split(" ");

        let wordsMap = words.reduce((acc, word) => {
          const foundWord = acc.find((w) => w.text === word);
          if (foundWord) {
            foundWord.value++;
          } else {
            acc.push({ text: word, value: 1 });
          }
          return acc;
        }, []);

        // Sort words by value in descending order and take the top 20
        wordsMap.sort((a, b) => b.value - a.value);
        return wordsMap.slice(0, 20);
      });
    }

    const color = d3.scaleOrdinal(schemeCategory10);

    wordArrays.forEach((words, index) => {
      // Clean the words array before processing
      const cleanedWords = cleanData(words);

      const cluster = clusteredResults.clustered_results[index];

      const backgroundColor = getBackgroundColor(cluster);

      const clusterDiv = container
        .append("div")
        .attr("class", "word-cluster")
        .style("border-color", backgroundColor);

      cleanedWords.forEach((word) => {
        clusterDiv
          .append("div")
          .style("display", "inline-block")
          .style("margin", "5px")
          .style("color", "#686868")
          // Apply a maximum font size of 50px
          .style(
            "font-size",
            `${Math.max(10, Math.min(word.value * 3 + 5, 27))}px`
          )
          .style("font-family", "sans-serif")
          .text(word.text);
      });
    });
  };

  return <div className="word-cloud-container" ref={containerRef}></div>;
};

export default WordCloudComp;
